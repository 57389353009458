import React, { useState } from "react";
import styles from "./orderDetail.module.css";
import { Table } from "reactstrap";
import { Tooltip } from "reactstrap";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import Moment from "react-moment";

const Changes = ({ orderDetails }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const { order_changes_info } = orderDetails?.order;
    return (
        <div className={styles.cardConatiner}>
            <div>
                <h3>
                    Changes
                    <BsFillQuestionCircleFill color="#3c71ff" id="changesTooltip" style={{ marginLeft: "5px" }} />
                </h3>
                <Tooltip target="changesTooltip" isOpen={tooltipOpen} toggle={toggle} placement="right">
                    See below to see changes to the order, who made them, and when they were made.
                </Tooltip>
            </div>
            <hr />
            <div>
                <Table responsive>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                SOURCE
                            </th>
                            <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                EVENT
                            </th>
                            <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                DESCRIPTION
                            </th>
                            <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                AT
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {order_changes_info?.map((change, index) => (
                            <tr key={change?.id} className="text-center">
                                <td align="left">{change?.source}</td>
                                <td align="left">{change?.event}</td>
                                <td align="left">{change?.description}</td>
                                <td align="left">
                                    <Moment format="MMMM DD YYYY, h:mm:ssa" withTitle>
                                        {change?.created_at}
                                    </Moment>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default Changes;
