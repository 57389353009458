import React, { useContext, useEffect, useState } from "react";
import { Container, Spinner } from "reactstrap";
import styles from "./orderDetail.module.css";
import OrderSummary from "./OrderSummary";
import DeliveryInformation from "./DeliveryInformation";
import BillingInformation from "./BillingInformation";
//import LabelPrinted from "./LabelPrinted";
import Notes from "./Notes";
import OrderCount from "./OrderCount";
import InstructionToSeller from "./InstructionToSeller";
import Changes from "./Changes";
import CustomerInformation from "./CustomerInformation";
import AssigneeInfo from "./AssigneeInfo";
//import FullfilmentHistory from "./FullfilmentHistory";
import FullfilmentInformation from "./FullfilmentInformation";
import Notification from "./Notification";
import InternalNote from "./InternalNote";
import MetaFields from "./MetaFields";
import UtmParameters from "./UtmParameters";

import { AuthContext } from "contexts/AuthContext";
import { api } from "services/api";
import { useToasts } from "react-toast-notifications";

const fetchData = async (endpoint, setterFn, addToast) => {
    try {
        const response = await api.get(endpoint);
        const data = response?.data;
        setterFn(data);
    } catch (error) {
        addToast("something went wrong.unable to fetch order details", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 40000,
        });
        console.error("Error fetching data:", error);
        setTimeout(()=>{
            window.location.href ="/orders"
        },3000)
    }
};

const OrderDetailsPage = () => {
    const { user } = useContext(AuthContext);
    const searchParams = new URLSearchParams(window.location.search);
    const orderId = searchParams.get("orderId");
    const [orderDetails, setOrderDetails] = useState(null);
    const { addToast } = useToasts();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchData(`order/detail-view/${orderId}`, setOrderDetails, addToast);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId]);

    console.log("order details", orderDetails);
    return (
        <div>
            <Container fluid style={{ paddingTop: "7rem", marginBottom: "3rem" }}>
                {orderDetails ? (
                    <div className={styles.productConatiner}>
                        {/* <div style={{display:"flex",justifyContent:"space-between", alignItems:"center", flexWrap:"wrap", gap:"20px"}}> */}
                        <div className={styles.orderDetailPage}>
                            <div className={styles.leftColumn}>
                                <div style={{ display: "grid", gridTemplate: "1fr", gap: "20px" }}>
                                    <div className={styles.tableWrapper}>
                                        <OrderSummary orderDetails={orderDetails} />
                                    </div>
                                    <div>
                                        <DeliveryInformation orderDetails={orderDetails} />
                                    </div>
                                    <div>
                                        <BillingInformation orderDetails={orderDetails} />
                                    </div>
                                    <div className={styles.noteContainer}>
                                        <div>
                                            <Notes orderDetails={orderDetails} />
                                        </div>
                                        <div>
                                            <OrderCount orderDetails={orderDetails} />
                                        </div>
                                        <div>
                                            <InstructionToSeller orderDetails={orderDetails} />
                                        </div>
                                    </div>
                                    {user && user.isAdmin ? (
                                        <div className={styles.tableWrapper}>
                                            <Changes orderDetails={orderDetails} />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.rightColumn}>
                                <div style={{ display: "grid", gridTemplate: "1fr", gap: "20px" }}>
                                    {/* <div>
                                    <LabelPrinted />
                                    </div> */}
                                    <div>
                                        <CustomerInformation orderDetails={orderDetails} />
                                    </div>
                                    {user && user.isAdmin ? <div>
                                        <AssigneeInfo orderDetails={orderDetails} />
                                    </div> : null}
                                    {/* <div>
                                    <FullfilmentHistory />
                                    </div> */}
                                    <div>
                                        <FullfilmentInformation orderDetails={orderDetails} />
                                    </div>
                                    {user && user.isAdmin ? <div>
                                        <Notification orderDetails={orderDetails} />
                                    </div> : null}
                                    {user && (user.isAdmin || user.isRetailer || user.isSupplier) ? (
                                        <div>
                                            <InternalNote orderDetails={orderDetails} />
                                        </div>
                                    ) : null}
                                    <div>
                                        <MetaFields orderDetails={orderDetails} />
                                    </div>
                                    <div>
                                        <UtmParameters orderDetails={orderDetails} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "95vw" }}>
                        <Spinner />
                    </div>
                )}
            </Container>
        </div>
    );
};

export default OrderDetailsPage;
