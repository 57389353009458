import React, { useContext, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Col,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import Moment from "react-moment";
import { api } from "services/api";
import { AuthContext } from "contexts/AuthContext";
import Filter from "../../components/Filters/Filter";
import BadgePillFilter from "components/Filters/Badge";
import { OverrideBillingRetailerModal } from "../../components/Retailer/OverrideBillingRetailerModal";
import { EditOverrideBillingRetailerSearchForm } from "../../feature/rolewise/admin/retailer-payout-override/UpdateOverrideBillingRetailerModal";
import DeleteRetailerPayoutOverrideModal from "feature/rolewise/admin/retailer-payout-override/deleteRetailerPayoutOverrideModal";
import useFilter from "customHooks/useFilter";
import { removeBadge } from "utils/commonFunctions";
import { getParams } from "utils/commonFunctions";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import { Sort, handleSort } from "../../utils/commonFunctions"
import moment from "moment";
import fileDownload from "js-file-download";
import "../../utils/commonStyles.css";

const RetailerPayoutOverride = props => {
    const { addToast } = useToasts();

    const [retailer_override_assignee_api_result, setRetailer_override_assignee_api_result] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });
    const [sort, setSort] = useState("-id");
    const [sortOrder, setSortOrder] = useState({order: "", columnName: ""});
    const [isLoading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState();
    const [openEditModal, setOpenEditModal] = useState();
    const [deleteRetailerOverrideInventoryLocation, setdeleteRetailerOverrideInventoryLocation] = useState();
    const [deleteRetailerOverrideSKU, setdeleteRetailerOverrideSKU] = useState();
    const [deleteRetailerOverrideState, setdeleteRetailerOverrideState] = useState();
    const [deleteRetailerOverrideId, setdeleteRetailerOverrideId] = useState();

    const [deleteRetailerOverrideMarketingFee, setdeleteRetailerOverrideMarketingFee] = useState();
    const [deleteRetailerOverrideShippingRate, setdeleteRetailerOverrideShippingRate] = useState();
    const [deleteRetailerOverrideSalesChannel, setdeleteRetailerOverrideSalesChannel] = useState();
    const [deleteRetailerOverrideRetailer, setdeleteRetailerOverrideRetailer] = useState();

    const [updateRetailerOverrideInventoryLocation, setupdateRetailerOverrideInventoryLocation] = useState();
    const [updateRetailerOverrideVariant, setupdateRetailerOverrideVariant] = useState();
    const [updateRetailerOverrideState, setupdateRetailerOverrideState] = useState();
    const [updateRetailerOverrideId, setupdateRetailerOverrideId] = useState();
    const [updateRetailerOverrideSku, setupdateRetailerOverrideSku] = useState();
    const [updateRetailermarketing_fee, setupdateRetailermarketing_fee] = useState();
    const [updateRetailershipping_rate, setupdateRetailershipping_rate] = useState();
    const [updateRetailerMinquantity, setupdateRetailerMinquantity] = useState();
    const [updateRetailerMaxquantity, setupdateRetailerMaxquantity] = useState();
    const [updateRetailersales_channel, setupdateRetailersales_channel] = useState();
    const [updateRetailerretailer, setupdateRetailerretailer] = useState();
    const [updateFeeType, setupdateFeeType] = useState();
    const [deleteRetailerOverrideFeeType, setdeleteRetailerOverrideFeeType] = useState();

    const [delete_retailer_override_assignee_list, setdelete_retailer_override_assignee_list] = useState([]);
    const [isActiveButton, setisActiveButton] = useState(false);

    const [selected, setSelected] = useState([]);
    const [params, setParams] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [clearall, setClearAll] = useState(false);

    const [filter, setFilter, clearFilterState] = useFilter({});
    const [updateOrdersShippingValue, setupdateOrdersShippingValue] = useState();
    const [deleteOrdersShippingValue, setdeleteOrdersShippingValue] = useState();

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);
    
    const [exportButton, setExportButton] = useState({
        label: "Export",
        disabled: false,
    });

    useEffect(() => {
        setLoading(true);
        let new_params = getParams(filter, setClearAll);
        new_params = { ...new_params, page, ordering: sort };
        setParams(new_params);
        api.get("order/retailer-billing-overrides/", { params: new_params })
            .then(response => {
                const list = response.data.results.map(order => {
                    const s = selected.filter(item => parseInt(item.id) === order.id);
                    order.checked = s.length > 0;
                    return order;
                });
                setRetailer_override_assignee_api_result(list);
                setTotalOrders(response.data.count);
                const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                const previous = page !== 1 ? page - 1 : 1;
                const next = page !== totalPages ? page + 1 : totalPages;
                setPagination({
                    previous,
                    next,
                    pages: () => {
                        let startCount = 1;
                        let endCount = isMobile ? 4 : 12;
                        let numberCount = Math.round(endCount / 2);
                        const pageList = [];
                        if (numberCount < 0) numberCount = 1;
                        startCount = page - numberCount;
                        if (startCount <= 0) startCount = 1;
                        if (page !== 1) endCount = page + numberCount;
                        if (endCount > totalPages) endCount = totalPages;
                        if (totalPages >= endCount) {
                            for (let i = startCount; i <= endCount; i++) {
                                pageList.push(i);
                            }
                        } else if (totalPages >= 1) {
                            for (let i = 1; i <= totalPages; i++) pageList.push(i);
                        }
                        return pageList;
                    }
                });
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            });
    }, [page, sort, filter, refresh]);
    

    const handleExportPayoutOverrides = () => {
        setExportButton({
            label: "Exporting...",
            disabled: true,
        });

        let new_params = params;
    
        new_params.override_assignee_ids = delete_retailer_override_assignee_list.map((item) => item.id);
        api
            .get("order/retailer-billing-overrides/export/", {
                params: new_params,
            })
            .then((response) => response.data)
            .then((response) => {
                const success = response?.success | false;

                if (success) {
                    addToast(response.message, {
                        appearance: "success",
                        autoDismiss: false,
                    });
                } else {
                    const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
                    const filename = "retailers-payouts-overrides-" + timestamp + ".csv";
                    fileDownload(response, filename);
                    console.log("Downloading file:", filename);
                }

                setExportButton({
                    label: "Export",
                    disabled: false,
                });
            });
    };
    const handlePagination = page => {
        setPage(page);
    };

    const { user, isAuthentication } = useContext(AuthContext);

    const handleBrands = e => {
        setPage(1);
        if (e == "__all__") {
            let status = [];
            let checkbox = document.querySelectorAll(".checkbox_brand");
            for (let i = 0; i < checkbox.length; i++) {
              checkbox[i].checked = true;
              status.push({
                value: checkbox[i].value,
                label: checkbox[i].dataset.label,
              });
            }
            setFilter({ ...filter, brands: status });
          } else {
            let checkbox = [...new Set(filter.brands)];
            if (e.target.checked) {
              checkbox.push({ value: e.target.value, label: e.target.dataset.label });
            } else {
              checkbox = checkbox.filter((item) => item.value !== e.target.value);
            }
            setFilter({ ...filter, brands: checkbox });
          }
        
    };

    const handleStates = e => {
        setPage(1);
        if (e == "__all__") {
            let status = [];

            let checkbox = document.querySelectorAll(".checkbox_state");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                status.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label
                });
            }
            setFilter({ ...filter, states: status });
        } else {
            let checkbox = [...new Set(filter.states)];

            if (e.target.checked) {
                checkbox.push({ value: e.target.value, label: e.target.dataset.label });
            } else {
                checkbox = checkbox.filter(item => item.value !== e.target.value);
            }

            setFilter({ ...filter, states: checkbox });
        }
    };

    const handleInventoryLocation = e => {
        setPage(1);
        if (e == "__all__") {
            let inventory_location = [];

            let checkbox = document.querySelectorAll(".checkbox_inventory_location");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                inventory_location.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label
                });
            }
            setFilter({ ...filter, inventory_location: inventory_location });
        } else {
            let checkbox = [...new Set(filter.inventory_location)];
            if (e.target.checked) {
                checkbox.push({ value: e.target.value, label: e.target.dataset.label });
            } else {
                checkbox = checkbox.filter(item => item.value !== e.target.value);
            }
            setFilter({ ...filter, inventory_location: checkbox });
        }
    };

    const handleProduct = e => {
        setPage(1);
        let checkbox = [...new Set(filter.products)];

        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
        }

        setFilter({ ...filter, products: checkbox });
    };

    const handleUser = e => {
        setPage(1);
        if (e == "__all__") {
            let user = [];
            let checkbox = document.querySelectorAll(".checkbox_user");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                user.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label,
                });
            }
            setFilter({ ...filter, user: user });
        } else {
            let checkbox = [...new Set(filter.user)];
            if (e.target.checked) {
                checkbox.push({ value: e.target.value, label: e.target.dataset.label });
            } else {
                checkbox = checkbox.filter(item => item.value !== e.target.value);
            }
            setFilter({ ...filter, user: checkbox });
        }
    };

    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    const clearFilter = prefix => {
        setPage(1);
        if (prefix === "brand") {
            setFilter({ ...filter, brands: [] });
        } else if (prefix === "product") {
            setFilter({ ...filter, products: [] });
        } else if (prefix === "state") {
            setFilter({ ...filter, states: [] });
        } else if (prefix === "inventory_location") {
            setFilter({ ...filter, inventory_location: [] });
        } else if (prefix === "user") {
            setFilter({ ...filter, user: [] });
        } else if (prefix === "sku") {
            setFilter({ ...filter, sku: [] });
        }
        setClearAll(false);

        let checkbox = document.querySelectorAll(".checkbox_" + prefix + ":checked");
        if (checkbox.length > 0) {
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = false;
            }
        }
    };

    const handleRetailerCheckbox = e => {
        let checkbox = document.querySelectorAll(".retailer_override_assignee_checbox");
        for (let i = 0; i < checkbox.length; i++) {
            if (e.target.checked === true && checkbox[i].checked === false) {
                checkbox[i].checked = true;
                var new_retailer_override_assignee_list = [];
                var retailer_instance = {};

                retailer_override_assignee_api_result.forEach(order_detail => {
                    if (parseInt(order_detail.id) === parseInt(checkbox[i].name)) {
                        retailer_instance.id = order_detail.id;
                        retailer_instance.sku = order_detail.sku;
                        retailer_instance.marketing_fee = order_detail.marketing_fee;
                        retailer_instance.inventory_location = order_detail.inventory_location;
                        retailer_instance.ship_to_state = order_detail.ship_to_state;
                        retailer_instance.shipping_rate = order_detail.shipping_rate;
                        retailer_instance.sales_channel = order_detail.sales_channel;
                        retailer_instance.retailer = order_detail.retailer;

                        if (delete_retailer_override_assignee_list.length <= 1) {
                            setisActiveButton(false);
                        }

                        if (delete_retailer_override_assignee_list.length > 0) {
                            new_retailer_override_assignee_list = delete_retailer_override_assignee_list;
                            new_retailer_override_assignee_list.push(retailer_instance);
                            setdelete_retailer_override_assignee_list(new_retailer_override_assignee_list);
                            setisActiveButton(true);
                        } else {
                            new_retailer_override_assignee_list = delete_retailer_override_assignee_list;
                            new_retailer_override_assignee_list.push(retailer_instance);
                            setdelete_retailer_override_assignee_list(new_retailer_override_assignee_list);
                            setisActiveButton(true);
                        }
                    }
                });
            }
            if (e.target.checked === false && checkbox[i].checked === true) {
                setdelete_retailer_override_assignee_list([]);
                checkbox[i].checked = false;
                setisActiveButton(false);
            }
        }
    };

    const handleOverrideAssigneeCheckbox = (e, id, sku, inventory_location, ship_to_state, marketing_fee, shipping_rate, sales_channel, retailer) => {
        let new_retailer_override_assignee_list = [];
        let retailer_instance = {
            id: id,
            sku: sku,
            inventory_location: inventory_location,
            ship_to_state: ship_to_state,
            marketing_fee: marketing_fee,
            shipping_rate: shipping_rate,
            sales_channel: sales_channel,
            retailer: retailer
        };
        if (delete_retailer_override_assignee_list.length <= 1) {
            setisActiveButton(false);
        }
        if (delete_retailer_override_assignee_list.length > 0) {
            const index = delete_retailer_override_assignee_list.findIndex(element => element.id === id);
            if (index !== -1) {
                delete_retailer_override_assignee_list.splice(index, 1);
            } else {
                new_retailer_override_assignee_list = delete_retailer_override_assignee_list;
                new_retailer_override_assignee_list.push(retailer_instance);
                setdelete_retailer_override_assignee_list(new_retailer_override_assignee_list);
                setisActiveButton(true);
            }
        } else {
            new_retailer_override_assignee_list = delete_retailer_override_assignee_list;
            new_retailer_override_assignee_list.push(retailer_instance);
            setdelete_retailer_override_assignee_list(new_retailer_override_assignee_list);
            setisActiveButton(true);
        }
    };

    const handleClearAll = () => {
        setPage(1);
        setClearAll(false);
        setFilter({ ...filter, brands: [], products: [], states: [], inventory_location: [], user: [] });
    };

    const toggle = () => setOpenDeleteModal(!openDeleteModal);

    const DeleteRetailerOverride = override_assignee_id => {
        let deleted_override_assignee_id = "";
        if (typeof override_assignee_id === "string") {
            deleted_override_assignee_id = override_assignee_id;
        }
        if (typeof override_assignee_id === "object") {
            let override_assignee_id_list = [];
            override_assignee_id.forEach(event => {
                override_assignee_id_list.push(event.id);
            });
            deleted_override_assignee_id = override_assignee_id_list;
        }
        api.delete(`order/retailer-billing-overrides/?retailer_billing_overrides=${deleted_override_assignee_id}`, {})
            .then(response => response)
            .then(response => {
                if (response.status === 200) {
                    addToast(response.data.message, {
                        appearance: "success",
                        autoDismiss: true
                    });
                    handleRefresh();
                    setisActiveButton(false);
                    setdelete_retailer_override_assignee_list([]);
                    setOpenDeleteModal(false);
                } else if (response.status === 404) {
                    addToast(response.data.message, {
                        appearance: "error",
                        autoDismiss: true
                    });
                    handleRefresh();
                    setOpenDeleteModal(false);
                }
            })
            .catch(err => {
                addToast("Something went wrong, please try again!!", {
                    appearance: "error",
                    autoDismiss: true
                });
            });
    };

    const DeleteRetailerOverrideModal = (id, variant, inventory_location, ship_to_state, marketing_fee, shipping_rate, sales_channel, retailer, fee_type, orders_shipping_value) => {
        setOpenDeleteModal(true);
        setdeleteRetailerOverrideInventoryLocation(inventory_location);
        setdeleteRetailerOverrideSKU(variant);
        setdeleteRetailerOverrideState(ship_to_state);
        setdeleteRetailerOverrideId(id);
        setdeleteRetailerOverrideMarketingFee(marketing_fee);
        setdeleteRetailerOverrideShippingRate(shipping_rate);
        setdeleteRetailerOverrideSalesChannel(sales_channel);
        setdeleteRetailerOverrideRetailer(retailer);
        setdeleteRetailerOverrideFeeType(fee_type);
        setdeleteOrdersShippingValue(orders_shipping_value);
    };

    const EditRetailerOverrideModal = (
        id,
        variant,
        sku,
        inventory_location,
        ship_to_state,
        marketing_fee,
        shipping_rate,
        sales_channel,
        retailer,
        fee_type,
        minimum_quantity,
        maximum_quantity,
        orders_shipping_value,
    ) => {
        setOpenEditModal(true);
        setupdateRetailerOverrideId(id);
        setupdateRetailerOverrideVariant(variant);
        setupdateRetailerOverrideSku(sku);
        setupdateRetailerOverrideInventoryLocation(inventory_location);
        setupdateRetailerOverrideState(ship_to_state);
        setupdateRetailermarketing_fee(marketing_fee);
        setupdateRetailershipping_rate(shipping_rate);
        setupdateRetailersales_channel(sales_channel);
        setupdateRetailerretailer(retailer);
        setupdateFeeType(fee_type);
        setupdateOrdersShippingValue(orders_shipping_value);
        setupdateRetailerMinquantity(minimum_quantity);
        setupdateRetailerMaxquantity(maximum_quantity);
    };

    if (isAuthentication && !user.isAdmin) window.location.href = window.env.APP_URL;

    const handleSKU = (e) => {
        setPage(1);
        if (e == "__all__") {
          let status = [];
          let checkbox = document.querySelectorAll(".checkbox_state");
          for (let i = 0; i < checkbox.length; i++) {
            checkbox[i].checked = true;
            status.push({
              value: checkbox[i].value,
              label: checkbox[i].dataset.label,
            });
          }
          setFilter({ ...filter, sku: status });
        } else {
          let checkbox = [...new Set(filter.sku)];
          if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
          } else {
            checkbox = checkbox.filter((item) => item.value !== e.target.value);
          }
          setFilter({ ...filter, sku: checkbox });
        }
      };
      
    return (
        <>
            {isAuthentication && user.isAdmin && (
                <Container fluid>
                    <div className={"pt-7"}>
                        {isAuthentication && (
                            <Row className={"mb-2"}>
                                <Col xs={10} lg={"2"} className={"text-left"}></Col>
                                <Col xs={2} lg={"10"} className={"text-right"}>
                                    <div className={"d-none d-lg-inline-block mr-2"}>
                                        <div className="mb-1 d-inline">
                                            {/* 7278215305 - increase font size */}
                                            <button className="button-font-size btn btn-neutral btn-sm mr-2 p-9px" onClick={handleRefresh}>
                                                <i className="fas fa-sync-alt"></i>
                                            </button>
                                        </div>

                                        <Filter
                                            name={"State"}
                                            prefix={"state"}
                                            onChange={handleStates}
                                            newparams={params}
                                            selected={filter.states}
                                            url={"order/filter/state/"}
                                            inputSearch={true}
                                            onClear={() => clearFilter("state")}
                                        />
                                        <Filter
                                            name={"Brand"}
                                            prefix={"brand"}
                                            onChange={handleBrands}
                                            newparams={params}
                                            selected={filter.brands}
                                            url={"/order/filter/brand/"}
                                            onClear={() => clearFilter("brand")}
                                        />
                                        <Filter
                                            name={"Inventory Location"}
                                            prefix={"inventory_location"}
                                            showSelectAll={false}
                                            onChange={handleInventoryLocation}
                                            newparams={params}
                                            selected={filter.inventory_location}
                                            url={"order/filter/inventory-location/"}
                                            disablePagination={true}
                                            inputSearch={true}
                                            onClear={() => clearFilter("inventory_location")}
                                        />
                                        <Filter
                                            name={"Product"}
                                            prefix={"product"}
                                            onChange={handleProduct}
                                            newparams={params}
                                            selected={filter.products}
                                            url={"inventory/filter/product"}
                                            inputSearch={true}
                                            onClear={() => clearFilter("product")}
                                        />
                                        <Filter
                                            name={"User"}
                                            prefix={"user"}
                                            onChange={handleUser}
                                            newparams={params}
                                            selected={filter.user}
                                            url={"order/retailer-billing-override-user-filter/"}
                                            inputSearch={true}
                                            onClear={() => clearFilter("user")}
                                        />
                                        <Filter
                                        name={"SKUs"}
                                        prefix={"sku"}
                                        onChange={handleSKU}
                                        newparams={params}
                                        selected={filter.sku}
                                        url={"/order/retailer-override/filter/sku/"}
                                        inputSearch={true}
                                        onClear={() => clearFilter("sku")}
                                      />
                                        {/* 7278215305 - increase font size */}
                                        <Button className="button-font-size p-9px" color={"primary"} size={"sm"} onClick={e => setOpenModal(true)}>
                                            <i className="fas fa-file-csv"></i> Create
                                        </Button>
                                        {/* 7278215305 - increase font size */}
                                        <Button
                                            color={"primary"}
                                            size={"sm"}
                                            disabled={exportButton.disabled}
                                            onClick={handleExportPayoutOverrides}
                                            className='button-font-size p-9px'
                                        >
                                            <i className="fas fa-file-csv"></i> {exportButton.label}
                                        </Button>

                                        <OverrideBillingRetailerModal
                                            url={"retailer-assignment-product-list/"}
                                            isOpen={openModal}
                                            toggle={e => setOpenModal(false)}
                                            loading={isLoading}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            {isAuthentication && (
                                <div className="col">
                                    <Card className="shadow list-height">
                                        <CardHeader className="border-0">
                                            <Row>
                                                <Col>
                                                    <h3
                                                        className="mb-0"
                                                        style={{
                                                            display: "inline-block",
                                                            paddingRight: "5px"
                                                        }}>
                                                        Retailer Payout Override <small>&nbsp;({totalOrders})</small>
                                                    </h3>
                                                    {clearall && (
                                                        <a
                                                            href={void 0}
                                                            className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                                            onClick={handleClearAll}
                                                            id="clear_all_filters">
                                                            <i className="fas fa-times-circle"></i> Clear All filters
                                                        </a>
                                                    )}
                                                </Col>
                                                <Col className="text-right">
                                                    {isActiveButton && user.isAdmin && (
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                className="btn text-primary"
                                                                href="#"
                                                                role="button"
                                                                size="sm"
                                                                color="white"
                                                                onClick={e => e.preventDefault()}>
                                                                <i className="fas fa-cogs" /> actions
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                                <DropdownItem href="#" onClick={e => setOpenDeleteModal(true)} className="text-danger">
                                                                    <i className="fas fa-file-invoice" />
                                                                    Delete Records
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    )}
                                                </Col>
                                                <Col sm="12">
                                                    <BadgePillFilter
                                                        data={filter}
                                                        onClick={e => {
                                                            removeBadge(e, filter, setFilter, undefined, undefined, undefined, undefined, setPage);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        {isLoading ? (
                                            <div className="text-center mt-3 mb-3">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <Table className="align-items-center table-flush" responsive>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>
                                                                <input
                                                                    type="checkbox"
                                                                    name={"check_orders"}
                                                                    id={"checkbox_orders"}
                                                                    onChange={handleRetailerCheckbox}
                                                                />
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                <Sort onClick={() => handleSort(sort, "created_at", setSortOrder, setSort)} sortingOrder={sortOrder} column="created_at">Created at</Sort>
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Ship to state
                                                            </th>
                                                            <th scope="col" style={{ minWidth: "300px" }} className="pl-3 pr-3">
                                                                Variant Name
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                SKU
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Inventory Location Name
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Retailer
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Marketing Fee
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Marketing Fee Type
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                <Sort onClick={() => handleSort(sort, "shipping_rate", setSortOrder, setSort)} sortingOrder={sortOrder} column="shipping_rate">Shipping rate</Sort>
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                <Sort onClick={() => handleSort(sort, "orders_shipping_value", setSortOrder, setSort)} sortingOrder={sortOrder} column="orders_shipping_value">Order's Shipping</Sort>
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                <Sort onClick={() => handleSort(sort, "sales_channel", setSortOrder, setSort)} sortingOrder={sortOrder} column="sales_channel">Sales channel</Sort>
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                User Created Name
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-7"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {retailer_override_assignee_api_result.map(order => (
                                                            <tr key={order.id}>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        name={order.id}
                                                                        className="retailer_override_assignee_checbox"
                                                                        onChange={e =>
                                                                            handleOverrideAssigneeCheckbox(
                                                                                e,
                                                                                order.id,
                                                                                order.sku,
                                                                                order.inventory_location,
                                                                                order.variant,
                                                                                order.ship_to_state,
                                                                                order.marketing_fee,
                                                                                order.shipping_rate,
                                                                                order.sales_channel,
                                                                                order.retailer
                                                                            )
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="pl-3 pr-3 ">
                                                                    <Moment format="M/D/Y h:mm:ssa" withTitle>
                                                                        {order.created_at}
                                                                    </Moment>
                                                                </td>
                                                                <td className="pl-3 pr-3 ">{order.ship_to_state}</td>
                                                                <td className="pl-3 pr-3">
                                                                    <span className="text-wrap">
                                                                        {order.variant_size ? `${order.variant} - ${order.variant_size}` : order.variant}
                                                                    </span>
                                                                </td>
                                                                <td className="pl-3 pr-3">
                                                                    <span className="text-wrap">{order.sku}</span>
                                                                </td>
                                                                <td className="pl-3 pr-3 ">{order.inventory_location}</td>
                                                                <td className="pl-3 pr-3 ">{order.retailer}</td>
                                                                <td className="pl-3 pr-3 ">{order.marketing_fee}</td>
                                                                <td className="pl-3 pr-3 ">{order.fee_type}</td>
                                                                <td className="pl-3 pr-3 ">{order.shipping_rate}</td>
                                                                <td className="pl-3 pr-3 ">{order.orders_shipping_value ? "Yes" : "No"}</td>
                                                                <td className="pl-3 pr-3 ">{order.sales_channel}</td>
                                                                <td className="pl-3 pr-3 ">{order.user}</td>
                                                                <td className="pl-3 pr-3 ">
                                                                    <i
                                                                        className="fa fa-edit mr-3"
                                                                        style={{ color: "" }}
                                                                        onClick={e =>
                                                                            EditRetailerOverrideModal(
                                                                                `${order.id}`,
                                                                                `${order.sku}`,
                                                                                `${order.sku}`,
                                                                                `${order.inventory_location}`,
                                                                                `${order.ship_to_state}`,
                                                                                order.marketing_fee,
                                                                                order.shipping_rate,
                                                                                `${order.sales_channel}`,
                                                                                `${order.retailer_id}`,
                                                                                `${order.fee_type}`,
                                                                                `${order.minimum_quantity}`,
                                                                                `${order.maximum_quantity}`,
                                                                                order.orders_shipping_value
                                                                            )
                                                                        }></i>
                                                                    <i
                                                                        className="fa fa-trash"
                                                                        style={{ color: "red" }}
                                                                        onClick={e =>
                                                                            DeleteRetailerOverrideModal(
                                                                                `${order.id}`,
                                                                                `${order.sku}`,
                                                                                `${order.inventory_location}`,
                                                                                `${order.ship_to_state}`,
                                                                                `${order.marketing_fee}`,
                                                                                `${order.shipping_rate}`,
                                                                                `${order.sales_channel}`,
                                                                                `${order.retailer}`,
                                                                                `${order.fee_type}`,
                                                                                order.orders_shipping_value
                                                                            )
                                                                        }></i>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={totalOrders} />
                                            </>
                                        )}
                                    </Card>
                                </div>
                            )}
                        </Row>
                    </div>
                </Container>
            )}

            <DeleteRetailerPayoutOverrideModal
                delete_retailer_override_assignee_list={delete_retailer_override_assignee_list}
                openDeleteModal={openDeleteModal}
                toggle={toggle}
                deleteRetailerOverrideState={deleteRetailerOverrideState}
                deleteRetailerOverrideSKU={deleteRetailerOverrideSKU}
                deleteRetailerOverrideSalesChannel={deleteRetailerOverrideSalesChannel}
                deleteRetailerOverrideShippingRate={deleteRetailerOverrideShippingRate}
                deleteRetailerOverrideFeeType={deleteRetailerOverrideFeeType}
                deleteRetailerOverrideMarketingFee={deleteRetailerOverrideMarketingFee}
                deleteRetailerOverrideRetailer={deleteRetailerOverrideRetailer}
                deleteRetailerOverrideInventoryLocation={deleteRetailerOverrideInventoryLocation}
                DeleteRetailerOverride={DeleteRetailerOverride}
                deleteRetailerOverrideId={deleteRetailerOverrideId}
                setOpenDeleteModal={setOpenDeleteModal}
                deleteOrdersShippingValue={deleteOrdersShippingValue}
            />

            {openEditModal && <EditOverrideBillingRetailerSearchForm
                url={"retailer-assignment-product-list/"}
                isOpen={openEditModal}
                toggle={e => setOpenEditModal(false)}
                loading={isLoading}
                default_state_value={updateRetailerOverrideState}
                default_inventory_location_value={updateRetailerOverrideInventoryLocation}
                default_variant_value={updateRetailerOverrideVariant}
                default_id={updateRetailerOverrideId}
                default_sku_value={updateRetailerOverrideSku}
                default_marketing_fee={updateRetailermarketing_fee}
                default_shipping_rate={updateRetailershipping_rate}
                default_sale_channel={updateRetailersales_channel}
                default_retailer={updateRetailerretailer}
                default_fee_type={updateFeeType}
                default_orders_shipping_value={updateOrdersShippingValue}
                default_min_qty = {updateRetailerMinquantity}
                default_max_qty = {updateRetailerMaxquantity}
            />}
        </>
    );
};

export default RetailerPayoutOverride;
