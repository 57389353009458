/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect } from 'react'
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";

import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const { user } = useContext(AuthContext)
  const location = useLocation();

  React.useEffect(() => {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
    window.Intercom && window.Intercom("update");
  }, [location]);

  React.useEffect(() => {
    const intercom_app_id = process.env.REACT_APP_INTERCOM_KEY
    // Function to initialize Intercom
    const initIntercom = () => {
      if (window.Intercom) {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: intercom_app_id,
          name: user?.first_name, // name
          email: user?.email, // email
        });
      } else {
        // This is a fallback in case the Intercom script hasn't loaded
        console.error("Intercom script not loaded yet.");
      }
    };

    if (user && !user.isWmsRetailer) {
      initIntercom();
    }

    // cleanup logic for the component unmounts
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [user]);


  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/") {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      {user && !user.isWmsRetailer &&<Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />}
      <div className="main-content" >
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/orders" />
        </Switch>

      </div>
    </>
  );
};

export default Admin;
