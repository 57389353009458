/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";

import { AuthContext } from "../../contexts/AuthContext";

var ps;

const Sidebar = (props, ref) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [collapseMenu, setCollapseMenu] = useState({
    product: false,
    order: false,
    general_settings: false,
    marketing_campaigns: false,
    flaviar: false,
  });
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  const sidebarRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef?.current && !sidebarRef.current.contains(event.target)) {
        setCollapseOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { user, isAuthentication } = useContext(AuthContext);
  return (
    <>
      {isAuthentication && (
        <div ref={sidebarRef}>
          <div
            className={"position-absolute pl-4 pt-4"}
            style={{ zIndex: 10, cursor: "pointer" }}
            onClick={() => setCollapseOpen(true)}
          >
            <h3 className={" p-0 m-0 mt-2 mt-md-0"}>
              <i className="fas fa-bars text-white text-lg p-0 m-0"></i>
            </h3>
          </div>

          {collapseOpen && (
            <Navbar
              className="navbar-vertical navbar-light bg-white position-fixed navbar-expand-xs"
              id="sidenav-main"
              style={{ zIndex: 999 }}
            >
              <div>
                <div className=" h1 text-primary text-center">
                  <img src="https://assets-barcart.s3.amazonaws.com/static/images/logo.png" height="28" alt=""/>
                </div>

                {/* Navigation */}
                <Nav navbar>
                  {!(user.isSupplierProduct) &&
                    <NavItem>
                      {/* Hide New dashboard till we get go ahead */}
                      <NavLink href={"/dashboard"}>
                        <i className={"ni ni-tv-2 text-primary"} />
                        Dashboard
                      </NavLink>
                    </NavItem>
                  }
                  {(!user.isSupplierProduct && !user.isWmsRetailer)  &&
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setCollapseMenu({
                          product: false,
                          order: !collapseMenu.order,
                        })
                      }
                    >
                      <i className={"ni ni-cart text-primary"} />
                      Orders
                    </NavLink>
                    <Collapse isOpen={collapseMenu.order}>
                      <div className={"bg-lighter pl-3"}>
                        <NavLink to={"/orders"} tag={NavLinkRRD}>
                          <i className="fas fa-grip-lines-vertical text-gray "></i>{" "}
                          Orders
                        </NavLink>
                        {(user.isAdmin || user.isRetailer) && (
                          <NavLink to={"/optimized-orders"} tag={NavLinkRRD}>
                            <i className="fas fa-grip-lines-vertical text-gray "></i>{" "}
                            Optimized Orders
                          </NavLink>
                        )}
                        {/**# 6355048100- Archive orders Sidebar menu */}
                        { !user.isEndConsumer &&
                        <NavLink to={"/archived-orders"} tag={NavLinkRRD}>
                          <i className="fas fa-grip-lines-vertical text-gray "></i>{" "}
                          Archived Orders
                        </NavLink> }
                        {(user.isAdmin || user.isRetailer) && (
                          <NavLink
                            href="fulfillment"
                          >
                            <i className="fas fa-grip-lines-vertical text-gray "></i>{" "}
                            Fulfillments
                          </NavLink>
                        )}
                        {(user.isAdmin || user.isRetailer) && (
                          <NavLink href={window.env.APP_URL + "batch/"}>
                            <i className="fas fa-grip-lines-vertical text-gray "></i>{" "}
                            Batches
                          </NavLink>
                        )}
                        {(user.isAdmin ||
                          (user.isRetailer &&
                            user.isRetailerBillingFeature)) && (
                            <NavLink href="billing">
                              <i className="fas fa-grip-lines-vertical text-gray "></i>{" "}
                              Payouts
                            </NavLink>
                          )}
                        {(user.isAdmin) && (
                            <NavLink href={"/advent-calendar"}>
                              <i className="fas fa-grip-lines-vertical text-gray "></i>{" "}
                              Advent Calendar
                            </NavLink>
                          )}
                          {(user.isAdmin) && (
                            <NavLink href={"/brand-order-monitoring"}>
                              <i className="fas fa-grip-lines-vertical text-gray "></i>{" "}
                              Brand Order Monitoring
                            </NavLink>
                          )}
                      </div>
                    </Collapse>
                  </NavItem>
                  }
                  {(user.isAdmin || user.isAtBulkOrder) && (
                    <NavLink href={"/bulk-orders"}>
                      <i className={"fas fa-cart-plus text-primary"} />
                      Bulk Orders
                      <Badge className={"position-relative mt-1 ml-2"} color={"primary"}> BETA </Badge>
                    </NavLink>
                  )}
                  {(user.isProductFeature) && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setCollapseMenu({
                            product: !collapseMenu.product,
                            order: false,
                          })
                        }
                      >
                        <i className={"fas fa-dolly text-primary"} />
                        Product
                      </NavLink>
                      <Collapse isOpen={collapseMenu.product}>
                        <div className={"bg-lighter pl-3"}>
                          <NavLink href="product">
                            <i className="fas fa-grip-lines-vertical text-gray "></i>{" "}
                            Product
                          </NavLink>
                          { 
                            (user.isAdmin || (user.isSupplier && user.IsBrandJose)) &&
                            <NavLink href={"/jose-cuervo-inventory"}>
                              <i className="fas fa-grip-lines-vertical text-gray" /> Jose Cuervo Inventory
                            </NavLink>
                          }
                          {
                            !user.isRetailer && 
                            <NavLink href={"/product-availability"}>
                              <i className="fas fa-grip-lines-vertical text-gray" /> Availability
                              <Badge className={"position-relative mt-1 ml-2"} color={"primary"}> BETA </Badge>
                            </NavLink>
                          }
                          {
                            (user.isAdmin || user.isRetailer) &&
                            <NavLink href={"/product-inventory"}>
                              <i className="fas fa-grip-lines-vertical text-gray"></i> Inventory
                            </NavLink>
                          }
                          { 
                            (user.isAdmin || user.isRetailer) &&
                            <NavLink href={"/retailer-staging"}>
                              <i className="fas fa-grip-lines-vertical text-gray" /> Connect
                            </NavLink>
                          }
                          { user.isAdmin &&
                          <NavLink href={"/combo-skus"}>
                            <i className="fas fa-grip-lines-vertical text-gray" /> Combo Skus
                          </NavLink>
                          }
                        </div>
                      </Collapse>
                    </NavItem>
                  )}
                  {user.isDiscountCodeFeature && (
                    <NavItem>
                      <NavLink href={window.env.APP_URL + "discount-code/"}>
                        <i className={"fas fa-percent  text-primary"} />
                        Discount Codes
                      </NavLink>
                    </NavItem>
                  )}
                  {(!user.isRetailer && !user.isSupplierProduct && !user.isEndConsumer) &&
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setCollapseMenu({
                          product: false,
                          marketing_campaigns: !collapseMenu.marketing_campaigns,
                        })
                      }
                    >
                      <i className={"fas fa-bullhorn text-primary"} />
                      Marketing Campaigns
                    </NavLink>
                      <Collapse isOpen={collapseMenu.marketing_campaigns}>
                        <div className={"bg-lighter pl-3"}>
                          <NavLink href="/marketing-campaign">
                            <i className={"fas fa-grip-lines-vertical text-gray"}></i>
                            Marketing Campaigns
                          </NavLink>
                          {(user.isAdmin || user.isSupplier) && (
                            <NavLink to={"shipping-chargebacks"} tag={NavLinkRRD}>
                              <i className="fas fa-grip-lines-vertical text-gray"></i>{" "}
                              Shipping Chargebacks
                            </NavLink>
                          )}
                          {(user.isAdmin || user.isSupplier) && (
                            <NavLink to={"shipping-invoice-chargebacks"} tag={NavLinkRRD}>
                              <i className="fas fa-grip-lines-vertical text-gray"></i>{" "}
                              Shipping Chargeback Invoices
                            </NavLink>
                          )}
                          {(user.isAdmin || user.isSupplier)  &&
                              <NavLink href="/discount-code-chargebacks">
                                <i className={"fas fa-grip-lines-vertical text-gray"}></i>
                                  Discount Code Chargebacks
                              </NavLink>
                          }
                          {(user.isAdmin || user.isSupplier) && (
                            <NavLink href="/discount-code-invoice-chargebacks">
                              <i className={"fas fa-grip-lines-vertical text-gray"}></i>
                                Discount Code Chargebacks Invoice
                            </NavLink>
                          )}
                        </div>
                      </Collapse>
                  </NavItem>
                  }
                  {/*{ (user.isAdmin || user.isPro || user.isStandard) && <NavItem>*/}
                  {/*    <NavLink href={window.env.APP_URL + "facebook/pixel/"}>*/}
                  {/*        <i className={"fab fa-facebook text-primary"}/>*/}
                  {/*        Facebook Pixel*/}
                  {/*    </NavLink>*/}
                  {/*</NavItem> }*/}

                  {user.isAdmin && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setCollapseMenu({
                            product: false,
                            flaviar: !collapseMenu.flaviar,
                          })
                        }
                      >
                      <i className={"fas fa-cocktail text-primary"} />
                          F3
                      </NavLink>
                      <Collapse isOpen={collapseMenu.flaviar}>
                        <div className={"bg-lighter pl-3"}>
                          {user.isAdmin && (
                            <NavLink to={"manual-child-orders"} tag={NavLinkRRD}>
                              <i className="fas fa-grip-lines-vertical text-gray"></i>{" "}
                                Manual Child Orders
                            </NavLink>
                          )}
                        </div>
                      </Collapse>
                    </NavItem>
                  )}
                  

                  {(!user.isRetailer && !user.isAdmin && !user.isSupplierProduct && !user.isSupplier && !user.isEndConsumer) && (
                    <NavItem>
                      <NavLink href={window.env.APP_URL + "customer/"}>
                        <i className={"fas fa-user-tie text-primary"} />
                        Customers
                        <Badge
                          className={"position-relative mt-1 ml-2"}
                          color={"primary"}
                        >
                          BETA
                        </Badge>
                      </NavLink>
                    </NavItem>
                  )}
                  {(user.isAdmin || user.isSupplier || user.isSupplierProduct) && (
                    <NavItem>
                      <NavLink href="/customers">
                        <i className={"fas fa-user-tie text-primary"} />
                        Customers
                      </NavLink>
                    </NavItem>
                  )}

                  { /*{user.isAdmin && (
                    <NavItem>
                      <NavLink href={"/users"}>
                        <i className={"ni ni-single-02 text-primary"} />
                        Users
                      </NavLink>
                    </NavItem>
                  )} */}

                  {user.isAdmin && (
                    <NavItem>
                      <NavLink href={"/users"}>
                        <i className={"ni ni-single-02 text-primary"} />
                        Users
                      </NavLink>
                    </NavItem>
                  )}
                  {user.isAdmin && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setCollapseMenu({
                          product: false,
                          general_settings: !collapseMenu.general_settings,
                        })
                      }
                    >
                      <i className={"fas fa-user-tie text-primary"} />
                      Retailers
                    </NavLink>
                    <Collapse isOpen={collapseMenu.general_settings}>
                      <div className={"bg-lighter pl-3"}>
                        {(user.isAdmin) && (
                          <NavLink href={"/inventory-location"}>
                            <i className={"fas fa-grip-lines-vertical text-gray"} />
                            Locations
                          </NavLink>
                        )}
                        {(user.isAdmin) && (
                          <NavLink to={"/retailer-override"} tag={NavLinkRRD}>
                            <i className="fas fa-grip-lines-vertical text-gray"></i>{" "}
                            Retailer Assignment
                          </NavLink>
                        )}
                        {(user.isAdmin) && (
                          <NavLink to={"/retailer-payout-override"} tag={NavLinkRRD}>
                            <i className="fas fa-grip-lines-vertical text-gray"></i>{" "}
                            Payout Overrides
                          </NavLink>
                        )}
                        {(user.isAdmin) && (
                          <NavLink to={"/order-status-override"} tag={NavLinkRRD}>
                            <i className="fas fa-grip-lines-vertical text-gray"></i>{" "}
                            Order Status Override
                          </NavLink>
                        )}
                      </div>
                    </Collapse>
                  </NavItem>
                  )}
                  {user.isAdmin && (
                  <NavItem>
                    <NavLink href={window.env.APP_URL + "marketing/campaign-type/"}>
                        <i className={"fas fa-cogs text-primary"} />
                        Configuration
                    </NavLink>
                  </NavItem>
                  )}
                  {/* Monday Item - 6586117328 */}
                  {(user.isAdmin || user.isMaster || user.isManager) && (
                  <NavItem>
                    <NavLink href={"/subscription"}>
                        <i className={"fa fa-bars text-primary"} />
                        Subscription
                    </NavLink>
                  </NavItem>
                  )}
                  {/* Monday Item - 6429709459 */}
                  {user.hasInsights && (
                  <NavItem>
                    <NavLink href={window.env.APP_URL + "insight/"}>
                      <i className="fas fa-solid fa-info-circle text-primary"></i>
                      Insights
                    </NavLink>
                  </NavItem>
                  )}
                  {/*6847419241 - Add filters to Store Page */}
                  {(user.isAdmin || user.isMaster || user.isManager) && (
                  <NavItem>
                    <NavLink href={"/store"}>
                    <i className="fas fa-store text-primary"></i>
                      Store
                    </NavLink>
                  </NavItem>
                  )}
                </Nav>
              </div>
            </Navbar>
          )}
        </div>
      )}
    </>
  );
};

export default forwardRef(Sidebar);
