// Monday Item - 6586117328
// Created By - Aditya Bhoslae

import { AuthContext } from "contexts/AuthContext";
import React, { useState, useContext, useEffect } from "react";
import { Card, CardHeader, Col, Container, Row, Button } from "reactstrap";
import { processURLSearchParams } from "utils/urls";
import Filter from "../../components/Filters/Filter";
import { getParams, removeBadge, onFilterChange, clearFilter } from "../../utils/commonFunctions";
import useFilter from "customHooks/useFilter";
import { api } from "services/api";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import axios from "axios";
import fileDownload from "js-file-download";
import BadgePillFilter from "components/Filters/Badge";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import SubscriptionTable from "./SubscriptionTable";
import PagePagination from "feature/pagination/Pagination";
import { useLocation } from "react-router-dom";
import { addURLParam, removeURLParam } from "../../utils/urls";

function UsersListingPage() {
    const baseURL = window.env.APP_URL ? window.env.APP_URL : "http://localhost:8000/";
    const { user, isAuthentication } = useContext(AuthContext);
    const [params, setParams] = useState([]);
    const [page, setPage] = useState(1);
    const [subscriptions, setSubscriptions] = useState([]);
    const [subscriptionsCount, setsubscriptionsCount] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useFilter({});
    const [refresh, setRefresh] = useState(false);
    const [brandOptions, setBrandOptions] = useState([]);
    const [exportButton, setExportButton] = useState({
        label: "Export",
        disabled: false
    });
    const [planOptions, setPlanOptions] = useState([]);
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });
    const { addToast } = useToasts();
    const cancelToken = React.useRef(undefined);
    const searchInput = document.getElementById("subscriptionSearch");

    const createRemoveBadgeWrapper = (filter, setFilter) => e => {
        removeBadge(
            e,
            filter,
            setFilter,
            () => {},
            () => {},
            "",
            searchInput
        );
    };

    const [shouldFetchBrands, setShouldFetchBrands] = useState(false);

    // Monday Task 6290763425: Added Dropdown functionality for brands column
    const fetchBrands = async () => {
        try {
            const response = await api.get("subscriptions/available-brands/");
            const filteredBrands = response.data;
            const options = filteredBrands.map(brand => ({
                value: brand.id,
                label: brand.name
            }));
            setBrandOptions(options);
        } catch (error) {
            console.error("Failed to fetch brands", error);
        }
    };

    const fetchSubscriptionPlan = async () => {
        try {
            const response = await api.get("subscriptions/filter/plan");
            const plans = response.data.results;
            const options = plans.map(plan => ({
                value: plan.id,
                label: plan.name
            }));
            setPlanOptions(options);
        } catch (error) {
            console.error("Failed to fetch brands", error);
        }
    };

    useEffect(() => {
        fetchBrands();
        fetchSubscriptionPlan();
    }, []);

    // Fetch brands whenever shouldFetchBrands flag is set to true
    useEffect(() => {
        if (shouldFetchBrands) {
            fetchBrands();
            setShouldFetchBrands(false); // Reset the flag after fetching
        }
    }, [shouldFetchBrands]);

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    const [currentFilter, setCurrentFilter] = useState(filter);

    // Handle Refresh
    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    useEffect(() => {
        if (filter !== currentFilter) {
            setPage(1);
            setCurrentFilter(filter);
        }
    }, [filter, currentFilter]);

    // To Fetch all data from API
    useEffect(() => {
        setLoading(true);
        let new_params = getParams(filter);
        if (typeof cancelToken.current != undefined) {
            cancelToken.current !== undefined && cancelToken.current.cancel("Operation canceled due to new request.");
        }
        cancelToken.current = axios.CancelToken.source();
        new_params = {
            ...new_params,
            page
        };
        api.get("subscriptions/", {
            params: new_params,
            cancelToken: cancelToken.current.token
        })
            .then(response => {
                setSubscriptions(response.data.results);
                setsubscriptionsCount(response.data.count);
                const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                const previous = page !== 1 ? page - 1 : 1;
                const next = page !== totalPages ? page + 1 : totalPages;
                setPagination({
                    previous,
                    next,
                    pages: () => {
                        let startCount = 1;
                        let endCount = isMobile ? 4 : 12;
                        let numberCount = Math.round(endCount / 2);
                        const pageList = [];
                        if (numberCount < 0) numberCount = 1;
                        startCount = page - numberCount;
                        if (startCount <= 0) startCount = 1;
                        if (page !== 1) endCount = page + numberCount;
                        if (endCount > totalPages) endCount = totalPages;
                        if (totalPages >= endCount) {
                            for (let i = startCount; i <= endCount; i++) {
                                pageList.push(i);
                            }
                        } else if (totalPages >= 1) {
                            for (let i = 1; i <= totalPages; i++) {
                                pageList.push(i);
                            }
                        }
                        return pageList;
                    }
                });
                setLoading(false);
                delete new_params["page"];
                delete new_params["ordering"];
                setParams(new_params);
            })
            .catch(err => {
                setLoading(false);
                if (axios.isCancel(err)) {
                    console.log("Request canceled", err.message);
                } else if (err.response && err.response.status === 403) {
                    addToast("You do not have permission to access this page.", {
                        appearance: "error",
                        autoDismiss: true
                    });
                }
            });
    }, [page, filter, currentFilter, refresh, isMobile]);

    let timer = null;
    const handleSearch = e => {
        addURLParam(window, "search", e.target.value);
        clearTimeout(timer);
        timer = setTimeout(function () {
            setFilter({
                ...filter,
                search: e.target.value
            });
        }, 750);
    };

    const urlSearchParams = useLocation().search;
    React.useMemo(() => {
        if (filter.urlParamsProcessed === false) processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter);
    }, [urlSearchParams, filter, setFilter]);

    /**
     * Function for handling Export Functionality with Filters
     */
    const handleExportSubscription = () => {
        // Update the export button state to show the exporting process
        setExportButton({
            label: "Exporting...",
            disabled: true
        });
        let new_params = params;
        api.get("subscriptions/export/", {
            params: new_params
        })
            .then(response => response.data)
            .then(response => {
                const success = response?.success | false;
                if (success) {
                    addToast(response.message, {
                        appearance: "success",
                        autoDismiss: false
                    });
                } else {
                    const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
                    const filename = "subscription-" + timestamp + ".csv";
                    fileDownload(response, filename);
                }
                setExportButton({
                    label: "Export",
                    disabled: false
                });
            });
        // Reset the export button state after a short delay
        setTimeout(() => {
            setExportButton({
                label: "Export",
                disabled: false
            });
        }, 3000); // Adjust the delay as needed
    };

    const handlePagination = page => {
        setPage(page);
    };

    // Function to handle brands change (Multi Select)
    const handleBrandChange = async (subscriptionId, subscription_item_id, selectedBrands) => {
        // 7323252007 - avoid api call when we remove search text inside select input
        if(!selectedBrands || selectedBrands?.length === 0) {
            // Do nothing if no brands are selected
            return;
        }
        const brandIds = selectedBrands?.map(brand => brand.value);

        try {
            await api.put(`subscriptions/brands/${subscriptionId}/`, { brands: brandIds, subscription_item_id: subscription_item_id }).then(response => {
                console.log("response: ", response);
                const { message } = response?.data;
                setSubscriptions(prevData =>
                    prevData.map(sub =>
                        sub.subscription_id === subscriptionId
                            ? {
                                  ...sub,
                                  subscription_items: sub.subscription_items.map(item =>
                                      item.id === subscription_item_id
                                          ? {
                                                ...item,
                                                brands: selectedBrands.map(brand => ({ id: brand.value, name: brand.label })),
                                                total_brands: selectedBrands?.length
                                            }
                                          : item
                                  )
                              }
                            : sub
                    )
                );
                addToast(message, { appearance: "success", autoDismiss: true });
                setShouldFetchBrands(true);
            });
        } catch (error) {
            console.error("Failed to update subscription brands", error);
            addToast(error.toString(), { appearance: "error", autoDismiss: true });
        }
    };

    // Monady Task: 6873707985 - Function to handle plan change
    const handlePlanChange = async (selectedOption, subscriptionItem_id) => {
        console.log("selectedOption: ", selectedOption);
        console.log("subscriptionItem.id: ", subscriptionItem_id);

        try {
            const response = await api.put(`/subscriptions/plan/${subscriptionItem_id}/`, { plan: selectedOption.value });

            if (response.data && response.data.success) {
                addToast(response.data.message, { appearance: "success", autoDismiss: true });
                console.log(response.data.message);
            }
        } catch (error) {
            console.error("Failed to update subscription plan", error);

            if (error.response) {
                // Server responded with a status other than 200 range
                if (error.response.data && error.response.data.message) {
                    addToast(error.response.data.message, { appearance: "error", autoDismiss: true });
                } else if (error.response.data) {
                    // Assuming error response has other validation errors
                    const errors = error.response.data;
                    for (const key in errors) {
                        if (errors.hasOwnProperty(key)) {
                            addToast(`${key}: ${errors[key]}`, { appearance: "error", autoDismiss: true });
                        }
                    }
                } else {
                    addToast("An error occurred while updating the subscription plan.", { appearance: "error", autoDismiss: true });
                }
            } else if (error.request) {
                // The request was made but no response was received
                addToast("No response received from the server. Please try again.", { appearance: "error", autoDismiss: true });
            } else {
                // Something happened in setting up the request that triggered an Error
                addToast("An unexpected error occurred. Please try again.", { appearance: "error", autoDismiss: true });
            }
        }
    };

    if (!user || !(user.isMaster || user.isAdmin)) {
        return null; // Render nothing if the user does not have the required role
    }
    return (
        <>
            <Container fluid>
                <div className={"pt-7"}>
                    {isAuthentication && (
                        <Row className={"mb-2"}>
                            <Col xs={8} lg={2} className={"text-left"}>
                                <input
                                    type="text"
                                    className={"form-control form-control-sm"}
                                    placeholder={"Owner name or email"}
                                    id="subscriptionSearch"
                                    defaultValue={filter.search}
                                    onChange={handleSearch}
                                    newparams={""}
                                />
                            </Col>
                            <Col className={"text-right"}>
                                <div className={"d-none d-lg-inline-block mr-2"}>
                                    {/* 7278215305 - increase font size */}
                                    <button className="button-font-size btn btn-neutral btn-sm p-9px" onClick={() => handleRefresh()}>
                                        <i className="fas fa-sync-alt"></i>
                                    </button>
                                </div>
                                <Filter
                                    name={"Brand"}
                                    prefix={"brand"}
                                    onChange={e => onFilterChange(e, "brand", filter, setFilter)}
                                    newparams={params}
                                    selected={filter.brands}
                                    url={"subscriptions/filter/brand"}
                                    onClear={() => clearFilter("brand", setFilter)}
                                />
                                <Filter
                                    name={"Plan"}
                                    prefix={"plan"}
                                    onChange={e => onFilterChange(e, "plan", filter, setFilter)}
                                    newparams={params}
                                    selected={filter.plan}
                                    inputSearch={false}
                                    url={"subscriptions/filter/plan"}
                                    onClear={() => clearFilter("plan", setFilter)}
                                />
                                {/* 7196353058 - Subscription Page Status Filter */}
                                <Filter
                                    name={"Status"}
                                    prefix={"status"}
                                    onChange={e => onFilterChange(e, "status", filter, setFilter)}
                                    newparams={params}
                                    selected={filter.status}
                                    inputSearch={false}
                                    url={"subscriptions/filter/status"}
                                    onClear={() => clearFilter("status", setFilter)}
                                />
                                <Button
                                    color={"primary"}
                                    size={"sm"}
                                    disabled={exportButton.disabled}
                                    onClick={() => handleExportSubscription()}
                                    className="button-font-size p-9px">
                                    <i className="fas fa-file-csv"></i> {exportButton.label}
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        {isAuthentication && (
                            <div className="col">
                                <Card className="shadow " style={{ overflowX: "scroll" }}>
                                    <CardHeader className="border-0">
                                        <Row className="d-flex justify-content-between align-items-center p-1">
                                            <div className="d-flex">
                                                <h3 className="mb-0">
                                                    Subscriptions <small>({subscriptionsCount})</small>
                                                </h3>
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col sm="12">
                                                <BadgePillFilter data={filter} onClick={createRemoveBadgeWrapper(filter, setFilter)} />
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    {isLoading ? (
                                        <div className="text-center mt-3 mb-3">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <SubscriptionTable
                                                subscriptions={subscriptions}
                                                user={user}
                                                planOptions={planOptions}
                                                handlePlanChange={handlePlanChange}
                                                brandOptions={brandOptions}
                                                handleBrandChange={handleBrandChange}
                                            />
                                            <PagePagination
                                                pagination={pagination}
                                                handlePagination={handlePagination}
                                                page={page}
                                                totalCount={subscriptionsCount}
                                            />
                                        </>
                                    )}
                                </Card>
                            </div>
                        )}
                    </Row>
                </div>
            </Container>
        </>
    );
}
export default UsersListingPage;