/*
    Author - Akshay Vishwakarma
*/
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { api } from "services/api";
import Moment from "react-moment";
function ShipEngineHistoryModal({ modalIsOpen, setModalIsOpen, id }) {
    const [data, setData] = React.useState([]);

    const toggleModel = () => {
        setModalIsOpen(!modalIsOpen);
    };


    React.useEffect(() => {
        if (id) {
            api.get(`shipengine/list/webhook/?tracking_number=${id}`)
                .then(response => {
                    const status = response?.status;
                    const data = response?.data;
                    if (status === 200 && data?.webhooks?.length > 0) {
                        setData(data?.webhooks)
                    }
                })
                .catch(error => {
                    console.error("Error making the request:", error);
                });
        }

    }, [id])

    return (
        <Modal isOpen={modalIsOpen} toggle={toggleModel} size="xl" >
            <ModalHeader className={"bg-primary text-white"}>
                <span className="text-white h3">Webhook History : {id}</span>
            </ModalHeader>
            <ModalBody style={{ maxHeight: "50vh", overflowY: "scroll" }}>
                <div>
                    <Table responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                    Tracking Number
                                </th>
                                <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                    Created At
                                </th>
                                <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                    Event
                                </th>
                                <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 ? data?.map((shippingData, index) => (<tr key={index}>
                                <td className="pl-3 pr-3" >{shippingData.id}</td>
                                <td className="pl-3 pr-3"><Moment format="MMMM DD YYYY, h:mm:ssa" withTitle>
                                    {shippingData?.created_at}
                                </Moment></td>
                                <td className="pl-3 pr-3">{shippingData.event} Created</td>
                            </tr>)) : null}
                        </tbody>
                    </Table>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggleModel}>Close</Button>
            </ModalFooter>
        </Modal>
    );
}
export default ShipEngineHistoryModal;