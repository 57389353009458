import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { api } from "../../services/api";
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Input } from "reactstrap";
import { couriers } from "utils/constants";
import axios from "axios";

export const ManualFulfilmentForm = ({ isOpen, orders, onExit }) => {

  const [showModal, setShowModal] = useState(isOpen)
  const [loading, setLoading] = useState(false)
  const [trackingType, setTrackingType] = useState('')
  const [trackingCompany, setTrackingCompany] = useState('')
  const [trackingNumber, setTrackingNumber] = useState('')
  const [trackingUrl, setTrackingUrl] = useState('')
  const [shipmentStatus, setShipmentStatus] = useState('')
  const [orderId, setOrderId] = useState('')
  const [orderNumber, setOrderNumber] = useState("");
  const { addToast } = useToasts()

  const toggle = () => {
    setShowModal(!showModal)
  }

  useEffect(() => {
    setShowModal(isOpen)
    if (isOpen && orders) {
      setTrackingType('')
      setTrackingCompany('')
      setTrackingNumber('')
      setTrackingUrl('')
      setShipmentStatus('')
      setOrderId(orders[0].id)
    }
  }, [isOpen])

  const createFulfillment = () => {
    let data = {
      order_id: orderId,
      tracking_type: trackingType,
      order_number: orderNumber,
    }

    if (trackingType !== 'local') {
      data = {
        ...data,
        tracking_company: trackingCompany,
        tracking_number: trackingNumber,
        tracking_url: trackingUrl,
        tracking_status: shipmentStatus,
      }
    }
    setLoading(true);
    // Create manual fulfillement
    api.post("fulfillment/manual/create/", data).then((response) => {
      setShowModal(false);
      setLoading(false);
      if (response.data.success) {
        addToast("Manual Fulfillment created successfully!", { appearance: "success", autoDismiss: true });
      } else {
        let message = "Something went wrong, please try again!!"
        if (response?.data?.message) message = response.data.message;
        addToast(message, { appearance: "error" });
      }
    }).catch((error) => {
      let message = "Something went wrong, please try again!!"
      if (error?.response?.data?.message) {
        message = error.response.data.message;
      };
      setLoading(false);
      addToast(message, { appearance: "error" });
    });
  }

  /**
   * On click of submit call the API to create manual fulfillment
   * @param {*} e
   * @returns
   */
  const handleCreateFulfillment = (e) => {
    e.preventDefault();
    setLoading(true);
    // Don't perform courier validations if type is "Local Delivery"
    if(trackingType !== "local"){
      api.get("couriers/tracking/detect/", { params : { tracking_number : trackingNumber }})
      .then((response) => {
        if (response.data.success)
        { createFulfillment();}
        else {
          let message = "Something went wrong, please try again!!";
          if (!response?.data?.message) message = response.data.message;
          addToast(message, { appearance: "error" });
          setLoading(false);
        }
      }).catch((error) => {
        let message = "Something went wrong, please try again!!";
        if (error?.response?.data?.message) {
          message = error.response.data.message;
        }
        setLoading(false);
        addToast(message, { appearance: "error" });
      });
    } else createFulfillment();
  };

  const cancelToken = React.useRef(undefined);
  const detectCourierProvider = async(e) => {
    let trackingNumber = e.target.value;
    if(trackingNumber.length > 6) {
      if (cancelToken.current !== undefined) {
        cancelToken.current.cancel("Operation canceled due to new request.");
      }
      cancelToken.current = axios.CancelToken.source();

      api.get("couriers/tracking/detect/", {
        params : { tracking_number : trackingNumber}, 
        cancelToken: cancelToken.current.token})
      .then((response) => {
        if (response?.data?.results.length > 0 && response.data?.success) {
          setTrackingCompany(response.data.results)
          addToast("Courier company detected successfully!", { appearance: "success", autoDismiss: true });
        }
      }).catch((error) => {
        if(error.response && error.response.data){
          console.error(error.response.data.message);
        }
      });
  }
  }

  return (
    <>
      {showModal &&
        <Modal isOpen={showModal} toggle={toggle} onExit={onExit}>
          <Form onSubmit={handleCreateFulfillment}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
              <span className="text-white h3">{`Create Manual Fulfillment for Order ${orders[0].shopify_id}`}</span>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="type">Type *</Label>
                <Input
                  id="type"
                  type="select"
                  onChange={(e) => setTrackingType(e.target.value)}
                  required>
                  <option value="">------</option>
                  <option value={'local'}>Local Delivery</option>
                  <option value={'shipping'}>Shipping</option>
                </Input>
              </FormGroup>
              {(trackingType === '' || trackingType === 'shipping') ?
                <FormGroup>
                  <Label for="trackingnumber">Tracking number *</Label>
                  <Input
                    id="trackingnumber"
                    placeholder="Tracking number"
                    type="text"
                    onChange={async(e) => {
                      setTrackingNumber(e.target.value);
                      await detectCourierProvider(e);
                    }}
                    required
                  />
                </FormGroup> : undefined}
              {(trackingType == "" || trackingType == "shipping") ?
                <FormGroup>
                  <Label for="trackingCompany">Tracking company *</Label>
                  <Input
                    id="trackingCompany"
                    type="select"
                    value={trackingCompany}
                    onChange={(e) =>{
                      setTrackingCompany(e.target.value)}
                    }
                    required
                  >
                    {couriers.map((courier,index)=>{
                      if(index !== 1){
                        return(<option key={index} value={Object.keys(courier)[0]}>{Object.values(courier)[0]}</option>)
                      }
                    })}
                  </Input>
                </FormGroup> : undefined}
              {(trackingType === '' || trackingType === 'shipping') ?
                <FormGroup>
                  <Label for="trackingurl">Tracking URL(option)</Label>
                  <Input
                    id="trackingurl"
                    placeholder="Tracking URL(optional)"
                    type="text"
                    onChange={(e) => setTrackingUrl(e.target.value)}
                  />
                </FormGroup> : undefined}
              {(trackingType === '' || trackingType === 'shipping') ?
                <FormGroup>
                  <Label for="shipmentstatus">Shipment status *</Label>
                  <Input
                    id="shipmentstatus"
                    type="select"
                    onChange={(e) => setShipmentStatus(e.target.value)}
                    required
                  >
                    <option value="">------</option>
                    <option value={'no_status_yet'}>No Status Yet</option>
                    <option value={'label_printed'}>Label Printed</option>
                    <option value={'label_purchased'}>Label Purchased</option>
                    <option value={'attempted_delivery'}>Attempted Delivery</option>
                    <option value={'ready_for_pickup'}>Ready For Pickup</option>
                    <option value={'confirmed'}>Confirmed</option>
                    <option value={'in_transit'}>In Transit</option>
                    <option value={'out_for_delivery'}>Out For Delivery</option>
                    <option value={'failure'}>Failure</option>
                    <option value={'delivered'}>Delivered</option>
                  </Input>
                </FormGroup> : undefined}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>Cancel</Button>
              <Button color="primary" type="submit" disabled={loading}>
                Submit &nbsp;
                {loading && <Spinner size="sm" color="light" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>}
    </>
  )
}
