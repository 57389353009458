

import React, { useContext, useEffect, useState, useRef } from "react";
import {api} from "../../services/api";
import {AuthContext} from "../../contexts/AuthContext";

import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter,Table, UncontrolledTooltip } from "reactstrap";
import { Pagination, PaginationItem, PaginationLink, Button, ButtonGroup, Input } from "reactstrap";
import { useToasts } from "react-toast-notifications";

import BadgePillFilter from "../../components/Filters/Badge";

import Filter from "../../components/Filters/Filter";
import { useLocation } from "react-router-dom";
import { addURLParam, processURLSearchParams, removeAllURLParams, removeURLParam } from "utils/urls";
import axios from "axios";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import "../../utils/commonStyles.css";

const Availability = (props) => {
    const { addToast } = useToasts();
    const searchInputReference = useRef(null)
    const [isLoading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [count, setCount] = useState(0);
    const [skus, setSkus] = useState();
    const [locations, setLocations] = useState();
    const [totals, setTotals] = useState();
    const {user, isAuthentication } = useContext(AuthContext)
    const [page, setPage] = useState(1)
    const [params, setParams] = useState([])
    const [onlyAvailable, setOnlyAvailable] = useState(true)
    const [filter, setFilter] = useState({ q: "", brands: [], products: [], })
    const [pagination, setPagination] = useState({ previous: null, next: null,
        pages: () => { return [] }
    });
    const cancelToken = React.useRef(undefined);

    const handleClearAll = (event) => {
        event.preventDefault();
        setFilter({ q: "", brands: [], products: [], })
        searchInputReference.current.value = "";
    }

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    const handleCellButtonClick = (event, variant, location) => {
        event.preventDefault();
        document.getElementById("button--" + variant + "--" + location).classList.toggle("invisible")
        document.getElementById("button--" + variant + "--" + location).classList.toggle("d-none")
        document.getElementById("input--" + variant + "--" + location).value = parseInt(document.getElementById("button--" + variant + "--" + location).textContent)
        document.getElementById("input--" + variant + "--" + location).classList.toggle("invisible")
        document.getElementById("input--" + variant + "--" + location).classList.toggle("d-none")
    }

    const decorateCellButton = (id, quantity) => {
        let _element = document.getElementById(id)
        _element.classList.remove("btn-outline-secondary", "btn-outline-warning", "btn-outline-danger")
        /** 6 > sku[location]["quantity"] 
         *  ? "danger" : 12 > sku[location]["quantity"] && 6 <= sku[location]["quantity"] 
         * ? "warning" : "secondary" */
        let _color = 6 > quantity ? "danger" : 12 > quantity && 6 <= quantity ? "warning" : "secondary"
        _element.classList.add("btn-outline-" + _color)
    }

    const handleCellInputBlur = (event, variant, location) => {
        event.preventDefault();
        (async() => {
            try {
                let _quantity = document.getElementById("input--" + variant + "--" + location).value
                let _response = await api.put(`availability/variant/${variant}/inventory/${location}/`, {
                    variant: variant,
                    inventory: location,
                    value: _quantity,
                })
                decorateCellButton("button--" + variant + "--" + location, parseInt(_quantity))
                addToast('Inventory quantity updated Successfully.', { appearance: 'success', autoDismiss: true });
            } catch(_error) {
                addToast('Unable to update inventory quantity.', { appearance: 'error', autoDismiss: true });
            }
        })();
        document.getElementById("button--" + variant + "--" + location).textContent = document.getElementById("input--" + variant + "--" + location).value
        document.getElementById("button--" + variant + "--" + location).classList.toggle("invisible")
        document.getElementById("button--" + variant + "--" + location).classList.toggle("d-none")

        
        document.getElementById("input--" + variant + "--" + location).classList.toggle("invisible")
        document.getElementById("input--" + variant + "--" + location).classList.toggle("d-none")
    }

    const getParams = () => {
        let _params = { page, page_size: 50, }
        if (filter.brands?.length > 0) {
            _params.brands = []
            filter.brands.map(item => _params.brands.push(item.value))
        }

        if (filter.products?.length > 0) {
            _params.products = []
            filter.products.map(item => _params.products.push(item.value))
        }

        if (filter?.q?.length > 0) {
            _params.q = filter.q
        }

        if (searchText) {
            _params.q = searchText
        }

        setParams(_params)
        return _params;
    }

    const clearFilter = (type) => {
        setFilter({ ...filter, [type]: [] })
    }

    const handleSearch = (event) => {
        addURLParam(window, "search", event.target.value);
        let timer = null;
        clearTimeout(timer);
        timer = setTimeout(function () {
          setFilter({ ...filter, q: event.target.value, });
        }, 750);
      };

    const handleRemoveFilters = (event) => {
        if(event.component === "product") {
            let selection = filter.products;
            selection = selection.filter((item) => item.value !== event.item.value);
            setFilter({ ...filter, products: selection });
        }

        if(event.component === "brand") {
            let selection = filter.brands;
            selection = selection.filter((item) => item.value !== event.item.value);
            setFilter({ ...filter, brands: selection });
        }

        if(event.component === "search") {
            setFilter({ ...filter, q: "" });
            searchInputReference.current.value = "";
        }
    }


    const handleFilters = (event, type) => {

        let selection = [...new Set(filter[type])]
    
        if (event.target.checked) {
            selection.push({ value: event.target.value, label: event.target.dataset.label })
        } else {
            selection = selection.filter(item => item.value !== event.target.value)
    }     
    setFilter({ ...filter, [type]: selection })

    }

    useEffect(() => {
        setLoading(true);
        const _params = getParams();
        (async () => {
            try {
                if (cancelToken.current !== undefined) {
                    cancelToken.current.cancel("Operation canceled due to new request.");
                  }
                  cancelToken.current = axios.CancelToken.source();
                let _response = await api.get("/product/availability", { params: _params, cancelToken: cancelToken.current.token })
                if(_response.status === 200) {
                    console.log(_response)
                    setLocations(_response.data.locations);
                    setSkus(_response.data.skus);
                    setCount(_response.data.count);
                    setTotals(_response.data.totals)
    
                    const totalPages = Math.ceil(_response.data.count / window.env.PAGE_SIZE);
                    const previous = (page !== 1) ? page - 1 : 1
                    const next = (page !== totalPages) ? page + 1 : totalPages
    
                    setPagination({ previous, next,
                        pages: () => {
                            let startCount = 1;
                            let endCount = isMobile ? 4 : 12;
                            let numberCount = Math.round(endCount / 2)
                            const pages = [];
                            if (numberCount < 0) numberCount = 1;
                            startCount = page - numberCount;
    
                            if (startCount <= 0) startCount = 1;
                            if (page !== 1) endCount = page + numberCount;
                            if (endCount > totalPages) endCount = totalPages;

    
                            if (totalPages >= endCount) {
                                for (let count = startCount; count <= endCount; count++) {
                                    pages.push(count)
                                }
                            } else if (totalPages >= 1) {
                                for (let count = 1; count <= totalPages; count++) {
                                    pages.push(count)
                                }
                            }
    
                            return pages;
                        }
                    })
                }
            } catch(_error) {
                console.log(_error)
            }
            setLoading(false);
            delete _params['page']
            setParams(_params);
        })();      
    }, [page, filter, refresh])

    const handlePagination = page => {
        setPage(page)
    }

    const renderConditionalCell = (sku, location) => {
        return (
        <td key={ sku.sku + "--" + location }>
            {
                user.isAdmin ? 
                    <React.Fragment>
                        <Button
                            onClick={ (event) => handleCellButtonClick(event, sku[location]["variant_id"], sku[location]["location_id"]) }
                            className="text-center"
                            outline={ true }
                            color={ 6 > sku[location]["quantity"] ? "danger" : 12 > sku[location]["quantity"] && 6 <= sku[location]["quantity"] ? "warning" : "secondary" }
                            id={ "button--" + sku[location]["variant_id"] + "--" + sku[location]["location_id"] }
                            style={{ "border": 0, "width": "3vw" }}>{ sku[location]["quantity"] }</Button>
                        <Input
                            max="999"
                            min="0"
                            step="1"
                            onBlur={ (event) => handleCellInputBlur(event, sku[location]["variant_id"], sku[location]["location_id"]) }
                            className="d-none invisible text-center" id={"input--" + sku[location]["variant_id"] + "--" + sku[location]["location_id"]}
                            style={{ width: "3vw" }}
                            bsSize="sm" type="number" defaultValue={ sku[location]["quantity"] }></Input> 
                    </React.Fragment>
                : sku[location]["quantity"]
            }
        </td>)
    }
    
    /**
    * Function for displaying available quantity as non editable field
    * @param {*} sku 
    * @param {*} location 
    * @returns 
    */
    const renderAvailableQuantity = (sku, location) => {
        location = location || "null";
        let color;
        switch (true) {
            case 6 > sku[location]["quantity"]:
                color = "red";
                break;
            case 12 > sku[location]["quantity"] && 6 <= sku[location]["quantity"]:
                color = "yellow";
                break;
            default:
                color = "gray";
                break;
        }
        const styles = {
            color: color,
            border: "none",
            width: "3vw"
        };
        return (
            <td key={sku.sku + "--" + location}>
                {user.isAdmin ? (
                    <React.Fragment>
                        <p className="text-center" style={styles}>
                            {sku[location]["quantity"]}
                        </p>
                    </React.Fragment>
                ) : (
                    sku[location]["quantity"]
                )}
            </td>
        );
    };

    useEffect(() => {
        if (searchText) {
            filter.q = searchText
        }
    }, [])

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let searchText = useQuery().get("search");

     return (
        <Container fluid={ true } className="pt-8">
                <Row>
                    <Col xs={4} className="text-left">
                        <input type="text"
                                ref={ searchInputReference }
                                className={"button-font-size form-control form-control-sm"}
                                placeholder={"Product name or SKU"}
                                defaultValue={filter.q}
                                onKeyDown={(event) => handleSearch(event) }
                            />
                    </Col>
                    <Col xs={8} className="text-right">
                        <ButtonGroup className="mr-2">
                            {/* 7278215305 - increase font size */}
                            <Button className="button-font-size btn btn-sm p-8px"
                                color={ true === onlyAvailable ? "primary" : "secondary" }
                                onClick={ (event) => setOnlyAvailable(!onlyAvailable) }>
                                { !onlyAvailable ? "Show Available" : "Show All"}
                            </Button>
                        </ButtonGroup>
                        {/* 7278215305 - increase font size */}
                        <Button className="button-font-size btn btn-neutral btn-sm p-9px" onClick={() => { setRefresh(!refresh) } }>
                            <i className="fas fa-sync-alt"></i>
                        </Button>
                        <Filter name={"Brand"}
                            prefix={"brands"}
                            selected={filter.brands}
                            onChange={(event) => handleFilters(event, "brands")}
                            onClear={() => clearFilter("brands")}
                            params={params}
                            url={"/availability/filter/brands"}
                        />
                        <Filter name={"Products"}
                            prefix={"products"}
                            selected={filter.products}
                            onChange={(event) => handleFilters(event, "products")}
                            onClear={() => clearFilter("products")}
                            params={params}
                            url={"/availability/filter/products"}
                        />
                    </Col>
                </Row>
                <Row className="pt-3">
                    <Col xs={12}>
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="mb-2">
                                    <Col xs={18}>
                                        <h3 className="mb-0">Availability <small>({count})</small></h3>
                                    </Col>
                                    <Col xs={4}>
                                        { ("" !== filter.q || 0 < filter.brands?.length || 0 < filter.products?.length) ? (
                                            <a
                                            href={void 0}
                                            onClick={ (event) => handleClearAll(event) }
                                            className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                            id="clear_all_filters"
                                            >
                                            <i className="fas fa-times-circle"></i> Clear All filters
                                            </a>
                                        ) : null }
                                    </Col>
                                </Row>
                                <Row className="pt-2 border-top">
                                    <Col xs={12}>
                                        <BadgePillFilter data={filter}
                                            onClick={ (event) => handleRemoveFilters(event) }
                                        />
                                    </Col>
                                </Row>
                                { isLoading &&
                                    <Row className="pt-3">
                                        <Col xs={12}>
                                            <div className="text-center mt-3 mb-3">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row> }
                            </CardHeader>
                            { !isLoading && isAuthentication && locations && skus &&
                                <React.Fragment>
                                    <CardBody className="px-0">
                                        <Table className="align-items-center table-flush" size="md" striped={ false } responsive={ true }>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col" className="pl-3 pr-3">Name</th>
                                                    <th scope="col" className="pl-3 pr-3">SKU</th>
                                                    <th scope="col" className="pl-3 pr-3">Size</th>
                                                    <th scope="col" className="pl-3 pr-3">Retail Price</th>
                                                    { locations && locations.map((location, index) =>
                                                            onlyAvailable && 0 !== totals[location] ?
                                                                <th key={ location + "--" + index } scope="col" className="pl-3 pr-3">{ location } ({ totals[location] })</th>
                                                            : !onlyAvailable &&
                                                                <th key={ location + "--" + index } scope="col" className="pl-3 pr-3">{ location } ({ totals[location] })</th>
                                                        ) }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {skus && skus.map((sku, index) =>
                                                        <tr key={sku.sku + "-" + index}>
                                                            <td className="">
                                                                <a href={ window.env.APP_URL + "product/" + sku["product_id"] } target="_blank" rel="noreferrer">
                                                                    {sku?.name?.length > 50 ? sku?.name.substring(0, 50) + '...' : sku?.name} <strong>({ totals[sku.variant_id] })</strong>
                                                                </a>
                                                                &nbsp; &nbsp;
                                                                <a href={`/?productName=${sku?.name}`} target="_blank" rel="noreferrer">
                                                                    <i class="fas fa-history" aria-hidden="true" id={"tooltip_" + sku.variant_id}> </i> 
                                                                </a>

                                                                <UncontrolledTooltip delay={0} placement="top" target={"tooltip_" + sku.variant_id}>
                                                                    Order History
                                                                </UncontrolledTooltip> 
                                                            </td>
                                                            <td>{sku.sku}</td>
                                                            <td>{sku.size}</td>
                                                            <td>${sku.retail_price.toFixed(2)}</td>
                                                            { locations &&
                                                                locations.map((location, index) =>
                                                                onlyAvailable && 0 !== totals[location] ?
                                                                    renderAvailableQuantity(sku, location)
                                                                : !onlyAvailable && renderAvailableQuantity(sku, location)
                                                                    ) }
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                    <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={count} />
                                </React.Fragment> }
                        </Card>
                    </Col>
                </Row>
        </Container>
    );


}

export default Availability