// 6592338005 - Optimize Order Listing API
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
  UncontrolledTooltip,
  UncontrolledDropdown,
} from 'reactstrap'
import Select from 'react-select'
import { api } from 'services/api'
import '../../assets/scss/order-details.scss'
import { ComponentContext } from '../../contexts/ComponentContext'
import ConfirmationModal from '../Modal/ConfirmationModal'

const capfirst = function (e) {
  return e.charAt(0).toUpperCase() + e.slice(1)
}

export const FinancialStatus = (props) => {
  let class_element = ''
  if (props.children === 'paid') {
    class_element = 'text-success'
  } else if (props.children === 'refunded') {
    class_element = 'text-gray'
  } else {
    class_element = 'text-yellow'
  }

  return <span className={class_element}>{props.children}</span>
}

export const PayoutStatus = (props) => {
  let currentValue = props.status

  const [value, setValue] = useState('')
  const [statusColor, setStatusColor] = useState('')
  const [modelIsOpen, setmodelIsOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { addToast } = useToasts()

  useEffect(() => {
    setValue(currentValue)
  }, [])

  useEffect(() => {
    let color = ''
    if (value === 'Paid') {
      color = 'border-success text-success'
    } else if (value === 'Pending') {
      color = 'border-pink text-grey'
    } else if (value === 'Unpaid') {
      color = 'border-danger text-danger'
    }
    setStatusColor(color)
  }, [value])

  const handleStatusOrder = (e) => {
    const data = {
      payout_status: e.target.value,
      orders: [parseInt(props.order)],
    }
    setValue(e.target.value)

    api.put('order/payout-status/update/', data).then(response => {
      const { payout_status } = response.data
      setValue(payout_status)
      addToast('Saved Successfully',
        { appearance: 'success', autoDismiss: true })
    }).catch(error => addToast(error.message, { appearance: 'error' }))

  }

  const { user } = useContext(AuthContext)

  /**
   * Toggle Confirmation popup
   */
  const toggleModel = () => {
    setmodelIsOpen(!modelIsOpen)
  }

  /**
   * Open confirmation popup and close the modal
   */
  const handleConfirm = (e) => {
    if (e.target.value === 'Void') {
      toggleModel()
      setShowModal(!showModal)
    } else {
      handleStatusOrder(e)
    }
  }

  return (
    <>
      <ConfirmationModal
        modelIsOpen={modelIsOpen}
        setmodelIsOpen={setmodelIsOpen}
        onConfirmation={() => handleStatusOrder({ target: { value: 'Void' } })}
        modalBodyMessage="Are you sure you want to set this payout status to VOID?"
      />
      {(user.isAdmin && value !== 'Paid') ?
        <select name="payout_status"
                className={`form-control form-control-sm mx-1 ${statusColor}`}
                onChange={(e) => handleConfirm(e)}
                style={{ width: '8rem' }}
                value={value}
                disabled={value === 'Void'}
        >
          {props.data?.map(status =>
            status.id !== 'paid' ?
              <option
                key={status.id}
                value={status.name}
              >{status.name}</option> : null,
          )}
        </select>
        : [
          user.isAdmin
            ? <select name="payout_status"
                      className={`form-control form-control-sm mx-1 ${statusColor}`}
                      style={{ width: '8rem' }}
                      disabled={true}
                      value={value}>
              <option
                key={value}
                value={value}
              >{capfirst(value)}</option>
            </select>
            : <span className={statusColor}>{value}</span>,
        ]
      }
    </>
  )
}

export const OrderStatus = (props) => {
  let currentValue = props.children || 'OPEN'

  const [value, setValue] = useState('')
  const [productCategory, setProductCategory] = useState('')
  const [productList, setProductList] = useState([]);
  const [statusColor, setStatusColor] = useState('')
  const [modelIsOpen, setmodelIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { addToast } = useToasts()

  useEffect(() => {
    if (currentValue !== value) {
      setValue(currentValue)
    }
    // Provide linItems for changing the product category dropdown options
    if (props.order)
    {
      api.get(`/order/line-item-details/?order_id=${props.order}`)
          .then(response => {
            setProductList(response.data);
          })
          .catch(error => console.log(error));
    }
  }, [props.children])

  useEffect(() => {
    getProductCategory()
  }, [productList])

  useEffect(() => {
    let color = ''
    if (value === 'OPEN') {
      color = 'border-success text-success'
    } else if (value === 'CANCELLED') {
      color = 'border-light text-light'
    } else if (value === 'OUT_OF_STOCK') {
      color = 'border-danger text-danger'
    } else if (value === 'ORDERED_PRODUCT') {
      color = 'border-warning text-warning'
    } else if (value === 'REJECTED') {
      color = 'border-danger text-danger'
    } else if (value === 'CONFIRMED') {
      color = 'border-success text-success'
    } else if (value === 'PRINTED') {
      color = 'border-success text-success'
    } else if (value === 'CLOSED') {
      color = 'border-light text-light'
    } else if (value === 'PREORDER') {
      color = 'border-info text-info'
    } else if (value === 'ESCALATE') {
      color = 'border-danger text-danger'
    } else if (value === 'ON_HOLD') {
      color = 'border-pink text-pink'
    } else if (value === 'INCORRECT_ADDRESS') {
      color = 'border-danger text-danger'
    } else if (value === 'PRICE_TOO_LOW') {
      color = 'border-dark-green text-dark-green'
    } else if (value === 'OOS_NA_WITH_DISTRIBUTOR') {
      color = 'border-danger text-danger'
    } else if (value === 'OUT_OF_COVERAGE_AREA') {
      color = 'border-danger text-danger'
    } else if (value === 'ACTION_REQUIRED') {
      color = 'border-danger text-danger'
    } else if (value === 'PENDING_CONFIRMATION') {
      color = 'border-danger text-danger'
    } else if (value === 'NEED_DISTRIBUTOR_INFO') {
      color = 'border-light-blue text-light-blue'
    } else if (value === 'PRE_CONFIRMED') {
      color = 'border-success text-success'
    } else if (value === 'RETURN_TO_SENDER') {
      color = 'border-dark text-dark'
    } else if (value === 'SPLIT_REQUIRED') {
      color = 'border-dark text-dark'
    } else if (value === 'LOST') {
      color = 'border-dark text-dark'
    } else if (value === 'MISSING_ITEMS') {
      color = 'border-dark text-dark'
    } else if (value === 'WRONG_ITEMS') {
      color = 'border-dark text-dark'
    } else if (value === 'DAMAGED') {
      color = 'border-dark text-dark'
    } else if (value === 'VOID') {
      color = 'border-warning text-warning'
    } else if (value === 'PRE_PAY') {
      color = 'border-primary text-primary'
    } else if (value === 'DRAFT') {
      color = 'border-dark text-dark'
    } else if (value === 'CASK_ORDERED') {
      color = 'border-warning text-orange'
    } else if (value === 'CASK_FILLED') {
      color = 'border-warning text-orange'
    } else if (value === 'SAMPLE_1_FULFILLED') {
      color = 'border-warning text-orange'
    } else if (value === 'SAMPLE_2_FULFILLED') {
      color = 'border-warning text-orange'
    } else if (value === 'SAMPLE_3_FULFILLED') {
      color = 'border-warning text-orange'
    } else if (value === 'VIRTUAL_VERTICAL_TESTING') {
      color = 'border-warning text-orange'
    } else if (value === 'REQUEST_BOTTLING_OF_YOUR_BARREL') {
      color = 'border-warning text-orange'
    } else if (value === 'SKU_NOT_FOUND') {
      color = 'border-danger text-danger'
    } else {
      color = 'border-default text-default'
    }
    setStatusColor(color)
  }, [value])

  const handleStatusOrder = (e) => {
    const data = {
      order_status: e.target.value,
      orders: [parseInt(props.order)],
    }

    api.put('order/order-status/update/', data).then(response => {
      const { order_status } = response.data
      setValue(order_status)
      addToast('Saved Successfully',
        { appearance: 'success', autoDismiss: true })
      props.updateOrderStatus(props.order)
    }).catch(error => addToast(error.response.data.error.message, { appearance: 'error' }))

  }
  const { user } = useContext(AuthContext)

  /**
   * Toggle Confirmation popup
   */
  const toggleModel = () => {
    setmodelIsOpen(!modelIsOpen);
  };
  /**
   * Open confirmation popup and close the modal
   */
  const handleConfirm = (e) => {
    if (e.target.value === "PRE_PAY") {
      toggleModel();
      setShowModal(!showModal);
    }
    else {
      handleStatusOrder(e);
    }
  };

  const getProductCategory = () => {
    productList?.map((item) => {
      if(item?.product_category !== null) {
        setProductCategory(item?.product_category)
      }
    })
  }

  return (
    <>
      {user && (user.isAdmin || user.isRetailer) ? (
        <UncontrolledDropdown size={'lg'} block>
          {/* 6193203826 - Block order changes on WMS Orders */}
          <DropdownToggle
            color={'white'}
            disabled={user.isRetailer || value === 'CANCELLED' || (value ===
              'CLOSED' && !user.isAdmin) || props.disabled}
            className={`text-primary text-left border-primary px-2 py-1 ${statusColor}`}
            style={{ width: '130px', overflow: 'hidden' }}
            caret>
              <span className={'d-inline-block '}
                    style={{ width: '100px', fontSize: 'small' }}
                    id={'id_assignee_' + parseInt(props.idOrder)}>
                  {value === 'ACTION_REQUIRED'
                    ? 'PENDING_CONFIRMATION'.substring(0, 11)
                    : value.substring(0, 11)}
              </span>
          </DropdownToggle>
          <DropdownMenu style={{ height: '300px', overflow: 'scroll' }}
                        positionFixed={true}>
            {props.data?.map(
              (status, index) =>
                ((user.isRetailer && !status.is_retailer_blocked) ||
                  user.isAdmin) && (
                  <DropdownItem
                    key={index}
                    value={status.id}
                    disabled={(props.status === "CLOSED" && status.id !== 'VOID' && status.id !== 'LOST' && status.id !== 'DAMAGED' && status.id !== 'RETURN_TO_SENDER' && status.id !== 'WRONG_ITEMS') || (status.id === "PRE_PAY")  || (productCategory?.toLowerCase() !== "barrel" && status.is_product_caterogry_barrel)}
                    onClick={(e) => handleConfirm(e)}
                  >
                    {status.name}
                  </DropdownItem>
                ),
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <span className={statusColor}>
            {props.children === 'ACTION_REQUIRED'
              ? 'PENDING_CONFIRMATION'.substring(0, 11)
              : props.children.substring(0, 11)}
        </span>
      )}
    </>
  )
}

export const BillingStatus = (props) => {
  let currentValue = props.children || 'OPEN'

  const [value, setValue] = useState('')
  const [statusColor, setStatusColor] = useState('')
  const { addToast } = useToasts()

  useEffect(() => {
    setValue(currentValue)
  }, [])

  useEffect(() => {
    let color = ''
    if (value === 'PRNDING') {
      color = 'border-light text-light'
    } else if (value === 'BILLED') {
      color = 'border-danger text-danger'
    } else if (value === 'PAID') {
      color = 'border-success text-success'
    } else if (value === 'PAST_DUE') {
      color = 'border-warning text-warning'
    } else if (value === 'VOID') {
      color = 'border-light text-light'
    } else if (value === 'PRE_PAY') {
      color = 'border-primary text-primary'
    } else if (value === 'DRAFT') {
      color = 'border-dark text-dark'
    }
    setStatusColor(color)
  }, [value])

  const handleStatusOrder = (e) => {
    const data = {
      billing_status: e.target.value,
      orders: [parseInt(props.order)],
    }

    api.put('order/chargeback-status/update/', data).then(response => {
      const { billing_status } = response.data
      setValue(billing_status)
      addToast('Saved Successfully',
        { appearance: 'success', autoDismiss: true })
    }).catch(error => addToast(error.message, { appearance: 'error' }))
  }

  const { user } = useContext(AuthContext)

  return (
    <>
      {user && user.isAdmin ?
        <UncontrolledDropdown size={'lg'} block>
          <DropdownToggle
            color={'white'}
            className={`text-primary text-left border-primary px-2 py-1 ${statusColor}`}
            style={{ width: '130px', overflow: 'hidden' }}
            caret>
              <span className={'d-inline-block '}
                    style={{ width: '100px' }}>
                  {value === 'ACTION_REQUIRED'
                    ? 'PENDING_CONFIRMATION'.substring(0, 11)
                    : value.substring(0, 11)}
              </span>
          </DropdownToggle>
          <DropdownMenu style={{ height: '230px', overflow: 'scroll' }}
                        positionFixed={true}>
            {props.data?.map((status, index) =>
              <DropdownItem key={index}
                            value={status.id}
                            onClick={(e) => handleStatusOrder(e)}
              >{status.name}
              </DropdownItem>,
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
        :
        <span className={statusColor}>
            {props.children === 'ACTION_REQUIRED'
              ? 'PENDING_CONFIRMATION'.substring(0, 11)
              : props.children.substring(0, 11)}
        </span>
      }
    </>
  )
}

export const BillingStatusColor = (props) => {
  let class_elment = ''
  if (props.children === 'PRNDING') {
    class_elment = 'border-light text-light'
  } else if (props.children === 'BILLED') {
    class_elment = 'border-warning text-warning'
  } else if (props.children === 'PAID') {
    class_elment = 'border-success text-success'
  } else if (props.children === 'PAST_DUE') {
    class_elment = 'border-warning text-warning'
  } else if (props.children === 'VOID') {
    class_elment = 'border-light text-light'
  }

  return <span className={class_elment}>{props.children}</span>
}

export const Fulfillment = (props) => {
  let class_elment = ''
  if (props.children === 'fulfilled') {
    class_elment = 'text-success text-capitalize'
  } else {
    class_elment = 'text-yellow text-capitalize'
  }

  return <span className={class_elment}>{props.children}</span>
}

const EditableInput = (props) => {
  const [editable, setEditable] = useState(false)
  const [value, setValue] = useState(props?.value)
  const { addToast } = useToasts()
  const order = props?.order

  const ref = useRef(null)
  const handleClick = e => {
    setEditable(!editable)
  }
  const handleValue = e => {
    const url = ``

    {
      (e.target.value) &&

      setValue(parseInt(e.target.value) || 0)
      setEditable(!editable)
      const data = {
        value: e.target.value,
        order: order,
      }
      // api.put(url, data)
      //     .then(response => {
      //         addToast('Saved Successfully', {appearance: 'success', autoDismiss: true});
      //     }).catch(error => addToast(error.message, {appearance: 'error'}));
    }
  }

  return (
    editable ?
      <input type="text"
             autoFocus
             onKeyPress={(event) => {
               if (!/[0-9]/.test(event.key)) {
                 event.preventDefault()
               }
             }}
             defaultValue={value == '---' ? null : value}
             style={{
               backgroundColor: 'transperent',
               borderColor: 'transperent',
             }}
             onBlur={handleValue}
             className="form-control form-control-sm input-inventory"
             ref={ref}/> :
      <a href={void (0)} onClick={handleClick} className="btn btn-link p-0">
        {(value || value === 0) ? value : '---'}
      </a>
  )
}

export const OrderStatusModalForm = props => {
  const [listStatus, setListStatus] = useState([])
  const [modal, setModal] = useState(props.isOpen)
  const [idOrders, setIdOrders] = useState([])
  const [orderStatus, setOrderStatus] = useState(null)

  const { addComponent } = useContext(ComponentContext)
  const { addToast } = useToasts()

  const toggle = () => {
    setModal(!modal)
  }

  useEffect(() => {
    setModal(props.isOpen)
  }, [props.isOpen])

  useEffect(() => {
    let options = []
    props.data.map(result => options.push({
      value: result.id,
      label: result.name,
    }))

    setListStatus(options)
  }, [props.data])

  useEffect(() => {
    setIdOrders(props.orders)
  }, [props.orders])

  useEffect(() => {
    return () => {
      console.log('cleaned up')
    }

  }, [])

  const handleOrderStatus = (e) => {
    e.preventDefault()
    let ids = []

    idOrders.map(order => ids.push(parseInt(order.id)))

    const order_status = orderStatus ? orderStatus.value : 'OPEN'
    const data = {
      order_status,
      orders: ids,
    }

    api.put('order/order-status/update/', data).then(response => {
      addToast('Saved Successfully',
        { appearance: 'success', autoDismiss: true })
      toggle()
      addComponent({ statusChanged: true })
      if(props?.onComplete) {
        props?.onComplete();
      }
    }).catch(error => addToast(error.message,
      { appearance: 'error', autoDismiss: true }))
  }
  return (
    <>
      {modal &&
        <Modal isOpen={modal} toggle={toggle} onExit={props.onExit}>
          <Form onSubmit={handleOrderStatus}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
              <span className="text-white h3">Update Order Status</span>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="exampleEmail">Order Status</Label>
                <Select
                  className="basic-single form-control m-0 p-0 border-0"
                  classNamePrefix="select"
                  defaultValue={listStatus[0]}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="order_status"
                  options={listStatus}
                  onChange={(e) => setOrderStatus(e)}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>Cancel</Button>
              <Button color="primary" type="submit">Change</Button>
            </ModalFooter>
          </Form>
        </Modal>}
    </>
  )
}

export const CancelOrderModal = props => {
  const [modal, setModal] = useState(props.isOpen)
  const [Order, setOrder] = useState(props.order)
  const [editable, setEditable] = useState(false)
  const [value, setValue] = useState()
  const [msg, setMsg] = useState('')
  const [refund, setRefund] = useState(false)
  const [remaining, setRemaining] = useState(props.order.total)

  const { addToast } = useToasts()

  const handleValue = e => {
    const url = ``
    if (e.target.value > Order.total) {
      // setInputStyle({ borderColor: 'transparent'})
    }

    {
      (e.target.value) &&

      setValue(parseInt(e.target.value))
      setRemaining(Order.total - e.target.value)
      setEditable(!editable)
      const data = {
        value: e.target.value,
        order: Order,
      }
      // api.put(url, data)
      //     .then(response => {
      //         addToast('Saved Successfully', {appearance: 'success', autoDismiss: true});
      //     }).catch(error => addToast(error.message, {appearance: 'error'}));
    }
  }

  const toggle = () => {
    setModal(!modal)
  }

  useEffect(() => {
    setModal(props.isOpen)
  }, [props.isOpen])

  useEffect(() => {
    let options = props.order
    setOrder(options)
  }, [props.order])

  useEffect(() => {
    setRefund(false)
    setRemaining(props.order.total)
    setValue(0)
    setMsg()

  }, [props])

  const handleCancel = (e) => {
    setMsg('Are you sure you want to cancel the order ?')
    setRefund(false)
  }

  const handleRefund = (e) => {
    setRefund(true)
    setMsg()
  }
  const { addComponent } = useContext(ComponentContext)
  const handleCancelOrder = (e) => {
    const total = parseInt(Order.total * 100)
    const orderId = Order.id
    api.put('order/cancel/', {
      order: orderId,
      amount: total,
    }).then(response => response.data).then(response => {
      if (response.success) {
        addToast('Order has been canceled',
          { appearance: 'success', autoDismiss: true })
        setModal(false)
        addComponent({ order: orderId })
      } else {
        addToast(response.message,
          { appearance: 'error', autoDismiss: false })
        setModal(false)
      }
    }).catch(err => {
      console.log(err)
      addToast('Order cannot be cancelled!',
        { appearance: 'error', autoDismiss: false })
      setModal(false)
    })
  }

  const handleMsgDeletion = (e) => {
    setMsg()
  }

  return (
    <>
      {modal &&
        <Modal isOpen={modal} toggle={toggle} dialogClassName="modal-90w"
               onExit={props.onExit} size="lg"

               aria-labelledby="example-custom-modal-styling-title"
               centered
        >
          <Form>
            <ModalHeader id="example-custom-modal-styling-title" toggle={toggle}
                         className="bg-primary text-white">
              <span
                className="text-white h3">Cancel/Refund order: {Order.shopify_id}</span>
            </ModalHeader>
            <ModalBody className="show-grid">
              <h4>Products:</h4>

              {Order.items.map(product => (
                <div key={product.id + '_prod_' + Order.id}>
                  {!product.hidden_refunded &&
                    <div key={product.id + '_prod_' + Order.id}
                         className="row my-2 d-flex align-items-center"
                    >
                      <div className="col-2 pr-0">
                        {(product.image && product.show) ?
                          <img src={product.image}
                               className="img-thumbnail rounded-0"
                               width="50%"/>
                          : <img
                            src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                            className="img-thumbnail rounded-0"
                            width="50%"/>
                        }
                      </div>
                      <div className="col-10">
                        {product.show ?
                          <a href={window.env.APP_URL + 'product/' +
                            product.product_id}>
                            <span className="font-weight-bolder">{product.quantity}x</span>
                            <span className="text-wrap"> {product.name}</span>
                            <span className="text-right"
                                  style={{
                                    paddingLeft: '10px',
                                    color: 'black',
                                  }}>$ {product.price}</span>
                          </a> :
                          <>
                            <span className="font-weight-bolder">{product.quantity}x</span>
                            <span className="text-wrap"> OTHER PRODUCT</span>
                          </>
                        }
                      </div>
                    </div>}
                </div>
              ))}

              <div className="align-content-center"
                   style={{ paddingTop: '2rem' }}>
                <h4>Order Details:</h4>
                <div className="summary">
                  <dl className="subtotal">
                    <dt>Subtotal</dt>
                    <dd>${Order.subtotal}</dd>
                    <dt style={{ paddingTop: '0.5rem' }}>Shipping</dt>
                    <dd>${Order.shipping}</dd>
                    <dt style={{ paddingTop: '0.5rem' }}>Tax</dt>
                    <dd>${Math.round(Order.taxes * 100) / 100}</dd>
                    <hr style={{ marginTop: '6rem', marginBottom: '10px' }}/>
                    <dt>Total</dt>
                    <dd>${Order.total}</dd>
                  </dl>
                </div>
                <Container>
                  <Row style={{ textAlign: 'center' }}>
                    <Col>
                      <Button color="primary" onClick={handleCancel}>Cancel
                        Order</Button>
                      {/*<Button color="primary" onClick={handleRefund}>Refund</Button>*/}
                    </Col>
                  </Row>
                  {msg &&
                    <>
                      <Row style={{ paddingTop: '2rem', marginBottom: '1rem' }}>
                        <Col style={{ textAlign: 'center' }}>
                          <h4>{msg}</h4>
                        </Col>
                      </Row>
                      <Row style={{ textAlign: 'center' }}>
                        <Col>
                          <Button color="secondary"
                                  onClick={(e) => setMsg()}>No</Button>
                          <Button color="success"
                                  onClick={handleCancelOrder}>Yes</Button>
                        </Col>
                      </Row>
                    </>
                  }

                  {refund &&
                    <>
                      <div className="summary">
                        <dl className="subtotal"
                            style={{ borderTop: '0px !important' }}>
                          <dt>Total</dt>
                          <dd>${Order.total}</dd>
                          <dt style={{ paddingBottom: '1.5rem' }}>Refund
                            Amount
                          </dt>
                          <dd>
                            <Input type="text" onChange={handleValue}
                                   className={`${value > Order.total
                                     ? 'error'
                                     : 'success'} `}
                                   valid
                                   success
                                   formControlProps={{
                                     fullWidth: true,
                                   }}
                            />
                          </dd>
                          <dt>Remaining</dt>
                          <dd>{isNaN(remaining) ? <></> :
                            <>
                              {remaining && value < Order.total &&
                                <h4>${remaining.toFixed(2)}</h4>}
                            </>
                          }
                          </dd>
                        </dl>
                      </div>
                      <Container fluid>
                        <Row>
                          <Col xs={6} md={4}></Col>
                          <Col xs={6} md={4}>
                            <Button color="success" disabled={value >
                              Order.total}>Refund</Button></Col>
                          <Col xs={6} md={4}></Col>
                        </Row>
                      </Container>
                    </>
                  }
                </Container>
              </div>
            </ModalBody>
          </Form>
        </Modal>}
    </>
  )
}

export const SplitOrderModal = props => {
  const [modal, setModal] = useState(props.isOpen)
  const [splitReason, setSplitReason] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [Order, setOrder] = useState(props.order)
  const [orderDetails, setOrderDetails] = useState(
    {
      order_tax: props.order.taxes,
      order_tax1: 0,
      order_line_item_tax: 0,
      order_shipping_tax: 0,
      order_shipping: props.order.shipping,
      order_shipping1: 0,
      order_subtotal: props.order.subtotal,
      order_subtotal1: 0,
      order_total1: 0,
      order_total: props.order.total,
    },
  )
  const [inputs, setInputs] = useState({})

  const { addComponent } = useContext(ComponentContext)
  const { addToast } = useToasts()

  useEffect(() => {
    let options = props.order
    setOrder(options)
    setInputs({})
    setSplitReason('')
    setOrderDetails({})
  }, [props.order])

  useEffect(() => {
    let tax1 = 0.00
    if (![40, 132, 165, 196].includes(Order.channel_id)) {
      tax1 = parseFloat(orderDetails.order_tax1) + parseFloat(orderDetails.order_line_item_tax)
    } else {
      tax1 = ((orderDetails.order_subtotal1 + orderDetails.order_shipping1) * props.order.tax_rate)
    }
    console.log(orderDetails.order_tax1, orderDetails.order_line_item_tax)

    let tax = parseFloat((props.order.taxes - tax1) > 0 ? props.order.taxes - tax1 : 0)
    let shipping = (props.order.shipping - orderDetails.order_shipping1)
    let total = parseFloat(orderDetails.order_subtotal1) + parseFloat(orderDetails.order_shipping1) + tax1

    setOrderDetails({
      ...orderDetails,
      order_tax: tax ? tax.toFixed(2) : 0.00,
      order_tax1: tax1 ? tax1.toFixed(2) : 0.00,
      order_shipping: shipping.toFixed(2),
      order_total: (Order.total - total) >= 0 ? (Order.total - total).toFixed(2) : 0.00,
      order_total1: total >= 0 ? total : 0.00,
    })
  }, [orderDetails.order_subtotal])

  useEffect(() => {
    let tax1 = parseFloat(orderDetails.order_tax1)
    let shippingTaxOld = orderDetails.order_shipping_tax
    let shippingTax = parseFloat(orderDetails.order_shipping1 * props.order.shipping_tax_rate)
    shippingTax = Math.round((shippingTax + Number.EPSILON) * 100) / 100

    if (![40, 132, 165, 196].includes(Order.channel_id)) {
      tax1 = parseFloat(orderDetails.order_tax1 - shippingTaxOld) + shippingTax
    }

    let tax = parseFloat(props.order.taxes - tax1)
    let shipping = parseFloat(props.order.shipping - orderDetails.order_shipping1)
    let total = parseFloat(orderDetails.order_subtotal1) + parseFloat(orderDetails.order_shipping1) + tax1

    setOrderDetails({
      ...orderDetails,
      order_tax1: tax1 ? tax1.toFixed(2) : 0.00,
      order_tax: tax ? tax.toFixed(2) : 0.00,
      order_shipping_tax: shippingTax,
      order_shipping: shipping.toFixed(2),
      order_total: (Order.total - total) >= 0 ? (Order.total - total).toFixed(2) : 0.00,
      order_total1: total >= 0 ? total : 0.00,
    })
  }, [orderDetails.order_shipping1])

  useEffect(() => {
    setModal(props.isOpen)
  }, [props.isOpen])

  useEffect(() => {
    setInputs({})
    {
      (Order.items) &&
      Order.items?.map(product => {
        setInputs(prevState =>
          (
            {
              ...prevState,
              [`${product.id}`]: product.quantity,
              [`${product.id}-1`]: 0,
            }
          ),
        )
      })
      setOrderDetails({
        order_tax: props.order.taxes,
        order_tax1: 0,
        order_line_item_tax: 0,
        order_shipping_tax: 0,
        order_shipping: Order.shipping,
        order_shipping1: 0,
        order_total: props.order.total,
        order_total1: 0,
        order_subtotal: props.order.subtotal,
        order_subtotal1: 0,
      })
    }
  }, [Order])

  const handleSplit = (e, product) => {
    if (e.target.value <= product.quantity) {
      let remove = inputs[e.target.name]
      let remove1 = inputs[product.id]
      let value = parseInt(e.target.value)

      if (isNaN(parseInt(e.target.value))) {
        value = 0
      }

      setInputs(prevState => ({ ...prevState, [e.target.name]: value }))
      if (e.target.name.endsWith('-1')) {
        setInputs(prevState => ({
          ...prevState,
          [`${product.id}`]: product.quantity - value,
        }))
        let subtotal1 = (orderDetails.order_subtotal1 + product.price * (value - remove))
        let subtotal = (orderDetails.order_subtotal + product.price * (product.quantity - value - remove1))

        // Taxes Calculation
        let lineItemTax = 0.00
        if (![40, 132, 165, 196].includes(Order.channel_id)) {
          if ((value - remove) > 0) {
            if (product.tax_rate > 0) {
              lineItemTax = parseFloat((product.price * (value - remove)) * product.tax_rate)
              lineItemTax =  Math.floor((lineItemTax + Number.EPSILON) * 100) / 100
            }
          } else if ((value - remove) < 0) {
            if (product.tax_rate > 0) {
              lineItemTax = parseFloat((product.price * ((value - remove) * -1)) * product.tax_rate)
              lineItemTax = lineItemTax * -1
              lineItemTax =  Math.floor((lineItemTax + Number.EPSILON) * -100) / -100
            }
          }
        }

        setOrderDetails({
          ...orderDetails,
          order_subtotal1: subtotal1,
          order_subtotal: subtotal,
          order_line_item_tax: lineItemTax,
        })
      }
    }
  }

  const toggle = () => {
    setModal(!modal)
  }

  const handleExit = () => {
    props.onExit()
  }

  const handleSplitOrder = (e) => {
    const orderId = Order.id
    let line_items = []
    line_items = Object.entries(inputs).map(([key, value]) => {
      if (key.endsWith('-1')) {
        return {
          'id': `${key.slice(0, -2)}`,
          'quantity': `${value}`,
        }
      }
    })
    setLoading(true)
    api.post('order/split/', {
      order: orderId,
      line_items: line_items.filter(x => x != null),
      shipping: orderDetails.order_shipping1,
      taxes: orderDetails.order_tax1,
      split_reason: splitReason,

    }).then(response => response.data).then(response => {
      setLoading(false)

      if (response.success) {
        addToast('Order has been split!',
          { appearance: 'success', autoDismiss: true })
        setModal(false)
        addComponent({ order: orderId })
      } else {
        addToast(response.message,
          { appearance: 'error', autoDismiss: false })
        setModal(false)
      }
    }).catch(err => {
      console.log(err)
      addToast('Order cannot be split!',
        { appearance: 'error', autoDismiss: false })
      setModal(false)
    })
  }

  const handleMsgDeletion = (e) => {
    setSplitReason()
  }

  const handleShipping1 = (e) => {
    {
      (parseFloat(e.target.value) <= parseFloat(props.order.shipping)) &&
      setOrderDetails({
        ...orderDetails,
        order_shipping1: parseFloat(e.target.value || 0),
      })
    }
  }

  return (
    <>
      {modal &&
        <Modal isOpen={modal} toggle={toggle} dialogClassName="modal-90w"
               onExit={handleExit} size="lg"

               aria-labelledby="example-custom-modal-styling-title"
               centered
        >
          <Form>
            <ModalHeader id="example-custom-modal-styling-title" toggle={toggle}
                         className="bg-primary text-white">
              <span
                className="text-white h3"> Split order: {Order.shopify_id}</span>
            </ModalHeader>
            <ModalBody className="show-grid">
              <Table className="align-items-center table-flush split-order"
                     cellSpacing={0}
                     responsive>
                <thead className="thead-light">
                <tr>

                  <th scope="col" className="pl-5 pr-5"
                      style={{ textAlign: 'left' }}>Order Items
                  </th>
                  <th scope="col" className="pl-3 pr-3">Original Order</th>
                  <th scope="col" className="pl-3 pr-3">New Order</th>
                </tr>
                </thead>
                <tbody>
                {Order.items?.map(product => (
                  <tr>
                    <th scope="row-8">
                      <div key={product.id + '_prod_' + Order.id}>
                        {!product.hidden_refunded &&
                          <div key={product.id + '_prod_' + Order.id}
                               className="row my-2 d-flex align-items-center"
                          >
                            <div className="col-2 pr-0">
                              {(product.image && product.show) ?
                                <img src={product.image}
                                     className="img-thumbnail rounded-0"
                                     width="100%"/>
                                : <img
                                  src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                                  className="img-thumbnail rounded-0"
                                  width="100%"/>
                              }
                            </div>
                            <div className="col-10">
                              {product.show ?
                                <>
                                  <a href={window.env.APP_URL + 'product/' +
                                    product.product_id}>
                                    <span className="font-weight-bolder">{product.quantity}x</span>
                                    <span className="text-wrap"> {product.name}</span>
                                    <span className="text-right"
                                          style={{
                                            paddingLeft: '10px',
                                            color: 'black',
                                          }}>$ {product.price}</span>
                                  </a>
                                  <div>
                                    <span style={{ fontWeight: 'lighter' }}>Sku: {product.sku}</span>
                                  </div>
                                </>

                                :
                                <>
                                  <span className="font-weight-bolder">{product.quantity}x</span>
                                  <span className="text-wrap"> OTHER PRODUCT</span>
                                </>
                              }
                            </div>

                          </div>
                        }
                      </div>
                    </th>
                    <td className={'text-center'}>{inputs[`${product.id}`]}</td>
                    <td>
                      <Input type="number"
                             name={`${product.id}-1`}
                             onChange={(e) => handleSplit(e, product)}
                             onWheel={(e) => e.target.blur()}
                             min={0}
                             value={inputs[`${product.id}-1`]}
                             max={product.quantity}
                             valid={inputs[`${product.id}-1`] <=
                               product.quantity}
                             invalid={inputs[`${product.id}-1`] >
                               product.quantity}
                             formControlProps={{
                               fullWidth: true,
                             }}
                      />
                    </td>
                  </tr>
                ))}

                <tr className={'split-details'}>
                  <td className={'order-title-fees'}>Shipping Paid</td>
                  <td className={'order-values'}>
                    <Input type="number"
                           name={'shipping-fee'}
                      // step={0.1}
                      // onWheel={(e) => e.target.blur()}
                           value={orderDetails.order_shipping}
                      // style={{textAlign: "center"}}
                      // onChange={handleShipping}
                           readOnly

                    />
                  </td>
                  <td>
                    <Input type="number"
                           name={'shipping-fee-1'}
                           min={0}
                           max={props.order.shipping}
                           step={0.1}
                           onWheel={(e) => e.target.blur()}
                           value={orderDetails.order_shipping1 || 0}
                           onChange={handleShipping1}
                    />
                  </td>
                </tr>

                <tr className={'split-details'}>
                  <td className={'order-title-fees'}>Tax Paid</td>
                  <td className={'order-values'}>${orderDetails.order_tax}</td>
                  <td>
                    <Input type="number"
                           value={orderDetails?.order_tax1}
                           readOnly
                    />
                  </td>
                </tr>

                <tr className={'split-details'}>
                  <td className={'order-title-fees'}>Order Total</td>
                  <td className={'order-values'}>${parseFloat(
                    orderDetails.order_total)?.toFixed(2)}</td>
                  <td>
                    <Input type="number"
                           value={orderDetails.order_total1?.toFixed(2)}
                           readOnly
                    />
                  </td>
                </tr>
                </tbody>
              </Table>

              <div>
                <div className="align-content-center"
                     style={{ paddingTop: '2rem' }}>
                  <div style={{ marginBottom: '2rem', marginLeft: '1rem' }}>
                    <div className="form-group form-inline">
                      <label style={{ marginRight: '10px' }}>Split
                        Reason:</label>
                      <Input
                        type="text"
                        style={{ width: '75%' }}
                        value={splitReason}
                        onChange={(e) => setSplitReason(e.target.value)}
                      >
                      </Input>
                    </div>
                  </div>
                  <Container>
                    <Row style={{ textAlign: 'center' }}>
                      <Col>
                        <Button color="primary" onClick={toggle}>Cancel</Button>
                        <Button color="primary"
                                disabled={(orderDetails.order_subtotal1 == 0) ||
                                  isLoading}
                                onClick={handleSplitOrder}>
                          Confirm {isLoading &&
                          <Spinner size="sm" color="light"/>}
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>

            </ModalBody>
          </Form>
        </Modal>}
    </>
  )
}

export const Age = ({ data, children }) => {
  let class_element = 'text-danger'
  if (data.day === 0) {
    class_element = 'text-success'
  } else if (data.day < 2) {
    class_element = 'text-yellow'
  }

  return <span className={class_element}>{children}</span>

}

export const SKUMapppingModal = (props) => {
  const [modal, setModal] = useState(props.isOpen)
  const [productRetailerSku, setProductRetailerSku] = useState([])
  const [productRetailerSkuOptions, setProductRetailerSkuOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState(null)
  const { addToast } = useToasts()
  const { name, sku, orderAssignee } = props.variant

  useEffect(() => {
    // API to fetch the list of retailer sku and associated product details
    if (props.variant.orderAssignee) {
      const params = new URLSearchParams([
        ['assignee', props.variant.orderAssignee],
      ])

      api.get('/sku-mapping/product-retailer-sku/', { params: params }).
        then((response) => {
          if (response.status === 200) {
            const { results, statusCode } = response.data
            let options = []

            if (statusCode == 200 && results && results.length > 0) {
              options = results.map((item) => ({
                label: item.label,
                value: item.id,
              }))
            }
            setProductRetailerSkuOptions(options)
            setProductRetailerSku(results)
          }
        }).
        catch((error) => console.log(error))
    }
  }, [props.variant])

  useEffect(() => {
    setModal(props.isOpen)
  }, [props.isOpen])

  const toggle = () => {
    setModal(!modal)
  }

  /**
   * To handle the change of dropdown selected value
   * @param {*} value - selected label & value
   */
  const handleOnChange = (selectedValue) => {
    const value = selectedValue && selectedValue?.value
    setSelectedValue(value)
  }

  /**
   * To handle the form submit - create the mapping
   * @param {*} e event
   */
  const handleSubmit = (e) => {
    e.preventDefault()

    if (!selectedValue) {
      return addToast('Please select the value for Retailer SKU.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    const retailerSkuData = productRetailerSku.find(
      (item) => item.id === selectedValue,
    )
    const requestBody = {
      ...retailerSkuData,
      sku: sku,
      inventory: orderAssignee,
    }

    // API to save the sku mapping
    api.patch('/sku-mapping/barcart-retailer-sku/', requestBody).
      then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.success
        ) {
          addToast(response.data.message, {
            appearance: 'success',
            autoDismiss: true,
          })
          props.updateSKUMappingStatus(
            orderAssignee,
            sku,
            retailerSkuData?.quantity,
          )
        } else {
          addToast('Please try again after sometime!', {
            appearance: 'error',
            autoDismiss: true,
          })
        }
        setModal(false)
      }).
      catch((error) => {
        addToast('Error occurred while creating the sku mapping.', {
          appearance: 'error',
          autoDismiss: true,
        })
        setModal(false)
      })
  }

  return (
    <>
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          dialogclassname="modal-90w"
          onExit={props.onExit}
          size="lg"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Form>
            {/* Modal Header */}
            <ModalHeader
              id="example-custom-modal-styling-title"
              toggle={toggle}
              className="bg-primary text-white"
            >
                <span className="text-white h3">
                  SKU Mapping: {name}{' '}
                </span>
            </ModalHeader>
            {/* Modal Body */}
            <ModalBody className="show-grid">
              <Row>
                <Col>
                  <Label>Barcart SKU *</Label>
                </Col>
                <Col>
                  <Input disabled={true} value={sku}/>
                </Col>{' '}
              </Row>
              <Row>
                <Col>
                  <Label>Retailer SKU *</Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Select
                    className="basic-single form-control m-0 p-0 border-0"
                    classNamePrefix="select"
                    placeholder="Select Retailer Sku..."
                    isDisabled={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="retailer-sku"
                    options={productRetailerSkuOptions}
                    onChange={(value) => handleOnChange(value)}
                  />
                </Col>
              </Row>
            </ModalBody>
            {/* Modal Footer */}
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </>
  )
}


export const LineItem = ({ orderId, user, cursor, assignee, handleOpenSKUMappingModal }) => {
  //Component that renders al product or lineitem wise data on order listing page
  const [lineitems, setLineitems] = useState([]);
  useEffect(() => {
      if (orderId){
        api.get(`/order/line-item-details/?order_id=${orderId}`)
            .then(response => {
                setLineitems(response.data);
            })
            .catch(error => console.log(error));
      }
  }, []);
  return lineitems.map(product => (
      <div key={product.id + "_prod_" + orderId}>
          {!product.hidden_refunded && (
              <div key={product.id + "_prod_" + orderId} className="row my-2 d-flex align-items-center" style={{ width: "20rem" }}>
                  <div className="col-2 pr-0">
                      {product.image && product.show ? (
                          <img src={product.image} className="img-thumbnail rounded-0" width="100%" />
                      ) : (
                          <img
                              src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                              className="img-thumbnail rounded-0"
                              width="100%"
                          />
                      )}
                  </div>
                  <div className="col-10">
                      {product.show ? (
                          <>
                              <a href={window.env.APP_URL + "product/" + product.product_id}>
                                  <span className="font-weight-bolder">{product.quantity}x</span>
                                  <span className="text-wrap"> {product.name}</span>
                              </a>
                              <div>
                                  {product.mapped_sku?.status ? (
                                      <>
                                          <span
                                              className="text-wrap"
                                              style={{
                                                  color: "green",
                                                  fontWeight: "lighter"
                                              }}>
                                              SKU: {product.sku}
                                          </span>
                                          <div>
                                              <span
                                                  className="text-wrap"
                                                  style={{
                                                      color: "grey",
                                                      fontWeight: "lighter"
                                                  }}>
                                                  Quantity: {product.mapped_sku?.quantity}
                                              </span>
                                          </div>
                                      </>
                                  ) : (
                                      <a
                                          className="text-wrap"
                                          id={`tooltip_sku_${orderId}_${product.id}`}
                                          onClick={
                                              user.isAdmin || user.isRetailer
                                                  ? () => {
                                                        assignee != "-" && handleOpenSKUMappingModal(product.sku, product.name, assignee);
                                                    }
                                                  : undefined
                                          }
                                          style={{
                                              color: "red",
                                              fontWeight: "lighter",
                                              cursor: cursor
                                          }}>
                                          SKU: {product.sku}
                                          <UncontrolledTooltip delay={0} placement="bottom" target={`tooltip_sku_${orderId}_${product.id}`}>
                                              Retailer SKU not linked
                                          </UncontrolledTooltip>
                                      </a>
                                  )}
                              </div>
                          </>
                      ) : (
                          <>
                              <span className="font-weight-bolder">{product.quantity}x</span>
                              <span className="text-wrap"> OTHER PRODUCT</span>
                          </>
                      )}
                  </div>
              </div>
          )}
      </div>
  ));
};
