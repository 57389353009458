import React,{useState} from "react";
import styles from "./orderDetail.module.css";
import { Tooltip } from "reactstrap";
import { BsFillQuestionCircleFill } from "react-icons/bs";

const Notes = ({orderDetails}) => {
    const { order_note_info } = orderDetails?.order;  
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const junkValues = ["", " ", "-"];
    const isValidOrderCount = order_note_info && !junkValues.includes(order_note_info);
    return (
        <div className={styles.cardConatiner}>
            <div>
                <h3>
                    Note
                    <BsFillQuestionCircleFill color="#3c71ff" id="noteTooltip" style={{ marginLeft: "5px" }} />
                </h3>
                <Tooltip target="noteTooltip" isOpen={tooltipOpen} toggle={toggle} placement="right">
                    If you need to add a Gift Note at the customer's request, see here
                </Tooltip>
            </div>
            <hr />
            <div>
                <span>{isValidOrderCount?order_note_info:"-"}</span>
            </div>
        </div>
    );
};

export default Notes;
