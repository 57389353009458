import React, { useContext } from "react";
import styles from "./orderDetail.module.css";
import { AuthContext } from "contexts/AuthContext";

const CustomerInformation = ({ orderDetails }) => {
    const { user } = useContext(AuthContext);
    const { order_customer_info } = orderDetails?.order
    return (
        <div className={styles.cardConatiner}>
            <div>
                <h3>Customer Information</h3>
            </div>
            <hr />
            {user && (user.isAdmin || user.isRetailer || order_customer_info?.order_source !== "Mash&Grape") ? <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                <p style={{ fontWeight: "bold" }}> {order_customer_info?.billing_name} </p>
                {!user.isRetailer ? <p style={{ fontWeight: "bold" }}>{order_customer_info?.email}</p> : <p>order@getbarcart.com</p>}
            </div> : <h3>CONFIDENTIAL</h3>}
        </div>
    );
};

export default CustomerInformation;
