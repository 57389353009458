import React, { useContext, useEffect, useRef, useState } from 'react';
import { PayoutStatus } from 'components/Order';

import Moment from 'react-moment';

import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    Tooltip,
    ButtonDropdown,
    PopoverBody,
    PopoverHeader,
    UncontrolledPopover,
    UncontrolledTooltip,
    Form,
    Spinner,     
} from 'reactstrap';

import { api } from 'services/api';
import { AuthContext } from 'contexts/AuthContext';

import Filter from '../../components/Filters/Filter';
import BadgePillFilter from 'components/Filters/Badge';
import DateRange from '../../components/Filters/DateRange';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { useToasts } from 'react-toast-notifications';
import '../../assets/css/order.css';
import PayoutStatusModal from '../../components/Modal/PayoutStatusModal';
import { ComponentContext } from '../../contexts/ComponentContext';
import parse from "html-react-parser";
import BulkNotes from 'components/Modal/bulkNotes';
import PagePagination from 'feature/pagination/Pagination';
import useMobileDetection from 'customHooks/useMobileDetection';
import { mobileScreenThresholdValue } from 'utils/constants';
import { Sort, handleSort, getParams } from "../../utils/commonFunctions";
import "../../assets/css/order.css";
import { addURLParam, processURLSearchParams, removeAllURLParams, removeURLParam } from "../../utils/urls";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { prefixAndFilterMapping } from '../../customHooks/useFilter';
import "../../utils/commonStyles.css";

const RetailerBilling = (props) => {
    const [orders, setOrders] = useState([]);
    const [notesLoading, setNotesLoading] = useState(false);
    const [totalOrders, setTotalOrders] = useState(0);
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return []
        }
    });

    const [sort, setSort] = useState("-id");
    const [sortOrder, setSortOrder] = useState({order: "", columnName: ""});
    const [isLoading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [bulkModal, setBulkModal] = useState(false);
    const [status, setStatus] = useState([])
    const [selected, setSelected] = useState([])
    const [params, setParams] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [clearall, setClearAll] = useState(false)

    const { component } = useContext(ComponentContext);

    const [filter, setFilter] = useState({

        startDate: null,
        endDate: null,
        search: null,
        payoutStatus: [],
        tracking_statuses: [],
        assignees: [],
        brands: [],
        products: [],
        orderStartDate: null,
        orderEndDate: null,
        orderCity: [],
        orderState: [],
        orderZipCodes: [],
        financial_status:[],
        order_status:[]
    })
    const [exportDropdownOpen, setExportDropdownOpen] = useState(false);

    // Monday Task : 6530680655 - Payouts(Billing) Page URL Params
    const cancelToken = React.useRef(undefined);

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    const refOrdersCheckbox = useRef(false)
    const handleOrdersCheckbox = (e) => {

        refOrdersCheckbox.current = e.target.checked
        let checkboxes = document.querySelectorAll('.orderCheckbox');

        let items = [];
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = e.target.checked;
            if (e.target.checked === true) {
                items.push({
                    id: parseInt(checkboxes[i].value),
                    title: checkboxes[i].name,
                    status: checkboxes[i].title,
                    assignee: checkboxes[i].dataset.assignee,
                })
            }
        }
        setSelected(items)

    }

    const handleUnselect = () => {
        let checkboxes = document.querySelectorAll('.orderCheckbox');
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false
        }
        setSelected([])
    }

    const {addToast} = useToasts();
    const [exportButton, setExportButton] = useState({
        label: "Export",
        disabled: false,
    })
    const [payoutStatus, setPayoutstatus] = useState([
        {
            "id": "paid",
            "name": "Paid",
        },

       {
            "id": "unpaid",
            "name": "Unpaid",
        },

       {
            "id": "pending",
            "name": "Pending",
        },

        {
            "id": "void",
            "name": "Void",
        }
    ])
    const handleExportOrders = () => {
        setExportButton({
            label: "Exporting...",
            disabled: true,
        })

        let new_params = params

        new_params.order_ids = []

        selected.map(item =>  new_params.order_ids.push(item.id))

        api.get('payout/export/', {
            params: new_params
        })
            .then(response => response.data)
            .then(response => {
                const success = response?.success | false;

                if (success) {
                    addToast(response.message,
                        {appearance: 'success', autoDismiss: false})
                } else {
                    const timestamp = moment().format('MMMM-Do-YYYY-h:mm:ss-a');
                    const filename = 'payout_' + timestamp + '.csv'
                    fileDownload(response, filename)
                }

                setExportButton({
                    label: "Export",
                    disabled: false,
                })
            })
        // document.href = window.env.APP_URL + str
    }

    const handleExportOrdersLineItems = () => {
        setExportButton({
            label: "Exporting...",
            disabled: true,
        });
        let new_params = params;
        new_params.order_ids = [];
    
        selected.map(item =>  new_params.order_ids.push(item.id));
    
        api.get('payout/lineitem/export/', {
            params: new_params
        })
            .then(response => response.data)
            .then(response => {
                const success = response?.success | false;
                if (success) {
                    addToast(response.message, {appearance: 'success', autoDismiss: false});
                } else {
                    const timestamp = moment().format('MMMM-Do-YYYY-h:mm:ss-a');
                    const filename = 'payout_' + timestamp + '.csv';
                    fileDownload(response, filename);
                }
    
                setExportButton({
                    label: "Export",
                    disabled: false,
                });
            })
    }

    const exitBulkNotesModal = () => {
        setBulkModal(false);
    };

    const handleCheckbox = (e) => {
        let checkbox = [...new Set(selected)]
        const orderRecord = orders.find(order => order.id == e.target.value);

        if (e.target.checked) {
            checkbox.push({
                id: e.target.value,
                title: e.target.name,
                status: e.target.title,
                assignee: e.target.dataset.assignee,
                note: orderRecord.payouts_note
            })
        } else {
            // !== strict inequality operator checking the order id available or not, so that's why we use != inequality operator
            checkbox = checkbox.filter(item => item.id !== e.target.value)
        }

        setSelected(checkbox)

    }
    // Monday Task : 6530680655 - Used getParams function from "utils/commonFunctions.js" file
    // const getParams = () => {
    //     let new_params = {
    //         page,
    //         ordering: sort
    //     }
    //     if (filter.search?.length > 0) {
    //         new_params.q = filter.search
    //         setClearAll(true)
    //     }

    //     if (filter.startDate && filter.endDate) {
    //         new_params.date_start = filter.startDate.format("Y-M-D")
    //         new_params.date_end = filter.endDate.format("Y-M-D")
    //         setClearAll(true)
    //     }

    //     if (filter?.orderStartDate && filter?.orderEndDate) {
    //         new_params.order_date_start = filter.orderStartDate.format("YYYY-MM-DD");
    //         new_params.order_date_end = filter.orderEndDate.format("YYYY-MM-DD");
    //     }

    //     if (filter?.tracking_statuses.length > 0) {
    //         new_params.tracking_status = []
    //         filter.tracking_statuses.map(item => new_params.tracking_status.push(item.value))
    //         setClearAll(true)
    //     }
    //     if (filter?.financial_status.length > 0) {
    //         new_params.financial_status = []
    //         filter.financial_status.map(item => new_params.financial_status.push(item.value))
    //         setClearAll(true)
    //     }
    //     if (filter?.order_status.length > 0) {
    //         new_params.order_status = []
    //         filter.order_status.map(item => new_params.order_status.push(item.value))
    //         setClearAll(true)
    //     }

    //     if (filter?.payoutStatus.length > 0) {
    //         new_params.payout_status = []
    //         filter.payoutStatus.map(item => new_params.payout_status.push(item.value))
    //         setClearAll(true)
    //     }

    //     if (filter?.assignees.length > 0) {
    //         new_params.assignee = []
    //         filter.assignees.map(item => new_params.assignee.push(item.value))
    //         setClearAll(true)
    //     }

    //     if (filter?.brands.length > 0) {
    //         new_params.brand = []
    //         filter.brands.map(item => new_params.brand.push(item.value));
    //         setClearAll(true);
    //     }

    //     if (filter?.products.length > 0) {
    //         new_params.product = []
    //         filter.products.map(item => new_params.product.push(item.value));
    //         setClearAll(true);
    //     }

    //     if (filter?.orderCity.length > 0) {
    //         new_params.order_cities = []
    //         filter.orderCity.map(item => new_params.order_cities.push(item.value))
    //         setClearAll(true)
    //     }

    //     if (filter?.order_states.length > 0) {
    //         new_params.order_states = []
    //         filter.order_states.map(item => new_params.order_states.push(item.value))
    //         setClearAll(true)
    //     }

    //     if (filter?.order_zip_codes.length > 0) {
    //         new_params.order_zip_codes = []
    //         filter.order_zip_codes.map(item => new_params.order_zip_codes.push(item.value))
    //         setClearAll(true)
    //     }

    //     setParams(new_params)
    //     return new_params
    // }

    useEffect(() => {

        api.get('/order/order-status/')
            .then(response => {
                setStatus(response.data.results)
            })
            .catch(error => console.log(error))
    }, [])

    useEffect(() => {
        setLoading(true);

        let new_params = getParams(filter, setClearAll);

        if (typeof cancelToken.current != undefined) {
            cancelToken.current !== undefined && cancelToken.current.cancel("Operation canceled due to new request.");
          }
        cancelToken.current = axios.CancelToken.source();

        new_params = {
            ...new_params,
            page,
            ordering: sort,
        };

        api.get('/order/closed/', {
            params: new_params,
            cancelToken: cancelToken.current.token
        })
            .then(response => {
                const list = response.data.results.map(order => {
                    const s = selected.filter(item => parseInt(item.id) === order.id)
                    order.checked = s.length > 0;
                    return order
                })
                setOrders(list);
                setTotalOrders(response.data.count);

                const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                const previous = (page !== 1) ? page - 1 : 1
                const next = (page !== totalPages) ? page + 1 : totalPages
                setPagination({
                    previous,
                    next,
                    pages: () => {
                        let startCount = 1;
                        let endCount = isMobile ? 4 : 12;
                        let numberCount = Math.round(endCount / 2)
                        const pageList = [];
                        if (numberCount < 0)numberCount = 1;
                        startCount = page - numberCount;
                        if (startCount <= 0) startCount = 1;
                        if (page !== 1) endCount = page + numberCount;
                        if (endCount > totalPages) endCount = totalPages;
                        if (totalPages >= endCount) {
                            for (let i = startCount; i <= endCount; i++) {
                                pageList.push(i)
                            }
                        }else if (totalPages >= 1) {
                            for (let i = 1; i <= totalPages; i++) {
                                pageList.push(i)
                            }
                        }
                        return pageList;
                    }
                })
                setLoading(false);
                delete new_params['page']
                delete new_params['ordering']
                setParams(new_params);
            })
            .catch(err => {
                console.log(err);
            })
    }, [page, sort, filter, refresh, component])


    const handlePagination = page => {
        setPage(page)
    }

    const {user, isAuthentication} = useContext(AuthContext)

    let timer = null;
    const handleSearch = (e) => {
        setPage(1);
        clearTimeout(timer);
        timer = setTimeout(function () {
            setFilter({
                ...filter,
                search: e.target.value
            })
            addURLParam(window, "search", e.target.value);
        }, 750);
        
    }
    const removeBadge = (e) => {
        setPage(1);
        if (e.component === 'search') {
            setFilter({...filter, search: null})
            removeURLParam(window, "search");
            document.getElementById("payoutsPageSearch").value = "";
        } else if (e.component === 'tracking_statuses') {
            let items = filter.tracking_statuses
            items = items.filter(item => item.value !== e.item.value)
            setFilter({...filter, tracking_statuses: items})
            removeURLParam(window, "tracking_statuses", e.item.value);
        } else if (e.component === 'date') {
            setFilter({...filter, startDate: null, endDate: null});
            removeURLParam(window, "startDate");
            removeURLParam(window, "endDate");
        } else if (e.component === 'orderDate') {
            setFilter({ ...filter, orderStartDate: null, orderEndDate: null });
            removeURLParam(window, "orderStartDate");
            removeURLParam(window, "orderEndDate");
        } else if (e.component === 'payoutStatus') {
            let items = filter.payoutStatus
            items = items.filter(item => item.value !== e.item.value)
            setFilter({...filter, payoutStatus: items});
            removeURLParam(window, "payoutStatus", e.item.value);
        } else if (e.component === 'assignee') {
            let items = filter.assignees
            items = items.filter(item => item.value !== e.item.value)
            setFilter({...filter, assignees: items});
            removeURLParam(window, "assignees", e.item.value);
        } else if (e.component === 'financial_status') {
            let items = filter.financial_status
            items = items.filter(item => item.value !== e.item.value)
            setFilter({...filter, financial_status: items});
            removeURLParam(window, "financial_status", e.item.value);
        } else if (e.component === 'order_status') {
            let items = filter.order_status
            items = items.filter(item => item.value !== e.item.value)
            setFilter({...filter, order_status: items});
            removeURLParam(window, "order_status", e.item.value);
        } else if (e.component === 'brand') {
            let items = filter.brands
            items = items.filter(item => item.value !== e.item.value);
            setFilter({...filter, brands: items});
            removeURLParam(window, "brands", e.item.value);
        } else if (e.component === 'product') {
            let items = filter.products
            items = items.filter(item => item.value !== e.item.value);
            setFilter({...filter, products: items});
            removeURLParam(window, "products", e.item.value);
        } else if (e.component === 'orderCity') {
            let items = filter.orderCity
            items = items.filter(item => item.value !== e.item.value);
            setFilter({...filter, orderCity: items});
            removeURLParam(window, "orderCity", e.item.value);
        } else if (e.component === 'orderState') {
            let items = filter.orderState
            items = items.filter(item => item.value !== e.item.value);
            setFilter({...filter, orderState: items});
            removeURLParam(window, "orderState", e.item.value);
        } else if (e.component === 'orderZipCodes') {
            let items = filter.orderZipCodes
            items = items.filter(item => item.value !== e.item.value);
            setFilter({...filter, orderZipCodes: items});
            removeURLParam(window, "orderZipCodes", e.item.value);
        } else if (e.component === 'selected') {
            let items = selected
            items = items.filter(item => item.id !== e.item.id)

            setSelected(items)
            let checkbox = document.getElementById('checkbox_'+e.item.id)
            if (checkbox) {
                checkbox.checked = false
            }
        }
        setClearAll(false)

    }

    const handlePayoutStatus = (e, prefix) => {
        setPage(1);
        if (e == "__all__") {
            let payoutStatus = [];
            let checkbox = document.querySelectorAll(".checkbox_payout_status");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                payoutStatus.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label,
                });
                addURLParam(window, prefixAndFilterMapping[prefix], checkbox[i].value);
            }
            setFilter({ ...filter, payoutStatus: payoutStatus });
        } else {
            let checkbox = [...new Set(filter.payoutStatus)]
            if (e.target.checked) {
                checkbox.push({value: e.target.value, label: e.target.dataset.label});
                addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            } else {
                checkbox = checkbox.filter(item => item.value !== e.target.value)
                removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            }
            setFilter({...filter, payoutStatus: checkbox})
        }
    }

    const handleTrackingStatus = (e, prefix) => {
        setPage(1);
        if (e == "__all__") {
            let trackingStatus = [];
            let checkbox = document.querySelectorAll(".checkbox_tracking_status");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                trackingStatus.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label,
                });
                addURLParam(window, prefixAndFilterMapping[prefix], checkbox[i].value);
            }
            setFilter({ ...filter, tracking_statuses: trackingStatus });
        } else {
            let checkbox = [...new Set(filter.tracking_statuses)]
            if (e.target.checked) {
                checkbox.push({value: e.target.value, label: e.target.dataset.label});
                addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            } else {
                checkbox = checkbox.filter(item => item.value !== e.target.value);
                removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            }
            setFilter({...filter, tracking_statuses: checkbox})
        }
    }
    const handleAssignee = (e, prefix) => {
        setPage(1);
        if (e == "__all__") {
            let assignee = [];

            let checkbox = document.querySelectorAll(".checkbox_assignee");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                assignee.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label,
                });
                addURLParam(window, prefixAndFilterMapping[prefix], checkbox[i].value);
            }
            setFilter({ ...filter, assignees: assignee });
        } else {
            let checkbox = [...new Set(filter.assignees)];
            if (e.target.checked) {
                checkbox.push({ value: e.target.value, label: e.target.dataset.label });
                addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            } else {
                checkbox = checkbox.filter((item) => item.value !== e.target.value);
                removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            }
            setFilter({ ...filter, assignees: checkbox });
        }
    };

    const handleBrand = (e, prefix) => {
        setPage(1);
        if (e === "__all__") {
            let status = [];
      
            let checkbox = document.querySelectorAll(".checkbox_brand");
            for (let i = 0; i < checkbox.length; i++) {
              checkbox[i].checked = true;
              status.push({
                value: checkbox[i].value,
                label: checkbox[i].dataset.label,
              });
            }
            setFilter({ ...filter, brands: status });
      
          } else {
          let checkbox = [...new Set(filter.brands)];
      
          if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
            addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
          } else {
            checkbox = checkbox.filter((item) => item.value !== e.target.value);
            removeURLParam(window, "brands", e.target.value);
          }
      
          setFilter({ ...filter, brands: checkbox });
        }
    }

    const handleFinancialStatus = (e, prefix) => {
        setPage(1);
        let checkbox = [...new Set(filter.financial_status)];

        if (e.target.checked) {
            checkbox.push({value: e.target.value, label: e.target.dataset.label});
            addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
            removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        }

        setFilter({...filter, financial_status: checkbox});
    }

    const handleOrderStatus = (e, prefix) => {
        setPage(1);
        let checkbox = [...new Set(filter.order_status)];

        if (e.target.checked) {
            checkbox.push({value: e.target.value, label: e.target.dataset.label});
            addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
            removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        }

        setFilter({...filter, order_status: checkbox});
    }

    const handleProduct = (e, prefix) => {
        setPage(1);
        let checkbox = [...new Set(filter.products)];

        if (e.target.checked) {
            checkbox.push({value: e.target.value, label: e.target.dataset.label});
            addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
            removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        }

        setFilter({...filter, products: checkbox});
    }
    const handleDateRange = (start, end) => {
        setPage(1);

        setFilter({
            ...filter,
            startDate: start,
            endDate: end,
        })

        addURLParam(window, "startDate", start);
        addURLParam(window, "endDate", end);
    }

    //Function for date filter
    const handleOrderDateRange = (start, end) => {
        setPage(1);
        setFilter({
            ...filter,
            orderStartDate: start,
            orderEndDate: end,
        })

        addURLParam(window, "orderStartDate", start);
        addURLParam(window, "orderEndDate", end);
    }

    const handleRefresh = () => {
        setRefresh(!refresh)
    }

    const handleOrderCities = (e, prefix) => {
        setPage(1);
        let checkbox = [...new Set(filter.orderCity)]

        if (e.target.checked) {
            checkbox.push({value: e.target.value, label: e.target.dataset.label});
            addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
            removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        }

        setFilter({...filter, orderCity: checkbox})
    }

    const handleOrderStates = (e, prefix) => {
        setPage(1);
        if (e == "__all__") {
            let orderStates = [];
            let checkbox = document.querySelectorAll(".checkbox_order_states");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                orderStates.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label,
                });
                addURLParam(window, prefixAndFilterMapping[prefix], checkbox[i].value);
            }
            setFilter({ ...filter, orderState: orderStates });
        } else {
            let checkbox = [...new Set(filter.orderState)]
            if (e.target.checked) {
                checkbox.push({value: e.target.value, label: e.target.dataset.label});
                addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            } else {
                checkbox = checkbox.filter(item => item.value !== e.target.value);
                removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
            }
            setFilter({...filter, orderState: checkbox})
        }
    }

    const handleOrderZipCodes = (e, prefix) => {
        setPage(1);
        let checkbox = [...new Set(filter.orderZipCodes)]

        if (e.target.checked) {
            checkbox.push({value: e.target.value, label: e.target.dataset.label});
            addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
            removeURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
        }

        setFilter({...filter, orderZipCodes: checkbox})
    }


    const clearFilter = (prefix) => {
        if (prefix === "payoutStatus") {
            setFilter({
                ...filter, payoutStatus: []
            })
        } else if (prefix === "tracking_statuses") {
            setFilter({
                ...filter, tracking_statuses: []
            })
        } else if (prefix === "assignee") {
            setFilter({
                ...filter, assignees: []
            })
        } else if (prefix === "brand") {
            setFilter({
                ...filter, brands: []
            });
        } else if (prefix === "product") {
            setFilter({
                ...filter, products: []
            });
        } else if (prefix === "orderCity") {
            setFilter({
                ...filter, orderCity: []
            });
        } else if (prefix === "orderState") {
            setFilter({
                ...filter, orderState: []
            });
        } else if (prefix === "orderZipCodes") {
            setFilter({
                ...filter, orderZipCodes: []
            });
        } else if (prefix === "financial_status") {
            setFilter({
                ...filter, financial_status: []
            });
        } else if (prefix === "order_status") {
            setFilter({
                ...filter, order_status: []
            });

        }
        setClearAll(false)


        let checkbox = document.querySelectorAll('.checkbox_' + prefix + ':checked')
        if (checkbox.length > 0) {
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = false;
            }
        }
    }

    const handleClearAll = () => {
        setPage(1);

        setClearAll(false)

        document.getElementById("payoutsPageSearch").value = "";

        setFilter({
                startDate: null,
                endDate: null,
                search: null,
                payoutStatus: [],
                tracking_statuses: [],
                assignees: [],
                brands: [],
                products: [],
                orderStartDate: null,
                orderEndDate: null,
                orderCity: [],
                orderState: [],
                orderZipCodes: [],
                order_status: [],
                financial_status: []
            })
            removeAllURLParams();
    }


    if (isAuthentication && !(user.isAdmin || (user.isRetailer && user.isRetailerBillingFeature))){
        window.location.href = window.env.APP_URL;
    }

    // Monday Task : 6530680655 - Payouts(Billing) Page URL Params

    const urlSearchParams = useLocation().search;
    React.useMemo(() => {
        if (filter.urlParamsProcessed === undefined || filter.urlParamsProcessed === null || filter.urlParamsProcessed === undefined) {
        processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter)
        };
    }, [urlSearchParams]);

    const [actionsOpen, setActionsOpen] = useState(false)
    const toggle = () => setActionsOpen((prevState) => !prevState);

    const [tooltipOpen, setTooltipOpen] = useState(false)
    const tooltipToggle = () => setTooltipOpen(!tooltipOpen);


    const inputNotes = useRef();

    const handleInternalNotes = (e, orderId, internalNote, handleRefresh) => {
        e.preventDefault();

        let notes = internalNote;
        let id = orderId;
        let idArray = [];
        if (Array.isArray(orderId)) {
            // If Parameter is an array assign it to idArray
            idArray = orderId
        } else if (Number.isInteger(orderId)) {
            // If Parameter is an integer push it to idArray
            idArray.push(orderId);
        }
        if (inputNotes.current) {
            [notes, id] = inputNotes.current;
        }

        setNotesLoading(true);

        const data = {
            notes: notes,
            payouts: idArray,
        };
        api
            .put("order/payout/notes", data)
            .then((response) => {
                setNotesLoading(false);
                let notes_orders = orders.map((item) => {
                    if (item.id === id) {
                        item.payouts_note = notes;
                    }
                    return item;
                });
                if (handleRefresh) {
                    handleRefresh();
                }

                setOrders(notes_orders);
                addToast("Saved Successfully", {
                    appearance: "success",
                    autoDismiss: true,
                });
                exitBulkNotesModal();
                inputNotes.current = null;

                // Close notes popover
                document.body.click();
            })
            .catch((error) => {
                setNotesLoading(false);
                addToast(error.message, { appearance: "error" });
            });
    };

    const handleBulkNotes = () => {
        setBulkModal(true);
    }

    const capitalizeFirstLetter = string => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    return (
        <>
        {(isAuthentication) && (user.isAdmin || (user.isRetailer && user.isRetailerBillingFeature)) &&
        <Container fluid>
            <PayoutStatusModal data={status} open={openModal} setOpen={setOpenModal} payouts={selected} onComplete={handleUnselect} />
            <div className={"pt-7"}>
                {isAuthentication && <Row className={"mb-2"}>
                    <Col xs={10} lg={"2"} className={"text-left"}>
                        <input type="text"
                               className={"button-font-size form-control form-control-sm"}
                               placeholder={"Search"}
                               defaultValue={filter.search}
                               onKeyDown={handleSearch}
                               newparams={""}
                               id="payoutsPageSearch"
                        />
                    </Col>
                    <Col xs={2} lg={"10"} className={"text-right"}>
                        <div className={"d-none d-lg-inline-block mr-2"}>
                            {/* 7278215305 - increase font size */}
                            <button className="button-font-size btn btn-neutral btn-sm p-9px" onClick={handleRefresh}>
                                <i className="fas fa-sync-alt"></i>
                            </button>
                        </div>

                        <Filter name={"Brand"}
                                prefix={"brand"}
                                onChange={handleBrand}
                                newparams={params}
                                selected={filter.brands}
                                url={"order/filter/brand/"}
                                onClear={() => clearFilter("brand")}
                        />

                        <Filter name={"Product"}
                                prefix={"product"}
                                onChange={handleProduct}
                                newparams={params}
                                selected={filter.products}
                                url={"order/filter/product/"}
                                onClear={() => clearFilter("product")}
                        />

                        <Filter name={"Assignee"}
                                prefix={"assignee"}
                                onChange={handleAssignee}
                                newparams={params}
                                selected={filter.assignees}
                                url={"order/filter/assignee/"}
                                onClear={() => clearFilter("assignee")}
                        />

                        <Filter name={"Payout Status"}
                                prefix={"payoutStatus"}
                                onChange={handlePayoutStatus}
                                newparams={params}
                                selected={filter.payoutStatus}
                                url={"order/filter/payout/status/"}
                                onClear={() => clearFilter("payoutStatus")}
                        />


                        <Filter name={"Tracking Status"}
                                prefix={"tracking_statuses"}
                                onChange={handleTrackingStatus}
                                newparams={params}
                                selected={filter.tracking_statuses}
                                url={"order/filter/tracking/status/"}
                                onClear={() => clearFilter("tracking_statuses")}
                        />
                        <DateRange onCallback={handleDateRange} button_name={"Fulfillment Date"}></DateRange>
                        <DateRange onCallback={handleOrderDateRange} button_name={"Date"}></DateRange>

                        <Filter name={"City"}
                                prefix={"orderCity"}
                                onChange={handleOrderCities}
                                newparams={params}
                                selected={filter.orderCity}
                                url={"order/payout/filter/city/"}
                                onClear={() => clearFilter("orderCity")}
                        />

                        <Filter name={"State"}
                                prefix={"orderState"}
                                onChange={handleOrderStates}
                                newparams={params}
                                selected={filter.orderState}
                                url={"order/payout/filter/state/"}
                                onClear={() => clearFilter("orderState")}
                        />

                        <Filter name={"Zip Codes"}
                                prefix={"orderZipCodes"}
                                onChange={handleOrderZipCodes}
                                newparams={params}
                                selected={filter.orderZipCodes}
                                url={"order/payout/filter/zip-codes/"}
                                onClear={() => clearFilter("orderZipCodes")}
                        />

                        <Filter name={"Financial Status"}
                                prefix={"financial_status"}
                                onChange={handleFinancialStatus}
                                newparams={params}
                                selected={filter.financial_status}
                                url={"/order/filter/financial-status/"}
                                onClear={() => clearFilter("financial_status")}
                        />

                        <Filter name={"Order Status"}
                                prefix={"order_status"}
                                onChange={handleOrderStatus}
                                newparams={params}
                                selected={filter.order_status}
                                url={"order/order-status/"}
                                onClear={() => clearFilter("order_status")}
                        />

                        <ButtonDropdown
                            size={"sm"}
                            isOpen={exportDropdownOpen}
                            toggle={() => setExportDropdownOpen(!exportDropdownOpen)}
                        >
                            {/* 7278215305 - increase font size */}
                            <DropdownToggle
                                style={{ fontSize:'.8rem', width: '100px' }}
                                color="white"
                                className="text-primary p-10px"
                                caret
                            >
                                <i className="fas fa-file-csv" /> {exportButton.label}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={handleExportOrders}>Payouts</DropdownItem>
                                <DropdownItem onClick={handleExportOrdersLineItems}>Payout Line Items</DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>

                                {!!selected.length && user.isAdmin &&
                                    <Dropdown isOpen={actionsOpen} toggle={toggle} size={'sm'} style={{ paddingRight: 0 }}>
                                        <DropdownToggle
                                            caret
                                            color={"primary"}
                                            style={{ fontSize: '0.76rem', margin: 0 }}>
                                            Actions
                                        </DropdownToggle>
                                        <DropdownMenu right={true} flip={true}>
                                            <DropdownItem onClick={() => setOpenModal(true)}>
                                                Update Payout Status
                                            </DropdownItem>
                                            <DropdownItem
                                                href="#"
                                                onClick={(e) => handleBulkNotes(selected)}
                                            >
                                                <i className="fa fa-sticky-note" /> Add Bulk Notes
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>}
                    </Col>

                </Row>}
                <Row>
                    {isAuthentication && <div className="col">
                        <Card className="shadow list-height">
                            <CardHeader className="border-0">
                                <Row>
                                    <Col>
                                        <h3 className="mb-0" style={{display: "inline-block", paddingRight: "5px"}}>Payouts <small>({totalOrders})</small></h3>
                                        { clearall &&
                                            <a href={void (0)}
                                               className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                               onClick={handleClearAll}
                                               id="clear_all_filters">
                                                <i className="fas fa-times-circle"></i> Clear All filters
                                            </a>
                                        }
                                    </Col>

                                    <Col sm="12"><BadgePillFilter data={filter}
                                                                  onClick={removeBadge}/></Col>
                                </Row>
                            </CardHeader>
                            {isLoading ?
                                <div className="text-center mt-3 mb-3">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div> :
                                <>
                                    <Table className="align-items-center table-flush order-class" responsive>
                                        <thead className="thead-light">
                                        <tr>

                                            <th scope="col" className="pl-3 pr-3">
                                                <input
                                                    type="checkbox"
                                                    name={"check_orders"}
                                                    id={"checkbox_orders"}
                                                    ref={refOrdersCheckbox}
                                                    defaultChecked={refOrdersCheckbox.current}
                                                    onChange={handleOrdersCheckbox}
                                                    />
                                            </th>
                                            {(user.isAdmin) && ( <th>Notes</th> )}
                                            <th scope="col" className="pl-3 pr-3"><Sort
                                            onClick={() => handleSort(sort, "status", setSortOrder, setSort)} sortingOrder={sortOrder} column="status">Payout Status</Sort></th>

                                            <th scope="col" className="pl-3 pr-3"><Sort
                                            onClick={() => handleSort(sort, "inventory_location__name", setSortOrder, setSort)} sortingOrder={sortOrder} column="inventory_location__name">Assignee</Sort></th>

                                            <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, "order__shopify_id", setSortOrder, setSort)} sortingOrder={sortOrder} column="order__shopify_id">Order</Sort></th>
                                            <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, "order__created_at", setSortOrder, setSort)} sortingOrder={sortOrder} column="order__created_at">Date</Sort></th>

                                            <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, "fulfillment", setSortOrder, setSort)} sortingOrder={sortOrder} column="fulfillment">Fulfillment ID</Sort></th>

                                            <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, "fulfillment_date", setSortOrder, setSort)} sortingOrder={sortOrder} column="fulfillment_date">Fulfillment Date</Sort></th>

                                            <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, "tracking_status", setSortOrder, setSort)}sortingOrder={sortOrder} column="tracking_status">Tracking Status</Sort></th>

                                            <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, "subtotal", setSortOrder, setSort)} sortingOrder={sortOrder} column="subtotal">Subtotal</Sort></th>

                                            <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, "shipping", setSortOrder, setSort)} sortingOrder={sortOrder} column="shipping">Shipping</Sort></th>
                                            <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, "tax", setSortOrder, setSort)} sortingOrder={sortOrder} column="tax">Tax</Sort></th>
                                            <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, "total", setSortOrder, setSort)} sortingOrder={sortOrder} column="total">Total</Sort></th>
                                            <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, "marketing_fee", setSortOrder, setSort)} sortingOrder={sortOrder} column="marketing_fee">Marketing Fee</Sort></th>
                                            <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, "cc_fee", setSortOrder, setSort)} sortingOrder={sortOrder} column="cc_fee">CC FEE</Sort></th>
                                            <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, "net", setSortOrder, setSort)} sortingOrder={sortOrder} column="net">Net</Sort></th>
                                            <th scope="col" className="pl-3 pr-3">Ref ID</th>
                                            <th scope="col" className="pl-3 pr-3">Transfer ID</th>
                                            <th scope="col" className="pl-3 pr-3">Payout Date</th>
                                            <th scope="col" className="pl-3 pr-3">City</th>
                                            <th scope="col" className="pl-3 pr-3">State</th>
                                            <th scope="col" className="pl-3 pr-3">Zip Code</th>
                                            <th scope="col" className="pl-3 pr-3" align={"center"}>Payout Source</th>
                                            <th scope="col" className="pl-3 pr-3" align={"center"}>User</th>
                                            <th scope="col" className="pl-3 pr-3" align={"center"}>Order Status</th>
                                            <th scope="col" className="pl-3 pr-3" align={"center"}>Financial Status</th>


                                            {/*<th scope="col"/>*/}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {orders.map(order => (
                                            <tr key={order.id}>
                                                <td  className={"pr-3 pl-3"}>
                                                    <input type="checkbox"
                                                           name={order.shopify_id}
                                                           value={order.id}
                                                           title={order.payout_status.toLowerCase()}
                                                           data-assignee={order.assignee}
                                                           id={"checkbox_"+order.id}
                                                           className={"orderCheckbox"}
                                                           onChange={(e) => handleCheckbox(e)}
                                                           defaultChecked={order?.checked}/>
                                                </td>
                                                {(user.isAdmin) && (
                                                    <td className="pl-1 pr-1">
                                                        {order.payouts_note ? (
                                                            <>
                                                                <i
                                                                    className={"fa fa-sticky-note text-primary"}
                                                                    style={{ cursor: "pointer" }}
                                                                    id={"popover_" + order.id}
                                                                ></i>
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    placement="top"
                                                                    target={"popover_" + order.id}
                                                                >
                                                                    {parse(order.payouts_note)}
                                                                </UncontrolledTooltip>
                                                            </>
                                                        ) : (
                                                            <i
                                                                className={"fa fa-sticky-note "}
                                                                style={{ cursor: "pointer" }}
                                                                id={"popover_" + order.id}
                                                            ></i>
                                                        )}
                                                        {user.isAdmin && (
                                                            <UncontrolledPopover
                                                                placement="bottom"
                                                                width={"300px"}
                                                                target={"popover_" + order.id}
                                                                trigger="legacy"
                                                            >
                                                                <PopoverHeader>
                                                                    Payout Notes
                                                                </PopoverHeader>
                                                                <PopoverBody className={""}>
                                                                    <Form
                                                                        onSubmit={(e) =>
                                                                            handleInternalNotes(
                                                                                e,
                                                                                order.id,
                                                                                order.payouts_note
                                                                            )
                                                                        }
                                                                    >
                                                                        <textarea
                                                                            name="inote"
                                                                            onChange={(e) =>
                                                                            (inputNotes.current = [
                                                                                e.target.value,
                                                                                order.id,
                                                                            ])
                                                                            }
                                                                            rows={10}
                                                                            style={{ width: "100%" }}
                                                                            defaultValue={order.payouts_note}
                                                                        ></textarea>
                                                                        <Button className='button-font-size' color={"success"} disabled={notesLoading}>
                                                                            Add note
                                                                            {notesLoading && <Spinner size="sm" color="light" />}
                                                                        </Button>
                                                                    </Form>
                                                                </PopoverBody>
                                                            </UncontrolledPopover>
                                                        )}
                                                    </td>
                                                )}
                                                <td className="pl-3 pr-3 ">

                                                    <PayoutStatus data={payoutStatus} status={capitalizeFirstLetter(order.payout_status)}
                                                                 order={order.id}>{capitalizeFirstLetter(order.payout_status)}</PayoutStatus>
                                                </td>

                                                <td className="pl-3 pr-3 ">{order.assignee}</td>

                                                <td className="pl-3 pr-3">
                                                    <a href={`/order-details?${new URLSearchParams({ orderId: `${order.order_id}` })}`}
                                                       className="btn btn-link p-0">{order.shopify_id}</a>
                                                </td>
                                                <td className="pl-3 pr-3"><Moment format="MM/D/YYYY"
                                                                                  withTitle>{order.shopify_created_at}</Moment>
                                                </td>

                                                <td className="pl-3 pr-3">
                                                    {order.shopify_id &&
                                                    <a href={`/fulfillment?search=${order.shopify_id.replace('#', '')}`}
                                                       className="btn btn-link p-0">{order.fulfillment_id ?? "-"}</a>}
                                                </td>
                                                <td className="pl-3 pr-3">{order.fulfillment_date ? <Moment format="MM/D/YYYY"
                                                                                  withTitle>{order.fulfillment_date}</Moment> :
                                                                                  "-"}
                                                </td>
                                                <td className="pl-3 pr-3 ">{order.fulfillment_tracking_status ?? "-"}</td>


                                                <td className="pl-3 pr-3 ">{order.subtotal}</td>
                                                <td className="pl-3 pr-3">{order.shipping}</td>

                                                <td className="pl-3 pr-3">
                                                    {order.tax}
                                                </td>
                                                <td className="pl-3 pr-3">
                                                    {order.total}</td>
                                                <td className="pl-3 pr-3">{order.mktg_fee}</td>
                                                <td className="pl-3 pr-3">{order.cc_fee}</td>
                                                <td className="pl-3 pr-3">{order.net}</td>
                                                <td className="pl-3 pr-3">{order.stripe_id ? order.stripe_id : "-"}</td>
                                                <td className="pl-3 pr-3">{order.transfer_id ? order.transfer_id : "-"}</td>
                                                <td className="pl-3 pr-3">{order.payout_date ? <Moment format="MM/D/YYYY"
                                                                                  withTitle>{order.payout_date}</Moment> : "-"}</td>
                                                <td className="pl-3 pr-3">{order.order_city}</td>
                                                <td className="pl-3 pr-3">{order.order_state}</td>
                                                <td className="pl-3 pr-3">{order.order_zip}</td>
                                                <td className={"pl-3 pr-3"} align={"center"}>
                                                    {order.payments.length ? order.payments[0].payout_source : '-'}
                                                </td>
                                                <td className={"pl-3 pr-3"} align={"center"}>
                                                    {order.payments.length ? order.payments[0].user ? order.payments[0].user?.full_name : '-' : '-'}
                                                </td>
                                                <td className={"pl-3 pr-3"} align={"center"}>
                                                    {order.order_status ? order.order_status : "-"}
                                                </td>
                                                <td className={"pl-3 pr-3"} align={"center"}>
                                                    {order.financial_status ? order.financial_status : "-"}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                    <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={totalOrders} />
                                </>
                            }
                        </Card>
                    </div>}
                </Row>
            </div>
                    {bulkModal && selected.length > 0 &&
                        <BulkNotes
                            isOpen={bulkModal}
                            onExit={exitBulkNotesModal}
                            selected={selected}
                            handleInternalNotes={handleInternalNotes}
                            notesLoading={notesLoading}
                            inputNotes={inputNotes}
                            handleRefresh = {handleRefresh}
                        />
                    }
        </Container>
        
        }
        </>
    );
};

export default RetailerBilling