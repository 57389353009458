import React from "react";
import styles from "./orderDetail.module.css";
import { Table } from "reactstrap";

const UtmParameters = ({ orderDetails }) => {
    const { order_utm_info } = orderDetails?.order
    return (
        <div className={styles.cardConatiner}>
            <div>
                <h3>UTM Parameters</h3>
            </div>
            <div>
                <Table responsive>
                    <tbody>
                        <tr>
                            <td>UTM Source</td>
                            <td>{order_utm_info.utm_source??"-"}</td>
                        </tr>
                        <tr>
                            <td>UTM Campaign</td>
                            <td>{order_utm_info.utm_campaign??"-"}</td>
                        </tr>
                        <tr>
                            <td>UTM Medium</td>
                            <td>{order_utm_info.utm_medium??"-"}</td>
                        </tr>
                    </tbody>
                </Table>

            </div>
        </div>
    );
};

export default UtmParameters;
