import React, { useState, useContext } from "react";
import styles from "./orderDetail.module.css";
import { Tooltip } from "reactstrap";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { AuthContext } from "contexts/AuthContext";

const DeliveryInformation = ({ orderDetails }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const { user } = useContext(AuthContext);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <div className={styles.cardConatiner}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                    <h3>
                        Delivery Information
                        <BsFillQuestionCircleFill color="#3c71ff" id="dITooltip" style={{ marginLeft: "5px" }} />
                    </h3>
                    <Tooltip target="dITooltip" isOpen={tooltipOpen} toggle={toggle} placement="right">
                        Need to update the shipping address? See here.
                    </Tooltip>
                </div>
                {user && (user.isAdmin || user.isRetailer || orderDetails?.order?.order_delivery_info?.source !== "Mash&Grape") ? (
                    <div className="badge badge-info">{orderDetails?.order?.order_delivery_info?.shipping_method}</div>
                ) : (
                    <div className="badge badge-info">CONFIDENTIAL</div>
                )}
            </div>
            <hr />
            <div className={styles.deliveryInfoClass}>
                <div>
                    <div style={{ fontWeight: "bold" }}>Name</div>
                    {user && (user.isAdmin || user.isRetailer || orderDetails?.order?.order_delivery_info?.source !== "Mash&Grape") ? (
                        <div>{orderDetails?.order?.order_delivery_info?.shipping_name ?? "-"}</div>
                    ) : (
                        <div>CONFIDENTIAL</div>
                    )}
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>Shipping Email</div>
                    {user && (user.isAdmin || user.isRetailer || orderDetails?.order?.order_delivery_info?.source !== "Mash&Grape") ? (
                        <div>{orderDetails?.order?.order_delivery_info?.shipping_email ?? "-"}</div>
                    ) : (
                        <div>CONFIDENTIAL</div>
                    )}
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>Shipping Phone</div>
                    {user && (user.isAdmin || user.isRetailer || orderDetails?.order?.order_delivery_info?.source !== "Mash&Grape") ? (
                        <div>{orderDetails?.order?.order_delivery_info?.shipping_phone ?? "-"}</div>
                    ) : (
                        <div>CONFIDENTIAL</div>
                    )}
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>Shipping Company</div>
                    {user && (user.isAdmin || user.isRetailer || orderDetails?.order?.order_delivery_info?.source !== "Mash&Grape") ? (
                        <div>{orderDetails?.order?.order_delivery_info?.shipping_company ?? "-"}</div>
                    ) : (
                        <div>CONFIDENTIAL</div>
                    )}
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>Address</div>
                    {user && (user.isAdmin || user.isRetailer || orderDetails?.order?.order_delivery_info?.source !== "Mash&Grape") ? (
                        <div>
                            {orderDetails?.order?.order_delivery_info?.shipping_address1 ?? ""}{" "}
                            {orderDetails?.order?.order_delivery_info?.shipping_address2 ?? ""}
                        </div>
                    ) : (
                        <div>CONFIDENTIAL</div>
                    )}
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>City</div>
                    {orderDetails?.order?.order_delivery_info?.shipping_city ?? "-"}
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>State</div>
                    <div>{orderDetails?.order?.order_delivery_info?.shipping_state ?? "-"}</div>
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>Zip code</div>
                    <div>{orderDetails?.order?.order_delivery_info?.shipping_zip ?? "-"}</div>
                </div>
            </div>
        </div>
    );
};

export default DeliveryInformation;
