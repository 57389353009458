import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { MDBCol, MDBIcon } from "mdbreact";
import { api } from "../../services/api";
export function EditOverrideRetailerSearchForm({
  url,
  toggle,
  isOpen,
  loading,
  default_id,
  default_variant_value,
  default_inventory_location_value,
  default_state_value,
  default_sale_channel,
  default_sale_channel_id,
  default_sku_value,
}) {
  const [isLoading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const { addToast } = useToasts();
  const [data, setData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });
  const [paramsFilter, setParamsFilter] = useState({});
  const [state, setState] = useState("");
  const [inventory_location, setInventory_location] = useState("");
  const [InventoryLocation_list, setInventoryLocationlist] = useState([]);
  const [defaultCheckedVariant, setdefaultCheckedVariant] = useState(true);
  const [OrderChannel, setOrderChannel] = useState("");
  const [Channel, setChannel] = useState();
  const [OrderChannel_list, setOrderChannel_list] = useState([]);

  const getOrderChannelList = () => {
    api
      .get(`order/retailer-billing-overrides-channel-list/`)
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          var channels = [{name: 'Null', channel_store: 'None', id: "null"}];
          var finalResult = channels.concat(response.data);
          setOrderChannel_list(finalResult);
        }
      })
      .catch((err) => {
        console.error();
      });
  };

  useEffect(() => {
    getOrderChannelList();
    getInventoryLocation();
    api
      .get(url, {
        params: paramsFilter,
      })
      .then((response) => setData(response.data))
      .catch((error) => console.log(error));
  }, [paramsFilter]);

  let timer = null;

  const handleSearch = (e) => {
    // limpamos o timer
    clearTimeout(timer);
    // armazenamos o timer novamente
    timer = setTimeout(function () {
      setParamsFilter({
        ...paramsFilter,
        search: e.target.value,
      });
    }, 750);
  };

  const getInventoryLocation = () => {
    api
      .get(`inventory-location-list/`)
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          
          setInventoryLocationlist(response.data);
        }
      })
      .catch((err) => {});
  };
  const handleNext = () => {
    if (data.next) {
      let results = data.results;
      setLoading(true);
      api
        .get(data.next)
        .then((response) => {
          const new_results = results.concat(response.data.results);
          setData({
            ...data,
            results: new_results,
            next: response.data.next,
            previous: response.data.previous,
          });
          setLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleVariantCheckbox = (e, variant_id, sku) => {
    if (sku === default_sku_value) {
      setdefaultCheckedVariant(true);
      let checkbox = document.querySelectorAll(".variant_" + variant_id);
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = false;
        e.target.checked = false;
      }
      setSelectedProduct(variant_id);
    } else {
      setdefaultCheckedVariant(false);
      let checkbox = document.querySelectorAll(".variant_" + variant_id);
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = false;
        e.target.checked = false;
      }
      setSelectedProduct(variant_id);
    }
  };

  const handleSubmitForm = (e) => {
    loading = true;
    e.preventDefault();
    document.getElementById("update_btn").disabled = true;
    loading = true;

    let payloadIndividualUpdateData = {
      id: [default_id],
      state: state ? state : default_state_value,
      sales_channel: Channel ? Channel : default_sale_channel_id ,
      inventory_location: inventory_location
        ? inventory_location
        : default_inventory_location_value,
      variant: selectedProduct ? selectedProduct : default_variant_value,
      as_is: false,  // Set the as_is flag False for the individual record update condition
      select_all_in_this_view: false,
    }
      
  console.log('payloadIndividualUpdateData: ', payloadIndividualUpdateData);
    api
      .patch(`retailer-override-ui/`, {
        id: [default_id],
        state: state ? state : default_state_value,
        sales_channel: Channel ? Channel : default_sale_channel_id ,
        inventory_location: inventory_location
          ? inventory_location
          : default_inventory_location_value,
        variant: selectedProduct ? selectedProduct : default_variant_value,
      })
      .then((response) => response)
      .then((response) => {
        if (response.status === 201) {
          addToast(response.data.message, {
            appearance: "success",
            autoDismiss: true,
          });
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
          toggle();
        } else if (response.status === 200) {
          addToast(response.data.message, {
            appearance: "error",
            autoDismiss: true,
          });
          toggle();
        }
      })
      .catch((err) => {
        addToast("Something went wrong, please try again!!", {
          appearance: "error",
          autoDismiss: true,
        });
        toggle();
      });
  };
  return (
    <>
      <div className="form-group mb-3 d-flex flex-column" data-field="name">
        <Modal
          style={{ marginTop: "100px" }}
          isOpen={isOpen}
          onExit={toggle}
          toggle={() => toggle()}
          size={"lg"}
        >
          <Form onSubmit={handleSubmitForm}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
              <span className="text-white h3">
                Edit Retailer Assignment Overrides
              </span>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Row>
                  <Col>
                    <Label>Inventory Location *</Label>
                    <br />
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn text-primary"
                        value={default_inventory_location_value}
                        style={{ width: "350px" }}
                      >
                        {inventory_location
                          ? inventory_location
                          : default_inventory_location_value}
                      </DropdownToggle>
                      <DropdownMenu
                        className="dropdown-menu-arrow pre-scrollable"
                        onChange={(e) => setInventory_location(e.target.value)}
                        onClick={(e) => setInventory_location(e.target.value)}
                        styles={{
                          overflow: "auto",
                          maxHeight: "100",
                          width: "350px",
                        }}
                      >
                        {InventoryLocation_list?.map(({ index, name }) => (
                          <DropdownItem
                            key={index}
                            value={name}
                            onClick={(e) =>
                              setInventory_location(e.target.value)
                            }
                            onChange={(e) =>
                              setInventory_location(e.target.value)
                            }
                            style={{ width: "350px" }}
                          >
                            {name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>

                  <Col>
                    <Label>State *</Label>
                    <br />
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn text-primary"
                        value={default_state_value}
                        style={{ width: "350px" }}
                      >
                        {state
                          ? state
                          : default_state_value}
                      </DropdownToggle>
                      <DropdownMenu
                        className="dropdown-menu-arrow pre-scrollable"
                        onChange={(e) => setState(e.target.value)}
                        onClick={(e) => setState(e.target.value)}
                        styles={{
                          overflow: "auto",
                          maxHeight: "100",
                          width: "350px",
                        }}
                      >
                        <DropdownItem
                          value="AL"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Alabama
                        </DropdownItem>
                        <DropdownItem
                          value="AK"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Alaska
                        </DropdownItem>
                        <DropdownItem
                          value="AZ"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Arizona
                        </DropdownItem>
                        <DropdownItem
                          value="AR"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Arkansas
                        </DropdownItem>
                        <DropdownItem
                          value="CA"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          California
                        </DropdownItem>
                        <DropdownItem
                          value="CO"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Colorado
                        </DropdownItem>
                        <DropdownItem
                          value="CT"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Connecticut
                        </DropdownItem>
                        <DropdownItem
                          value="DE"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Delaware
                        </DropdownItem>
                        <DropdownItem
                          value="DC"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          District Of Columbia
                        </DropdownItem>
                        <DropdownItem
                          value="FL"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Florida
                        </DropdownItem>
                        <DropdownItem
                          value="GA"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Georgia
                        </DropdownItem>
                        <DropdownItem
                          value="HI"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Hawaii
                        </DropdownItem>
                        <DropdownItem
                          value="ID"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Idaho
                        </DropdownItem>
                        <DropdownItem
                          value="IL"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Illinois
                        </DropdownItem>
                        <DropdownItem
                          value="IN"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Indiana
                        </DropdownItem>
                        <DropdownItem
                          value="IA"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Iowa
                        </DropdownItem>
                        <DropdownItem
                          value="KS"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Kansas
                        </DropdownItem>
                        <DropdownItem
                          value="KY"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Kentucky
                        </DropdownItem>
                        <DropdownItem
                          value="LA"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Louisiana
                        </DropdownItem>
                        <DropdownItem
                          value="ME"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Maine
                        </DropdownItem>
                        <DropdownItem
                          value="MD"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Maryland
                        </DropdownItem>
                        <DropdownItem
                          value="MA"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Massachusetts
                        </DropdownItem>
                        <DropdownItem
                          value="MI"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Michigan
                        </DropdownItem>
                        <DropdownItem
                          value="MN"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Minnesota
                        </DropdownItem>
                        <DropdownItem
                          value="MS"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Mississippi
                        </DropdownItem>
                        <DropdownItem
                          value="MO"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Missouri
                        </DropdownItem>
                        <DropdownItem
                          value="MT"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Montana
                        </DropdownItem>
                        <DropdownItem
                          value="NE"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Nebraska
                        </DropdownItem>
                        <DropdownItem
                          value="NV"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Nevada
                        </DropdownItem>
                        <DropdownItem
                          value="NH"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          New Hampshire
                        </DropdownItem>
                        <DropdownItem
                          value="NJ"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          New Jersey
                        </DropdownItem>
                        <DropdownItem
                          value="NM"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          New Mexico
                        </DropdownItem>
                        <DropdownItem
                          value="NY"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          New York
                        </DropdownItem>
                        <DropdownItem
                          value="NC"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          North Carolina
                        </DropdownItem>
                        <DropdownItem
                          value="ND"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          North Dakota
                        </DropdownItem>
                        <DropdownItem
                          value="OH"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Ohio
                        </DropdownItem>
                        <DropdownItem
                          value="OK"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Oklahoma
                        </DropdownItem>
                        <DropdownItem
                          value="OR"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Oregon
                        </DropdownItem>
                        <DropdownItem
                          value="PA"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Pennsylvania
                        </DropdownItem>
                        <DropdownItem
                          value="RI"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Rhode Island
                        </DropdownItem>
                        <DropdownItem
                          value="SC"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          South Carolina
                        </DropdownItem>
                        <DropdownItem
                          value="SD"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          South Dakota
                        </DropdownItem>
                        <DropdownItem
                          value="TN"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Tennessee
                        </DropdownItem>
                        <DropdownItem
                          value="TX"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Texas
                        </DropdownItem>
                        <DropdownItem
                          value="UT"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Utah
                        </DropdownItem>
                        <DropdownItem
                          value="VT"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Vermont
                        </DropdownItem>
                        <DropdownItem
                          value="VA"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Virginia
                        </DropdownItem>
                        <DropdownItem
                          value="WA"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Washington
                        </DropdownItem>
                        <DropdownItem
                          value="WV"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          West Virginia
                        </DropdownItem>
                        <DropdownItem
                          value="WI"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Wisconsin
                        </DropdownItem>
                        <DropdownItem
                          value="WY"
                          onClick={(e) => setState(e.target.value)}
                          style={{ width: "350px" }}
                        >
                          Wyoming
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Sales Channel *</Label>
                    <br />
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn text-primary"
                          value={default_sale_channel}
                          style={{ width: "350px" }}
                        >
                          {OrderChannel ? OrderChannel : default_sale_channel}
                        </DropdownToggle>
                        <DropdownMenu
                          className="dropdown-menu-arrow pre-scrollable"
                          onClick={(e) => { setOrderChannel(e.target.value); setChannel(e.target.id)}}
                          styles={{
                            overflow: "auto",
                            maxHeight: "100",
                            width: "350px",
                          }}
                        >
                          {OrderChannel_list &&
                            OrderChannel_list?.map(( item, key) => (
                              <DropdownItem
                                key={item.id}
                                id={item.id}
                                value={item.channel_store}
                                onClick={(e) => { setOrderChannel(e.target.value) ; setChannel(e.target.id)}}
                                style={{ width: "350px" }}
                              >
                                {item.channel_store}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                  </Col>
                </Row>
                <Label className="mt-3">Variant *</Label>
                <MDBCol style={{ display: "-webkit-box" }}>
                  <MDBIcon icon="search" className="mr-2 ml-0" />
                  <input
                    className="form-control form-control-sm"
                    onKeyDown={handleSearch}
                    type="text"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </MDBCol>
                <small className="ml-5" style={{ color: "#5F93F8" }}>
                  Only one variant can be selected
                </small>
                <ul
                  className={"p-2 overflow-auto"}
                  style={{ maxHeight: "300px" }}
                  id={"filter_"}
                >
                  {data.results.map((product) => (
                    <div key={product.id + "_prod_"}>
                      <div
                        key={product.id + "_product_"}
                        className="row my-2 d-flex align-items-center"
                        style={{ width: "40rem" }}
                      >
                        <div className="col-2 pr-0">
                          <Label className={"text-sm"}>
                            <input
                              type="checkbox"
                              id={"_" + product.id}
                              className={"checkbox_product"}
                              value={product.id}
                              data-label={product.name}
                              data-image={product.image}
                              defaultChecked={product.checked}
                              disabled={true}
                              style={{ marginRight: "20px" }}
                            />
                          </Label>
                          {product.image ? (
                            <img
                              src={product.image}
                              className="img-thumbnail rounded-0"
                              width="50%"
                            />
                          ) : (
                            <img
                              src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                              className="img-thumbnail rounded-0"
                              width="50%"
                            />
                          )}
                        </div>
                        <div className="col-10">
                          <a
                            href={window.env.APP_URL + "product/" + product.id}
                            target="_blank"
                          >
                            <span className="text-wrap"> {product.name}</span>
                            <span
                              className="text-right"
                              style={{ paddingLeft: "10px", color: "black" }}
                            ></span>
                          </a>
                        </div>
                      </div>
                      {product.variants &&
                        product.variants.map((variant, index) => (
                          <div
                            className={"row sub-product ml-3"}
                            key={index + "_variant_"}
                          >
                            <div
                              className="col-2 pr-0 variant-container"
                              key={index}
                            >
                              {variant.sku === default_sku_value ? (
                                <Label className={"text-sm"}>
                                  <input
                                    type="checkbox"
                                    id={"_" + variant.id}
                                    className={"variant_" + variant.id}
                                    value={variant.id}
                                    data-label={variant.sku}
                                    data-image={variant.image}
                                    defaultChecked={defaultCheckedVariant}
                                    checked={defaultCheckedVariant}
                                    onChange={(e) =>
                                      handleVariantCheckbox(
                                        e,
                                        variant.id,
                                        variant.sku
                                      )
                                    }
                                    style={{ marginRight: "20px" }}
                                  />
                                </Label>
                              ) : (
                                <Label className={"text-sm"}>
                                  <input
                                    type="checkbox"
                                    id={"_" + variant.id}
                                    className={"variant_" + variant.id}
                                    value={variant.id}
                                    data-label={variant.sku}
                                    data-image={variant.image}
                                    defaultChecked={variant.checked}
                                    checked={variant.id === selectedProduct}
                                    onChange={(e) =>
                                      handleVariantCheckbox(e, variant.id)
                                    }
                                    style={{ marginRight: "20px" }}
                                  />
                                </Label>
                              )}

                              {variant.image ? (
                                <img
                                  src={variant.image}
                                  className="img-thumbnail rounded-0"
                                  width="50%"
                                />
                              ) : (
                                <img
                                  src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                                  className="img-thumbnail rounded-0"
                                  width="50%"
                                />
                              )}
                            </div>
                            <a href={void 0}>
                              {variant?.size_title === null ?
                                <span className="text-wrap">{`${variant.sku}`}</span>
                                :
                                <span className="text-wrap">{`${variant.size_title} - ${variant.sku}`}</span>
                              }
                              <span
                                className="text-right"
                                style={{ color: "black" }}
                              ></span>
                            </a>
                          </div>
                        ))}
                    </div>
                  ))}

                  {data.results.length < data.count && (
                    <li className={"text-center"}>
                      {!isLoading ? (
                        <Button className={"btn btn-sm "} onClick={handleNext}>
                          show more
                        </Button>
                      ) : (
                        <span className={"text-sm text-muted"}>Loading...</span>
                      )}
                    </li>
                  )}
                </ul>
              </FormGroup>
            </ModalBody>
            <ModalFooter className="mt-0 pt-0">
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              <Button
                color="primary"
                id="update_btn"
                onClick={handleSubmitForm}
              >
                Update
                {loading && <Spinner size="sm" color="light" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </>
  );
}
