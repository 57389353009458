import { createContext, useEffect, useState } from "react";

import {api} from "services/api";
import { setToken, getToken, removeTokens, setRefreshToken, getLogoutToken } from 'services/auth'


const AuthContextData = {
    signIn: credentials => { },
    isAuthentication: false,
    user: null
}

export const AuthContext = createContext(AuthContextData)


export const SignOut = () => {
    removeTokens()
    console.log("#### SIGNOUT ####")
    window.location = window.env.APP_URL
}

export function AuthProvider({ children }) {

    const [user, setUser] = useState();

    const isAuthentication = !!user;

    const logout = getLogoutToken()
    if (logout === true){
        // removeTokens()
        // window.location.href = window.env.APP_URL + "logout/"
    }


    useEffect(() => {
        const token = getToken()
        if (token){
            api.get("user/profile/").then(response => {

                const {
                    id,
                    email,
                    first_name,
                    avatar,
                    role,
                    is_pro,
                    is_standard,
                    discount_feature,
                    product_feature,
                    at_bulk_order,
                    retailer_billing_feature,
                    user_created_product,
                    user_created_buybutton,
                    created_at,
                    brand_jose,
                    insights,
                    // 6818151894 - WMS Retailer - AppCues
                    last_name,
                    alcohol_license,
                    shipping_agreement,
                    stripe_account,
                    fulfillment_state,
                    user_account_fulfillmentstates,
                    company_name,
                    business_email,
                    address,
                    wms_telephone
                } = response.data
                
                setUser({
                    id: id,
                    first_name,
                    avatar,
                    role,
                    isMaster: (role === 'master') ? true : false,
                    isAdmin: (role === 'master' || role === 'manager' || role === 'flaviar-admin') ? true : false,
                    isRetailer: (role === 'retailer') ? true : false,
                    isSupplier: (role === 'supplier') ? true : false,
                    isSupplierProduct: (role === 'supplier_product') ? true : false,
                    isFlaviarAdmin: (role === 'flaviar-admin') ? true : false,
                    isWmsRetailer: (role === 'wms-retailer') ? true : false,
                    isEndConsumer: (role === 'end-consumer') ? true : false,
                    isPro: is_pro,
                    isStandard: is_standard,
                    isDiscountCodeFeature: discount_feature,
                    isProductFeature: product_feature,
                    isAtBulkOrder: at_bulk_order,
                    isRetailerBillingFeature: retailer_billing_feature,
                    IsBrandJose: brand_jose,
                    insights,
                    hasInsights: insights,
                    isManager: (role === 'manager') ? true : false,
                    // 6818151894 - WMS Retailer - AppCues
                    last_name,
                    alcohol_license,
                    shipping_agreement,
                    stripe_account,
                    fulfillment_state,
                    user_account_fulfillmentstates,
                    company_name,
                    business_email,
                    address,
                    wms_telephone
                })

                // 6818151894 - WMS Retailer - AppCues
                const appCues = {
                    created_at: Math.floor(new Date(created_at).getTime() / 1000), // unix timestamp for created_at
                    role: role,
                    first_name: first_name,
                    email: email,
                    user_created_product: user_created_product,
                    user_created_buybutton: user_created_buybutton,
                    language: "en",
                }

                // 6818151894 - WMS Retailer - AppCues
                if (role && role === "wms-retailer") {
                    appCues.last_name = last_name
                    appCues.telephone = wms_telephone
                    appCues.alcohol_license = alcohol_license
                    appCues.shipping_agreement = shipping_agreement
                    appCues.stripe_account = stripe_account
                    appCues.fulfillment_state = fulfillment_state
                    appCues.user_account_fulfillmentstates = user_account_fulfillmentstates
                    appCues.company_name = company_name
                    // appCues.business_email = business_email
                    appCues.address = address
                } 

                window.Appcues.identify(id, appCues);

            }).catch(error => {
                console.log(error)
            })
            
        }
        
    }, [])

    const signIn = async ({ email, password }) => {

        try {

            const response = await api.post('token/', {
                email,
                password
            })
            const { access, refresh } = response.data;
            
            setToken(access)
            setRefreshToken(refresh)


        } catch (err) {
            console.log(err)
        }

    }

    return (
        <AuthContext.Provider value={{ signIn, isAuthentication, user }}>
            {children}
        </AuthContext.Provider>
    )
}