/*
    Author - Akshay Vishwakarma
*/
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
function NotificationConfirmationModal({ modalIsOpen, setModalIsOpen, onConfirmation, id,text ,endpoint , title}) {
    
    const toggleModel = () => {
        setModalIsOpen(!modalIsOpen);
    };
    return (
        <Modal isOpen={modalIsOpen} toggle={toggleModel}>
            <ModalHeader className="bg-primary text-white">
                <span className="text-white h3">Notification - {title}</span>
            </ModalHeader>
            <ModalBody>{text}</ModalBody>
            <ModalFooter>
                <Button onClick={toggleModel}>Cancel</Button>
                <Button
                    className="bg-success text-white"
                    onClick={() => {
                        onConfirmation(endpoint,id);
                        toggleModel();
                    }}>
                    Send
                </Button>
            </ModalFooter>
        </Modal>
    );
}
export default NotificationConfirmationModal;