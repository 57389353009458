import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { api } from "../../services/api";
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";

const VoidOrderFromDiscountCodeChargebacksTable = ({ isOpen, orders, onComplete, onExit }) => {
    const [modal, setModal] = useState();
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    const handleVoidOrders = (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            api.put("order/void/discount-code-chargebacks", { order_ids: orders }).then((response) => {
                setModal(false);
                setLoading(false);
                addToast("Orders successfully voided from the discount code chargeback table!", { appearance: "success", autoDismiss: true });
                setTimeout(() => {
                    if (onComplete) {
                        onComplete();
                    }
                }, 5000);
            })
            
        } catch (error) {
            setLoading(false);
            addToast(error.message, { appearance: "error" });
        }
    };

    useEffect(() => {
        setModal(isOpen);
    }, [isOpen]);

    return (
        <Modal isOpen={modal} toggle={onExit} onExit={onExit}>
            <Form onSubmit={handleVoidOrders}>
                <ModalHeader toggle={onExit} className="bg-primary text-white">
                    <span className="text-white h3">Void From Discount Code Chargebacks</span>
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>This action will void the following ( {orders?.length} ) orders from the discount code chargeback table:</Label>
                        <ul style={{ listStyleType: "disc", paddingLeft: "50px" }}>
                            {orders?.map((order, index) => (
                                <li key={index} style={{ fontSize: "16px", fontWeight: "bold" }}>
                                    {order.title}
                                </li>
                            ))}
                        </ul>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={onExit}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit" disabled={loading}>
                        Save
                        {loading && <Spinner size="sm" color="light" />}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default VoidOrderFromDiscountCodeChargebacksTable;
