import React from "react";
import styles from "./orderDetail.module.css";

const AssigneeInfo = ({orderDetails}) => {
    const {order_assignee_info} = orderDetails?.order
    return (
        <div className={styles.cardConatiner}>
            <div>
                <h3>Assignee Info</h3>
            </div>
            <hr />
            <div>
                <p style={{color:"blue"}}>{order_assignee_info?.inventory_location_name??"-"}</p>
            </div>
        </div>
    );
};

export default AssigneeInfo;
