// 7243367935 - Brand Order Monitoring
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Input, Label } from "reactstrap";
import { api } from "../../services/api";

export function BrandOrderMonitoringCreateModal({ isOpen, toggle, domain, onDomainChange, isEditMode, setEditMode, editRecord, onSuccess, handleDomainPaste }) {
    const [showModal, setShowModal] = useState(isOpen);
    const [isLoading, setLoading] = useState(false);
    const { addToast } = useToasts();
    useEffect(() => {
        setShowModal(isOpen);
    }, [isOpen]);

    const handleSubmitForm = e => {
        e.preventDefault();
        // Check if the domain is not null or empty
        if (!domain || domain.trim() === "") {
            addToast("Domain is required", {
                appearance: "error",
                autoDismiss: true
            });
            return;
        }
        let payload = {
            domain: domain
        };
        const apiCall = isEditMode
            ? api.put("order/brand-order-monitoring", payload, { params: { id: editRecord.id } })
            : api.post("order/brand-order-monitoring", payload);

        apiCall
            .then(response => {
                if (response.status === 201 || response.status === 200) {
                    const successMessage = isEditMode ? "Record updated successfully" : "Record created successfully";
                    addToast(successMessage, {
                        appearance: "success",
                        autoDismiss: true,
                        autoDismissTimeout: 5000
                    });
                    setLoading(false);
                    toggle();
                    setEditMode(false);
                    onSuccess();
                } else {
                    addToast(response.data.message, {
                        appearance: "error",
                        autoDismiss: true
                    });
                    toggle();
                }
            })
            .catch(err => {
                addToast(err.response.data.message, {
                    appearance: "error",
                    autoDismiss: true
                });
                setLoading(false);
                toggle();
            });
    };

    return (
        <>
            {showModal && (
                <div className="form-group mb-3 d-flex flex-column" data-field="name">
                    <Modal style={{ marginTop: "100px" }} isOpen={showModal} onExit={toggle} toggle={() => toggle()} size={"xl"}>
                        <Form onSubmit={handleSubmitForm}>
                            <ModalHeader toggle={toggle} className="bg-primary text-white">
                                <span className="text-white h3">Brand Order Monitoring</span>
                            </ModalHeader>
                            <ModalBody>
                                <Row className="px-4 mb-4">
                                    <Col>
                                        <Label for="domain">Domain</Label>
                                        <Input
                                            type="text"
                                            name="domain"
                                            id="domain"
                                            placeholder="Enter domain"
                                            value={domain}
                                            onChange={onDomainChange}
                                            onPaste={handleDomainPaste} 
                                            required
                                        />
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter className="mt-0 pt-0">
                                <Button color="secondary" onClick={toggle}>
                                    Cancel
                                </Button>
                                <Button color="primary" id="save_btn" disabled={isLoading} onClick={handleSubmitForm}>
                                    Save
                                    {isLoading && <Spinner size="sm" color="light" />}
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                </div>
            )}
        </>
    );
}
