import React from "react";
import styles from "./orderDetail.module.css";

const InstructionToSeller = ({ orderDetails }) => {
    const { order_seller_instructions } = orderDetails?.order;
    const junkValues = ["", " ", "-"];
    const isValidOrderCount = order_seller_instructions && !junkValues.includes(order_seller_instructions);
    return (
        <div className={styles.cardConatiner}>
            <div>
                <h3>Instruction to sellers</h3>
            </div>
            <hr />
            <div>
                <span>{isValidOrderCount ? order_seller_instructions : "-"}</span>
            </div>
        </div>
    );
};

export default InstructionToSeller;
