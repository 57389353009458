import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { api } from "services/api";
import { useToasts } from "react-toast-notifications";
import ConfirmationModal from "../Modal/ConfirmationModal";
import { AuthContext } from "../../contexts/AuthContext";

export const DoordashModal = ({ isOpen = false, order, onExit, handleRefresh }) => {
    console.log("order: ", order);
    const [showModal, setShowModal] = useState(isOpen);
    const { addToast } = useToasts();
    const { user } = useContext(AuthContext);
    const [modelIsOpen, setModelIsOpen] = useState(false);

    // Consolidated form state
    const [formData, setFormData] = useState({
        orderId: "",
        shopifyId: "",
        dropoffAddress1: "",
        dropoffAddress2: "",
        dropoffCity: "",
        dropoffState: "",
        dropoffZip: "",
        dropoffPhone: "",
        pickupAddress1: "",
        pickupAddress2: "",
        pickupCity: "",
        pickupState: "",
        pickupZip: "",
        pickupPhone: "",
        totalAmount: 0
    });

    // State to track validation errors
    const [errors, setErrors] = useState({});

    //Only admin can edit the fields and rest user read only field
    const isFieldDisabled = !user.isAdmin;

    /**
     * show and hide the module alternatively with each click of the crate doordash button
     */
    const toggle = () => {
        setShowModal(!showModal);
    };

    // Single handler for form inputs
    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    // Validate required fields before submission
    const validateForm = () => {
        const newErrors = {};

        // Required fields
        const requiredFields = [
            "dropoffAddress1",
            "dropoffAddress2",
            "dropoffCity",
            "dropoffState",
            "dropoffZip",
            "dropoffPhone",
            "pickupAddress1",
            "pickupAddress2",
            "pickupCity",
            "pickupState",
            "pickupZip",
            "pickupPhone"
        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = "This field is required.";
            }
        });

        setErrors(newErrors);

        // Return true if no errors, false otherwise
        return Object.keys(newErrors).length === 0;
    };

    /**
     * API call to create doordsah delivery
     */
    const handleDoordashDelivery = () => {
        const data = {
            order_id: formData.orderId,
            dropoff_address1: formData.dropoffAddress1,
            dropoff_address2: formData.dropoffAddress2,
            dropoff_city: formData.dropoffCity,
            dropoff_state: formData.dropoffState,
            dropoff_zip: formData.dropoffZip,
            dropoff_phone: formData.dropoffPhone,
            pickup_address1: formData.pickupAddress1,
            pickup_address2: formData.pickupAddress2,
            pickup_city: formData.pickupCity,
            pickup_state: formData.pickupState,
            pickup_zip: formData.pickupZip,
            pickup_phone: formData.pickupPhone
        };

        console.log("payload data: ", data);
        api.post("order/doordash-delivery/create", data)
            .then(() => {
                addToast("Doordash Delivery Created Successfully!", {
                    appearance: "success",
                    autoDismiss: true
                });
                if (handleRefresh) handleRefresh();
            })
            .catch(error => {
                const message = error?.response?.data?.message || "Something went wrong, please try again!!";
                addToast(message, { appearance: "error" });
            });
    };

    // Open doordash module and set all the fields
    useEffect(() => {
        setShowModal(isOpen);
        if (isOpen && order) {
            setFormData({
                orderId: order?.orderId,
                shopifyId: order?.shopify_id || "",
                dropoffAddress1: order?.dropoffDetails?.dropoff_address1 || "",
                dropoffAddress2: order?.dropoffDetails?.dropoff_address2 || "",
                dropoffCity: order?.dropoffDetails?.dropoff_city || "",
                dropoffState: order?.dropoffDetails?.dropoff_state || "",
                dropoffZip: order?.dropoffDetails?.dropoff_zip || "",
                dropoffPhone: order?.dropoffDetails?.dropoff_phone || "",
                pickupAddress1: order?.pickupDetails?.pickup_address1 || "",
                pickupAddress2: order?.pickupDetails?.pickup_address2 || "",
                pickupCity: order?.pickupDetails?.pickup_city || "",
                pickupState: order?.pickupDetails?.pickup_state || "",
                pickupZip: order?.pickupDetails?.pickup_zip || "",
                pickupPhone: order?.pickupDetails?.pickup_phone || "",
            });
            // Reset validation errors when the modal is opened or closed
            setErrors({});
        }
    }, [isOpen, order]);

    /**
     * Toggle Confirmation popup
     */
    const toggleModel = () => {
        setModelIsOpen(!modelIsOpen);
    };

    // When clicking "Create Doordash Delivery" button, validate first
    const handleCreateDoordashClick = () => {
        if (validateForm()) {
            toggleModel(); // Open confirmation modal if validation passes
            setShowModal(!showModal);
        } else {
            addToast("Please fill in all required fields.", { appearance: "error", autoDismiss: true });
        }
    };

    /**
     * Open confirmation popup and close the Create Doordash Delivery module
     */
    const handalConfirm = () => {
        toggleModel(); // Close confirmation modal
        handleDoordashDelivery(); // Call the API to create the delivery
    };

    console.log("errors: ", errors?.dropoffState);

    // Define the field configurations
    const fields = [
        { label: "Dropoff Address 1", name: "dropoffAddress1", required: true },
        { label: "Dropoff Address 2", name: "dropoffAddress2", required: true },
        { label: "Dropoff City", name: "dropoffCity", required: true },
        { label: "Dropoff State", name: "dropoffState", required: true },
        { label: "Dropoff Zipcode", name: "dropoffZip", required: true },
        { label: "Dropoff Phone Number", name: "dropoffPhone", required: true },
        { label: "Pickup Address 1", name: "pickupAddress1", required: true },
        { label: "Pickup Address 2", name: "pickupAddress2", required: true },
        { label: "Pickup City", name: "pickupCity", required: true },
        { label: "Pickup State", name: "pickupState", required: true },
        { label: "Pickup Zipcode", name: "pickupZip", required: true },
        { label: "Pickup Phone Number", name: "pickupPhone", required: true }
    ];

    return (
        <>
            <ConfirmationModal
                modelIsOpen={modelIsOpen}
                setmodelIsOpen={setModelIsOpen}
                onConfirmation={handalConfirm}
                modalBodyMessage="Are you sure you want to create Doordash delivery?"
            />
            {showModal && (
                <Modal size="lg" isOpen={showModal} toggle={toggle} onExit={onExit}>
                    <ModalHeader toggle={toggle} className="bg-primary text-white">
                        <span className="text-white h3">{`Create Doordash Delivery ${formData?.shopifyId}`}</span>
                    </ModalHeader>
                    <ModalBody>
                        <Row xs="1" lg="3">
                            {fields.map((field, index) => (
                                <Col key={index}>
                                    <FormGroup>
                                        <Label>
                                            {field.label} {field.required && "*"}
                                        </Label>
                                        <Input
                                            name={field.name}
                                            placeholder={field.label}
                                            type="text"
                                            value={formData[field.name]}
                                            onChange={handleInputChange}
                                            disabled={isFieldDisabled}
                                            className={errors[field.name]}
                                        />
                                        {errors[field.name] && (
                                            <div style={{ width: "100%", marginTop: "0.25rem", fontSize: "80%", color: "#fb6340" }}>{errors[field.name]}</div>
                                        )}
                                    </FormGroup>
                                </Col>
                            ))}{" "}
                        </Row>
                    </ModalBody>
                    <div className="text-center">
                        <Button className="my-4" color="primary" type="submit" id="save_btn" onClick={handleCreateDoordashClick}>
                            Create Doordash Delivery
                        </Button>
                    </div>
                </Modal>
            )}
        </>
    );
};
