import Joi from "joi";

export const schema = Joi.object({
    active: Joi.optional(),
    assignee: Joi.optional(),
    category: Joi.optional(),
    code: Joi.string().trim().required().max(10).messages({ "any.required": "Code is required", "string.empty": "Code is required" }),
    deleted_at: Joi.optional(),
    full_name: Joi.string().trim().required().messages({ "any.required": "Full name is required", "string.empty": "Full name is required" }),
    id: Joi.optional(),
    is_auto_assignable: Joi.boolean().required(),
    is_retailer: Joi.optional(),
    lat: Joi.optional(),
    long: Joi.optional(),
    name: Joi.string().optional().messages({ "string.empty": "Name is required" }).allow(null),
    priority: Joi.optional(),
    ship_from_address1: Joi.optional(),
    ship_from_address2: Joi.optional(),
    ship_from_city: Joi.optional(),
    ship_from_name: Joi.optional(),
    ship_from_phone: Joi.optional(),
    ship_from_state: Joi.number().required().messages({ "any.required": "State is required", "string.empty": "State is required" }),
    ship_from_zip: Joi.optional(),
    ship_return_address1: Joi.optional(),
    ship_return_address2: Joi.optional(),
    ship_return_city: Joi.optional(),
    ship_return_phone: Joi.optional(),
    ship_return_state: Joi.number().required().messages({ "any.required": "State is required", "string.empty": "State is required" }),
    ship_return_zip: Joi.optional(),
    shipengine_id: Joi.optional(),
    sort: Joi.optional(),
    state: Joi.number().required().messages({ "any.required": "State is required", "string.empty": "State is required" }),
    token: Joi.optional(),
    type: Joi.string().required().messages({ "any.required": "Type is required", "string.empty": "Type is required" }),

    // 6824684201 - Inventory location update issue. Added new fields.
    ship_from_country: Joi.optional(),
    company_name: Joi.optional(),
    dba: Joi.optional(),
    tax_number: Joi.optional(),
    ws_merchant_key: Joi.optional(),
    onboarded: Joi.optional(),
    shipstation_api_key: Joi.optional(),
    shipstation_api_secret: Joi.optional(),
    business_email: Joi.optional(),
    notification_emails: Joi.optional(),
    invoicing_emails: Joi.optional(),
    is_wms_retailer: Joi.optional(),
    wms_username: Joi.optional(),
    archived_at: Joi.optional(),
    is_wms_location: Joi.optional(),
    external_id: Joi.optional(),
});


// This list is mainted to navigate user to another sestion of form
// if one section dont have any bugs but another sesction hash
const sectionWiseFields = {
    "general": [
        "full_name", "name", "code", "state", "type", "is_auto_assignable", "external_id"
    ],
    "shipTo": ["ship_from_zip", "ship_from_state", "ship_from_phone", "ship_from_name", "ship_from_city", "ship_from_address1"],
    "shipFrom": ["ship_return_zip", "ship_return_state", "ship_return_phone", "ship_return_city", "ship_return_address2", "ship_return_address1"],
}

export const navigateToDifferentSectionIfNoErrorsOnCurrentSection = (errors, setactiveSection) => {
    let errorsInGeneralSection = false;
    let errorsInShipToSection = false;
    let errorsInshipFromSection = false;

    for (let error in errors.details) {
        if (sectionWiseFields.general.includes(errors.details[error].path[0])) errorsInGeneralSection = true;
        if (sectionWiseFields.shipTo.includes(errors.details[error].path[0])) errorsInShipToSection = true;
        if (sectionWiseFields.shipFrom.includes(errors.details[error].path[0])) errorsInshipFromSection = true;
    }

    if (errorsInGeneralSection === false && errorsInShipToSection === true) setactiveSection("shipFrom")
    if (errorsInGeneralSection === false && errorsInShipToSection === false && errorsInshipFromSection === true) setactiveSection("shipTo")
}