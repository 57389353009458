import React from 'react'
import { PaginationItem, PaginationLink, Pagination, CardFooter } from 'reactstrap'

const PagePagination = ({ pagination, handlePagination, page, totalCount }) => {
    const lastPage = React.useMemo(() => Math.ceil(totalCount / window.env.PAGE_SIZE), [totalCount])
    return (
        <CardFooter className="text-center">
            <nav aria-label="...">
                {pagination.pages().length >= 1 &&
                    <Pagination
                        className="pagination justify-content-center mb-0"
                        listClassName="justify-content-center mb-0"
                    >

                        {/* Go to first page */}
                        {!((pagination.pages()).includes(1)) && <PaginationItem>
                            <PaginationLink
                                href="#"
                                onClick={(e) => handlePagination(1)}
                                tabIndex="-1"
                            >
                                <i className="fas fa-angle-left" />
                                <i className="fas fa-angle-left" />
                            </PaginationLink>
                        </PaginationItem>}

                        {/* Go to previous page */}
                        <PaginationItem className="">
                            <PaginationLink
                                href="#"
                                onClick={(e) => handlePagination(pagination?.previous)}
                                tabIndex="-1"
                            >
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                            </PaginationLink>
                        </PaginationItem>

                        {pagination.pages().map((pageIndex, index) => (
                            <PaginationItem key={index}
                                className={(page === pageIndex) ? "active" : ""}>
                                <PaginationLink
                                    href="#"
                                    onClick={(e) => handlePagination(pageIndex)}
                                >
                                    {pageIndex}
                                </PaginationLink>
                            </PaginationItem>
                        )
                        )}

                        {/* Go to next page */}
                        <PaginationItem>
                            <PaginationLink
                                href="#"
                                onClick={(e) => handlePagination(pagination?.next)}
                            >
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                            </PaginationLink>
                        </PaginationItem>

                        {/* Go to last page */}
                        {(!(pagination.pages()).includes(lastPage)) && <PaginationItem>
                            <PaginationLink
                                href="#"
                                onClick={(e) => handlePagination(lastPage)}
                            >
                                <i className="fas fa-angle-right" />
                                <i className="fas fa-angle-right" />
                            </PaginationLink>
                        </PaginationItem>}

                    </Pagination>}
            </nav>
        </CardFooter>
    )
}

export default PagePagination;