

import React, {useContext, useEffect, useRef, useState} from "react";
import {api} from "../../../services/api";
import {ComponentContext} from "../../../contexts/ComponentContext";
import {AuthContext} from "../../../contexts/AuthContext";
import BadgePillFilter from "components/Filters/Badge";
import {useToasts} from "react-toast-notifications";
import { SubmitShopifyModalForm, ArchiveDeleteModalForm, DeleteModalForm, ProductStatus, ConfirmIndividualActionForm } from "../../../components/Product"
import fileDownload from "js-file-download";
import moment from "moment";
import {
    Button,
    Card, CardFooter,
    CardHeader,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink,
    Row, Table,
    UncontrolledDropdown, UncontrolledTooltip, PopoverBody, PopoverHeader, UncontrolledPopover, Form,
    Spinner, Modal, ModalHeader, ModalBody, FormGroup, ModalFooter, Input
} from 'reactstrap'
import Filter from "../../../components/Filters/Filter";
import Moment from "react-moment";

import parse from "html-react-parser";
import { useHistory, useLocation } from "react-router-dom";
import BulkNotes from "components/Modal/bulkNotes";
import { addURLParam, processURLSearchParams, removeAllURLParams, removeURLParam } from "../../../utils/urls";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import { Sort, handleSort } from "utils/commonFunctions";
import AsyncSelect from 'react-select/async'
import { quantize } from 'ol/geom/flat/simplify'


const  ComboFormModalForm = props => {

    const [modal, setModal] = useState(props.isOpen);
    const {addToast} = useToasts();

    const [product, setProduct] = useState(null)
    const [comboSkus, setComboSkus] = useState(null)
    const [variant, setVariant] = useState({})
    const [inlineForm, setInlineForm] = useState([{variant_id: null, quantity: 1}])


    const toggle = () => {
        setModal(!modal)
    };

    const handleRemove = (index) => {

        const newFields = inlineForm.filter((field, i) => i !== index);
        setInlineForm(newFields);
    };

    const handleSubmit = (e) => {
        let data = {
            variant_id: product.value,
            subskus: inlineForm
        }
        const url = "/product/combo-skus/create/"
        if (comboSkus) {
            data.id = comboSkus
        }

        console.log(data)

        api.post(url, data)
        .then(response => {

            setModal(false)
            addToast('Saved Successfully', {appearance: 'success', autoDismiss: true});
            props.reloadData()
            props.onExit()
            // setLoading(false)
            // addComponent({SubmitShopifyModalForm: data})
        }).catch(error => addToast(error.message, {appearance: 'error'}))
    }



    const mapResponseToValuesAndLabels = (data) => ({
        value: data.id,
        label: data.name,
    });

    async function getProducts(value) {

        const data = await api.get(`product/variant-sku/?search=${value}`)
          .then((response) => {

              return response.data
          })
          .then((response) => response?.results.map(mapResponseToValuesAndLabels))
          .then((final) =>
            final.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
          );

        return data;
    }


    const addForm = () => {
        setInlineForm([...inlineForm, {'variant_id': null, 'quantity': 1}])
    }

    const childForm = () => (
      <>
          {inlineForm.map((form, index) => (
            <Row className={''}>
                <Col className="col-sm-9">
                    <FormGroup>

                        <AsyncSelect cacheOptions loadOptions={getProducts} defaultOptions
                                     value={{label: form.label, value:form.variant_id}}
                                     classNamePrefix={`select-${index}`}
                                     onChange={(event) => {
                                         console.log(event)
                                         handleChangeSku(index, event)
                                     }} id={`select-${index}`}/>
                    </FormGroup>
                </Col>
                <Col className="col-sm-2">
                    <FormGroup>

                        <Input
                          id={`quantity-${index}`}
                          placeholder="quantity"
                          type="number"
                          onChange={(event)=>{ handleChangeQuantity(index, event) }}
                          value={form.quantity}
                        />
                    </FormGroup>
                </Col>
                <Col className="col-sm-1">
                    <Button className={'btn  btn-danger'} onClick={()=>{handleRemove(index)}}>
                        <i className="fas fa-trash"></i>
                    </Button>
                </Col>
            </Row>)) }
      </>
    )

    const handleChangeSku = (index, event) => {
        const newFields = inlineForm.map((field, i) => {
            if (i === index) {
                return { ...field, variant_id: event.value, label: event.label };
            }
            return field;
        });
        setInlineForm(newFields);
    };

    const handleChangeQuantity = (index, event) => {
        const newFields = inlineForm.map((field, i) => {
            if (i === index) {
                return { ...field, quantity: parseInt(event.target.value) };
            }
            return field;
        });
        setInlineForm(newFields);
    };

    useEffect(() => {
        console.log(inlineForm)
    }, [inlineForm])

    const getComboSkus = () => {
        if (props?.id > 0){
            api.get(`/product/combo-skus/${props?.id}/`).then(response => {

                const data = response.data
                setComboSkus(data.id)
                setProduct({value: data.parent_sku.value, label: data.parent_sku.label })
                setInlineForm(data.child_variant)
                console.log("data", {value: data.parent_sku.value, label: data.parent_sku.label })
            })
        }
    }

    useEffect(() => {
        console.log(props?.id)
        getComboSkus()
    }, [props?.id])

    return (
      <>

          <Modal isOpen={true} size={'lg'} toggle={toggle} onExit={props.onExit} style={{paddingTop: 200}}>
              <Form onSubmit={handleSubmit} >
                  <ModalHeader toggle={toggle} className="text-left">
                      Combo Skus
                  </ModalHeader>

                  <ModalBody className={'text-center'}>
                      <Container>
                          <Row>

                              <Col className="col-sm-12">

                                  <FormGroup className={'text-left'}>
                                      Parent Sku

                                      <AsyncSelect cacheOptions
                                                   loadOptions={getProducts}
                                                   defaultOptions
                                                   value={product}
                                                   onChange={(event) => {
                                                       console.log(event)
                                                       setProduct(event)
                                                   }}/>
                                  </FormGroup>
                              </Col>
                              <Col className="col-sm-12 text-left">
                                  Child Skus
                                  {childForm()}
                              </Col>
                          </Row>
                      </Container>
                      <Button  onClick={()=>{addForm()}}> <i className="fas fa-plus"></i> Add</Button>

                  </ModalBody>

                  <ModalFooter className={'flex justify-content-between'}>
                      <Button color="secondary" onClick={()=>{props.onExit()}}>Cancel</Button>
                      <Button color="success" onClick={handleSubmit}>Save</Button>
                  </ModalFooter>
              </Form>
          </Modal>
      </>
    )
}
const ComboSkusListing = (props) => {
    const urlSearchParams = useLocation().search;

    const [Products, setProducts] = useState([])
    const [idParent, setIdParent] = useState(null)
    const [inventories, setInventories] = useState([])
    const [totalProducts, setTotalProducts] = useState(0)
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return []
        }
    });
    const [status, setStatus] = useState([])


    const [filter, setFilter] = useState({
        brands: [],
        product_status: [],
        distributors: [],
        distribution_states: [],
        users: [],
        productCategory:[],
        search: "",
        urlParamsProcessed : false,
    })

    const [sort, setSort] = useState("-id");
    const [sortOrder, setSortOrder] = useState({order: "", columnName: ""});
    const [id, setId] = useState()
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const [selected, setSelected] = useState([])
    const [params, setParams] = useState([])
    const [clearall, setClearAll] = useState(false)
    const {component} = useContext(ComponentContext)
    const { addToast } = useToasts();
    const [notesLoading, setNotesLoading] = useState(false);

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    const getParams = () => {
        let new_params = {
            page,
            ordering: sort
        }

        if (filter.search?.length > 0) {
            new_params.search = filter.search
            setClearAll(true)
        }

        if (filter.brands.length > 0) {
            new_params.brand = []
            filter.brands.map(item => new_params.brand.push(item.value))
            setClearAll(true)
        }

        if (filter.distribution_states.length > 0) {
            new_params.state = [];
            filter.distribution_states.map((item) => new_params.state.push(item.value));
            setClearAll(true);
        }

        if (filter.users.length > 0) {
            new_params.userFilter = [];
            filter.users.map((item) => new_params.userFilter.push(item.value));
            setClearAll(true);
        }

        if (filter.product_status.length > 0) {
            new_params.product_status = []
            filter.product_status.map(item => new_params.product_status.push(item.value))
            setClearAll(true)
        }

        if (filter.distributors.length > 0) {
            new_params.distributors = [];
            filter.distributors.map(item => new_params.distributors.push(item.value));
            setClearAll(true);
        }

        if (filter.productCategory.length > 0) {
            new_params.productCategory = [];
            filter.productCategory.map(item => new_params.productCategory.push(item.value));
            setClearAll(true);
        }

        setParams(new_params)
        return new_params;
    }

    useEffect(() => {

        api.get('/product/product-status/')
          .then(response => {
              setStatus(response.data.results)
          })
          .catch(error => console.log(error))

    }, [])

    const history = useHistory();

    React.useMemo(() => {
        processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter);
    }, [urlSearchParams]);

    const getData = () => {

        if (filter.urlParamsProcessed) {
            setLoading(true);

            const new_params = getParams();
            console.log(new_params)
            api.get('/product/combo-skus/', {
                params: new_params
            })
              .then(response => {

                  setProducts(response.data.results);
                  setInventories(response.data.inventories)
                  setTotalProducts(response.data.count);

                  const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                  const previous = (page !== 1) ? page - 1 : 1
                  const next = (page !== totalPages) ? page + 1 : totalPages
                  setPagination({
                      previous,
                      next,
                      pages: () => {

                          let startCount = 1;
                          let endCount = isMobile? 4 : 12;
                          let numberCount = Math.round(endCount / 2)
                          const pageList = [];
                          if (numberCount < 0) numberCount = 1;
                          startCount = page - numberCount

                          // If start count is negative or equal to zero
                          if (startCount <= 0) startCount = 1;
                          if (page !== 1) endCount = page + numberCount;
                          if (endCount > totalPages) endCount= totalPages;

                          if (totalPages >= endCount) {
                              for (let i = startCount; i <= endCount; i++) {
                                  pageList.push(i)
                              }
                          } else if (totalPages >= 1) {
                              for (let i = 1; i <= totalPages; i++) {
                                  pageList.push(i)
                              }
                          }
                          return pageList;
                      }
                  })
                  setLoading(false);
                  delete new_params['page']
                  delete new_params['ordering']
                  setParams(new_params);
              })
              .catch(err => {
                  console.log(err);
              })
        }
    }

    useEffect(() => {
        getData()
    }, [page, sort, filter, refresh, component])

    const handleClick = (e) => {
        return true
    }

    const handlePagination = page => {
        setPage(page)
    }

    const refProductsCheckbox = useRef(false)

    const handleProductsCheckbox = (e) => {

        refProductsCheckbox.current = e.target.checked
        let checkboxes = document.querySelectorAll('.productCheckbox');

        let items = [];
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = e.target.checked;
            if (e.target.checked === true) {
                items.push({id: parseInt(checkboxes[i].value), title: checkboxes[i].name})
            }
        }
        setSelected(items)

    }

    const [exportButton, setExportButton] = useState({
        label: "Export",
        disabled: false,
    });

    /**
     * Export for Products
     */
    const handleExportComboSkus = () => {
        setExportButton({
            label: "Exporting...",
            disabled: true,
        });

        let new_params = params;
        // new_params.product_ids = selected.map((item) => item.id);

        api
          .get("product/combo-skus/export/", {
              params: new_params,
          })
          .then((response) => response.data)
          .then((response) => {
              const success = response?.success | false;

              if (success) {
                  addToast(response.message, {
                      appearance: "success",
                      autoDismiss: false,
                  });
              } else {
                  const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
                  const filename = "comboskus_" + timestamp + ".csv";
                  fileDownload(response, filename);
              }

              setExportButton({
                  label: "Export",
                  disabled: false,
              });
          });
    };


    const handleCheckbox = (e) => {
        let checkbox = [...new Set(selected)]
        const productRecord = Products.find(product => product.id == e.target.value);

        if (e.target.checked) {
            checkbox.push({id: e.target.value, title: e.target.name, note: productRecord.internal_note})
        } else {
            // !== strict inequality operator checking the order id available or not, so that's why we use != inequality operator
            checkbox = checkbox.filter(item => item.id != e.target.value)
        }

        setSelected(checkbox)

    }

    const onFilterChange = (e, prefix) => {
        let filteredRecords = [...new Set(filter[`${prefix}`])];

        if (e.target.checked) {
            filteredRecords.push({ value: e.target.value, label: e.target.dataset.label });
            addURLParam(window, prefix, e.target.value);
        } else {
            filteredRecords = filteredRecords.filter(item => item.value !== e.target.value);
            removeURLParam(window, prefix, e.target.value);
        }
        let updatedFilter = { ...filter };
        updatedFilter[`${prefix}`] = filteredRecords;
        setFilter(updatedFilter);
    }

    const handleStates = (e) => {
        if (e == "__all__") {
            let distribution_states = [];

            let checkbox = document.querySelectorAll(".checkbox_state");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                distribution_states.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label,
                });
                addURLParam(window, "distribution_states", checkbox[i].value);
            }
            setFilter({ ...filter, distribution_states: distribution_states });
        } else {
            let checkbox = [...new Set(filter.distribution_states)];

            if (e.target.checked) {
                checkbox.push({ value: e.target.value, label: e.target.dataset.label });
                addURLParam(window, "distribution_states", e.target.value);
            } else {
                checkbox = checkbox.filter((item) => item.value !== e.target.value);
                removeURLParam(window, "distribution_states", e.target.value);
            }

            setFilter({ ...filter, distribution_states: checkbox });
        }
    };

    const handleUserFilter = (e) => {
        let checkbox = [...new Set(filter.users)];

        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
            addURLParam(window, "users", e.target.value);
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
            removeURLParam(window, "users", e.target.value);
        }
        setFilter({ ...filter, users: checkbox });
    };

    const handleProductCategory = (e) => {
        let checkbox = [...new Set(filter.productCategory)];

        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
            addURLParam(window, "productCategory", e.target.value);
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
            removeURLParam(window, "productCategory", e.target.value);
        }
        setFilter({ ...filter, productCategory: checkbox });
    };
    const handleProductStatus = (e) => {
        if (e == "__all__") {
            let product_status = [];
            let checkbox = document.querySelectorAll(".checkbox_status");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                product_status.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label,
                });
                addURLParam(window, "product_status", checkbox[i].value);
            }
            setFilter({ ...filter, product_status: product_status });
        } else {
            let checkbox = [...new Set(filter.product_status)]
            if (e.target.checked) {
                checkbox.push({value: e.target.value, label: e.target.dataset.label});
                addURLParam(window, "product_status", e.target.value);
            } else {
                checkbox = checkbox.filter(item => item.value !== e.target.value);
                removeURLParam(window, "product_status", e.target.value);
            }
            setFilter({...filter, product_status: checkbox});
        };
    };

    const handleProducts = (e) => {
        let checkbox = [...new Set(filter.products)]

        if (e.target.checked) {
            checkbox.push({value: e.target.value, label: e.target.dataset.label})
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value)
        }

        setFilter({...filter, products: checkbox})
    }

    const handleRefresh = () => {
        setRefresh(!refresh)
    }

    const clearProductsSelected = () => {
        setSelected([])
        let checkboxes = document.querySelectorAll('.productCheckbox');

        let items = [];
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false;
        }
    }

    let timer = null;
    const handleSearch = (e) => {
        addURLParam(window, "search", e.target.value);
        clearTimeout(timer);
        timer = setTimeout(function () {
            setFilter({
                ...filter,
                search: e.target.value
            })
        }, 750);
    }

    const clearFilter = (prefix) => {

        if (prefix === "brands") {
            setFilter({
                ...filter, brands: []
            })
            removeURLParam(window, "brands");
        } else if (prefix === "product_status") {
            setFilter({
                ...filter, product_status: []
            })
            removeURLParam(window, "product_status");
        } else if (prefix === "distributors") {
            setFilter({
                ...filter, distributors: []
            })
            removeURLParam(window, "distributors");
        } else if (prefix === "distribution_states") {
            setFilter({
                ...filter, distribution_states: []
            })
            removeURLParam(window, "distribution_states");
        } else if (prefix === "userFilter") {
            setFilter({
                ...filter, users: []
            })
            removeURLParam(window, "users");
        }
        else if (prefix === "productCategory") {
            setFilter({
                ...filter, productCategory: []
            })
            removeURLParam(window, "productCategory");
        }
        setClearAll(false)
    }

    const removeBadge = (e) => {

        if (e.component === 'brand') {
            let items = filter.brands
            items = items.filter(item => item.value !== e.item.value)
            setFilter({...filter, brands: items})
            removeURLParam(window, "brands", e.item.value);
        } else if (e.component === "product") {
            let items = filter.products
            items = items.filter(item => item.value !== e.item.value)
            setFilter({...filter, products: items})
            removeURLParam(window, "product", e.item.value);
        } else if (e.component === 'search') {
            setFilter({...filter, search: null})
            removeURLParam(window, "search");
            document.getElementById("productPageSeachInput").value = "";
        } else if (e.component == 'selected') {
            let items = selected
            items = items.filter(item => item.id !== e.item.id)
            setSelected(items)
            let checkbox = document.getElementById('checkbox_'+e.item.id)
            if (checkbox) {
                checkbox.checked = false
            }
        }
        else if (e.component == 'product_status') {
            let items = filter.product_status
            items = items.filter(item => item.value !== e.item.value)
            setFilter({...filter, product_status: items})
            removeURLParam(window, "product_status", e.item.value);
        } else if (e.component == 'distributors') {
            let items = filter.distributors
            items = items.filter(item => item.value !== e.item.value)
            setFilter({ ...filter, distributors: items })
            removeURLParam(window, "distributors", e.item.value);
        } else if (e.component === "distribution_states") {
            let items = filter.distribution_states;
            items = items.filter((item) => item.value !== e.item.value);
            setFilter({ ...filter, distribution_states: items });
            removeURLParam(window, "distribution_states", e.item.value);
        }
        else if (e.component === "userFilter") {
            let items = filter.users;
            items = items.filter((item) => item.value !== e.item.value);
            setFilter({ ...filter, users: items });
            removeURLParam(window, "users", e.item.value);
        }
        else if (e.component === "productCategory") {
            let items = filter.productCategory;
            items = items.filter((item) => item.value !== e.item.value);
            setFilter({ ...filter, productCategory: items });
            removeURLParam(window, "productCategory", e.item.value);
        }
        setClearAll(false)

    }

    const handleClearAll = () => {

        setClearAll(false)

        document.getElementById("productPageSeachInput").value = "";
        setFilter({
            brands: [],
            product_status: [],
            distributors: [],
            distribution_states: [],
            users: [],
            search: '',
            urlParamsProcessed : true,
        })
        removeAllURLParams()
    }

    const exitSubmitShopifyModal = () => {
        setOpenModal(false)
        setIdParent(null)
    }







    const {user, isAuthentication} = useContext(AuthContext)

    if (isAuthentication && !(user.isProductFeature)){
        window.location.href = window.env.APP_URL;
    }





    const workflowTooltips = {
        user: 'Product will be reviewed soon.',
        published: 'Product has been published.',
        shopify: 'Product is under review.',
    };


    return (
      <>
          {(isAuthentication) ?
            <Container fluid>

                { openModal && <ComboFormModalForm
                  isOpen={openModal}
                  onExit={exitSubmitShopifyModal}
                  reloadData={getData}
                  id={idParent}

                /> }

                <div className={"pt-7"}>
                    {isAuthentication && <Row className={"mb-2"}>
                        <Col xs={10} lg={"2"} className={"text-left"}>
                            <input type="text"
                                   className={"form-control form-control-sm"}
                                   placeholder={"Parent Sku or Child SKU"}
                                   defaultValue={filter.search}
                                   onChange={handleSearch}
                                   newparams={""}
                                   id="productPageSeachInput"
                            />
                        </Col>
                        <Col xs={2} lg={"10"} className={"text-right"}>
                            <div className={"d-none d-lg-inline-block mr-2"}>
                                <button className="btn btn-neutral btn-sm p-9px" onClick={handleRefresh}>
                                    <i className="fas fa-sync-alt"></i>
                                </button>

                                <Filter name={"Brand"}
                                        prefix={"brands"}
                                        onChange={onFilterChange}
                                        newparams={params}
                                        selected={filter.brands}
                                        url={"/product/filter/brand/"}
                                        onClear={() => clearFilter("brands")}
                                />


                                <Button
                                  color={"primary"}
                                  size={"sm"}
                                  className="p-10px"
                                  disabled={exportButton.disabled}
                                  onClick={handleExportComboSkus}
                                >
                                    <i className="fas fa-file-csv"></i> {exportButton.label}
                                </Button>
                            </div>
                        </Col>

                    </Row>}
                    <Row>
                        {isAuthentication && <div className="col">
                            <Card className="shadow ">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col>
                                            <h3 className="mb-0">Combo Skus <small>({totalProducts})</small>{ clearall &&
                                              <a href={void (0)}
                                                 className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                                 onClick={handleClearAll}
                                                 id="clear_all_filters">
                                                  <i className="fas fa-times-circle"></i> Clear All filters
                                              </a>
                                            }</h3>

                                        </Col>
                                        <Col className="text-right">


                                            <Button  className="btn btn-success btn-sm" onClick={()=>{

                                                setOpenModal(true)
                                            }}>
                                                <i className="fas fa-plus"></i> Add
                                            </Button>

                                        </Col>

                                        <Col sm="12"><BadgePillFilter title="Product" data={filter}
                                                                      selected={selected} onClick={removeBadge}/>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                {isLoading ?
                                  <div className="text-center mt-3 mb-3">
                                      <div className="spinner-border" role="status">
                                          <span className="sr-only">Loading...</span>
                                      </div>
                                  </div> :
                                  <>
                                      <Table className="align-items-center table-flush" responsive>
                                          <thead className="thead-light">
                                          <tr>
                                              <th scope="col" className="pl-3 pr-3">
                                                  {/*<input*/}
                                                  {/*  type="checkbox"*/}
                                                  {/*  name={'check_orders'}*/}
                                                  {/*  id={'checkbox_orders'}*/}
                                                  {/*  ref={refProductsCheckbox}*/}
                                                  {/*  defaultChecked={refProductsCheckbox.current}*/}
                                                  {/*  onChange={handleProductsCheckbox}/>*/}
                                              </th>


                                              <th scope="col" className="pl-3 pr-1">
                                                  <Sort
                                                    onClick={() => handleSort(sort, 'comboskus__parent_variant__sku', setSortOrder, setSort)}
                                                    sortingOrder={sortOrder}
                                                    column="comboskus__parent_variant__sku">Parent Sku
                                                  </Sort>
                                              </th>

                                              <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, 'child_variant__sku', setSortOrder, setSort)}
                                                sortingOrder={sortOrder} column="child_variant__sku">Child
                                                  Sku</Sort>
                                              </th>
                                              <th scope="col" className="pl-3 pr-1">
                                                  <Sort
                                                    onClick={() => handleSort(sort, 'quantity', setSortOrder, setSort)}
                                                    sortingOrder={sortOrder}
                                                    column="quantity">Quantity
                                                  </Sort>
                                              </th>
                                              <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, 'child_variant__product__brand__name', setSortOrder, setSort)}
                                                sortingOrder={sortOrder} column="child_variant__product__brand__name">Brand</Sort></th>


                                              <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, 'created_at', setSortOrder, setSort)}
                                                sortingOrder={sortOrder} column="created_at">Created At</Sort>
                                              </th>

                                              <th scope="col" className="pl-3 pr-3"><Sort
                                                onClick={() => handleSort(sort, 'updated_at', setSortOrder, setSort)}
                                                sortingOrder={sortOrder} column="updated_at">Updated At</Sort>
                                              </th>

                                          </tr>
                                          </thead>
                                          <tbody>
                                          {Products.map(product => (
                                            <tr key={product.id}>
                                                <td className={'pr-3 pl-3'}>
                                                    {/*     <input type="checkbox"*/}
                                                    {/*         name={product.id}*/}
                                                    {/*         value={product.id}*/}
                                                    {/*         id={"checkbox_"+product.id}*/}
                                                    {/*         className={"productCheckbox"}*/}
                                                    {/*         onChange={(e) => handleCheckbox(e)}*/}
                                                    {/*defaultChecked={product?.checked}/>*/}
                                                </td>

                                                <td className="pl-3 pr-1">
                                                    <a className={'btn btn-link'} onClick={()=>{
                                                        setIdParent(product.comboskus)
                                                        setOpenModal(true)
                                                    }}>{product.parent_sku}</a>
                                                </td>
                                                <td className="pl-3 pr-3">{product.child_sku}</td>
                                                <td className="pl-3 pr-3">{product.quantity}</td>
                                                <td className="pl-3 pr-3">{product.brand}</td>
                                                <td className="pl-3 pr-3"><Moment format="MM/D/YYYY"
                                                                                  withTitle>{product.created_at}</Moment></td>
                                                <td className="pl-3 pr-3"><Moment format="MM/D/YYYY"
                                                                                  withTitle>{product.updated_at}</Moment></td>

                                            </tr>
                                          ))}
                                          </tbody>
                                      </Table>
                                      <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={totalProducts} />
                                  </>
                                }
                            </Card>
                        </div>}
                    </Row>
                </div>
            </Container> :
            null
          }
      </>
    );


}

export default ComboSkusListing