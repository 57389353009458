import React, { useState, useContext } from "react";
import styles from "./orderDetail.module.css";
import { Tooltip } from "reactstrap";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { AuthContext } from "contexts/AuthContext";

const BillingInformation = ({ orderDetails }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const { user } = useContext(AuthContext);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <div className={styles.cardConatiner}>
            <div>
                <h3>
                    Billing Information
                    <BsFillQuestionCircleFill color="#3c71ff" id="dITooltip" style={{ marginLeft: "5px" }} />
                </h3>
                <Tooltip target="dITooltip" isOpen={tooltipOpen} toggle={toggle} placement="right">
                    Customer requesting to update billing info? See this article.
                </Tooltip>
            </div>
            <hr />
            <div className={styles.billingInfoClass}>
                <div>
                    <div style={{ fontWeight: "bold" }}>Name</div>
                    {user && (user.isAdmin || user.isRetailer || orderDetails?.order?.order_billing_info?.source !== "Mash&Grape") ? (
                        <div>{orderDetails?.order?.order_billing_info?.billing_name ?? "-"}</div>
                    ) : (
                        <div>CONFIDENTIAL</div>
                    )}
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>Billing Email</div>
                    {user && (user.isAdmin || user.isRetailer || orderDetails?.order?.order_billing_info?.source !== "Mash&Grape") ? (
                        <div>{orderDetails?.order?.order_billing_info?.billing_email ?? "-"}</div>
                    ) : (
                        <div>CONFIDENTIAL</div>
                    )}
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>Billing Phone</div>
                    {user && (user.isAdmin || user.isRetailer || orderDetails?.order?.order_billing_info?.source !== "Mash&Grape") ? (
                        <div>{orderDetails?.order?.order_billing_info?.billing_phone ?? "-"}</div>
                    ) : (
                        <div>CONFIDENTIAL</div>
                    )}
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>Billing Company</div>
                    {user && (user.isAdmin || user.isRetailer || orderDetails?.order?.order_billing_info?.source !== "Mash&Grape") ? (
                        <div>{orderDetails?.order?.order_billing_info?.billing_company ?? "-"}</div>
                    ) : (
                        <div>CONFIDENTIAL</div>
                    )}
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>Address</div>
                    {user && (user.isAdmin || user.isRetailer || orderDetails?.order?.order_billing_info?.source !== "Mash&Grape") ? (
                        <div>
                            {orderDetails?.order?.order_billing_info?.billing_address1 ?? ""}{" "}
                            {orderDetails?.order?.order_billing_info?.billing_address2 ?? ""}
                        </div>
                    ) : (
                        <div>CONFIDENTIAL</div>
                    )}
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>City</div>
                    <div>{orderDetails?.order?.order_billing_info?.billing_city ?? "-"}</div>
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>State</div>
                    <div>{orderDetails?.order?.order_billing_info?.billing_state ?? "-"}</div>
                </div>
                <div>
                    <div style={{ fontWeight: "bold" }}>Zip code</div>
                    <div>{orderDetails?.order?.order_billing_info?.billing_zip ?? "-"}</div>
                </div>
            </div>
        </div>
    );
};

export default BillingInformation;
