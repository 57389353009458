import React, { useState, useContext } from "react";
import styles from "./orderDetail.module.css";
import { AuthContext } from "contexts/AuthContext";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip } from "reactstrap";
import Moment from "react-moment";
import { useToasts } from "react-toast-notifications";
import { api } from "services/api";
import FulfillmentInfoConfirmationModal from "./FulfillmentInfoConfirmationModal";
import ShipEngineHistoryModal from "./ShipEngineHistoryModal";
import { managedByIds } from "utils/commonFunctions";

const FullfilmentInformation = ({ orderDetails }) => {
    const orderId = orderDetails?.order?.order_info?.id;
    const { user } = useContext(AuthContext);
    const { addToast } = useToasts();
    const { pre_fulfillment_info, fulfillment_info, pre_fulfillment_count, fulfillment_count, valid_fulfillments } =
        orderDetails?.order?.order_fulfillment_info;
    const [fulfillmentToggle, setFulfillmentToggle] = useState(false);
    const [fulfillmentIDToggle, setFulfillmentIdToggle] = useState({});
    const [preFulfillmentToggle, setPreFulfillmentToggle] = useState({});
    const [shipEngineTrackingId, SetShipEngineTrackingId] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [shipEngineModalIsOpen, setShipEngineModalIsOpen] = useState(false);
    const [endPoint, setEndpoint] = useState("");
    const [id, setId] = useState(null);
    const [text, setText] = useState(false);
    const [type, setType] = useState("");
    const [hasPayload, setHasPayload] = useState(false);
    const [method, setMethod] = useState("");
    const [tooltipOpen, setTooltipOpen] = useState({});

    const toggle = (id) => setTooltipOpen(prev => ({
        ...prev,
        [id]: !prev[id]
    }));

    const handleModelToggle = (text, endPoint, id, type, hasPayload, method) => {
        setText(text);
        setType(type);
        setMethod(method);
        setEndpoint(endPoint);
        setId(id);
        if (hasPayload) {
            setHasPayload(true);
        }
        setModalIsOpen(true);
    };

    const handleToggleShipEngineHistoryModal = (trackingId) => {
        SetShipEngineTrackingId(trackingId)
        setShipEngineModalIsOpen(true);
    }

    const fetchData = async () => {
        try {
            let url;
            let payload = {};
            let response;
            if (!hasPayload) {
                url = `${endPoint}${id}`;
            } else {
                url = endPoint;
                if (url === "order/notification/shipped/") {
                    payload = {
                        id: orderId
                    };
                } else {
                    payload = {
                        order_id: orderId
                    };
                }
            }

            if (method === "post") {
                response = await api.post(url, payload);
            } else {
                response = await api.get(url, payload);
            }

            const status = response?.status;
            const data = response?.data;
            if (status === 200) {
                addToast(data?.message, { appearance: "success", autoDismiss: true });
            } else {
                addToast(data?.message, { appearance: "error", autoDismiss: true });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleShopifyFulfillment = (endpoint, fulfillmentId) => {
        const url = endpoint;

        // Create a URLSearchParams object to format the payload as form-data
        const payload = new URLSearchParams();
        payload.append("id", fulfillmentId);

        api.post(url, payload)
            .then(response => {
                const status = response?.status;
                const data = response?.data;
                if (status === 200 && data?.status === true) {
                    addToast(data?.message, { appearance: "success", autoDismiss: true });
                } else {
                    addToast(data?.message, { appearance: "error", autoDismiss: true });
                }
            })
            .catch(error => {
                console.error("Error making the request:", error);
                const errorMessage = error.response?.data?.message || error.message || "An unexpected error occurred.";
                addToast(errorMessage, { appearance: "error", autoDismiss: true });
            });
    };

    const handleAfterShipFulfillment = (endpoint, orderId, orderName, trackingNumber, trackingCompany) => {
        const url = endpoint;

        // Create the payload as a JSON object
        const payload = {
            order_id: orderId,
            order_name: orderName,
            tracking_number: trackingNumber,
            tracking_company: trackingCompany
        };

        api.post(url, payload, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                const status = response?.status;
                const data = response?.data;
                if (status === 200 && data?.status === true) {
                    addToast(data?.message, { appearance: "success", autoDismiss: true });
                } else {
                    addToast(data?.message, { appearance: "error", autoDismiss: true });
                }
            })
            .catch(error => {
                console.error("Error making the request:", error);
                const errorMessage = error.response?.data?.message || error.message || "An unexpected error occurred.";
                addToast(errorMessage, { appearance: "error", autoDismiss: true });
            });
    };

    const toggleFulfillment = () => {
        setFulfillmentToggle(!fulfillmentToggle);
    };
    const toggleFulfillmentId = index => {
        setFulfillmentIdToggle(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };
    const togglePreFulfillment = index => {
        setPreFulfillmentToggle(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const getBadgeClass = trackingStatusType => {
        if (trackingStatusType === "M") {
            return "badge badge-warning";
        } else if (["I", "IT", "NY"].includes(trackingStatusType)) {
            return "badge bg-yellow opacity-5 text-dark";
        } else if (["X", "EX", "UN", "AT"].includes(trackingStatusType)) {
            return "badge bg-blue text-white";
        } else if (["D", "DE"].includes(trackingStatusType)) {
            return "badge badge-success";
        } else if (["P", "MV"].includes(trackingStatusType)) {
            return "badge badge-default";
        } else {
            return "badge badge-warning";
        }
    };

    function getShipmentStatusBadgeClass(shipmentStatus) {
        if (["label_printed", "label_purchased", "ready_for_pickup", "confirmed"].includes(shipmentStatus)) {
            return "badge badge-warning";
        } else if (["out_for_delivery", "in_transit"].includes(shipmentStatus)) {
            return "badge bg-yellow opacity-5 text-dark";
        } else if (["attempted_delivery", "failure", "returned", "damaged", "lost"].includes(shipmentStatus)) {
            return "badge bg-blue text-white";
        } else if (shipmentStatus === "delivered") {
            return "badge badge-success";
        } else {
            return "badge badge-warning";
        }
    }

    const { managed_by_id } = orderDetails?.order?.order_info;
    const addFulfillmentInfoPermissions = managedByIds;
    const isPermission = managed_by_id && addFulfillmentInfoPermissions.includes(managed_by_id);

    return (
        <>
            <FulfillmentInfoConfirmationModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} text={text} onConfirmation={fetchData} type={type} />
            <ShipEngineHistoryModal modalIsOpen={shipEngineModalIsOpen} setModalIsOpen={setShipEngineModalIsOpen} id={shipEngineTrackingId} />
            {(valid_fulfillments || pre_fulfillment_count) ? (
                <div className={styles.cardConatiner}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <h3>Fullfilment Information</h3>
                        </div>
                        {user && user.isAdmin && !isPermission &&(
                            <div>
                                <Dropdown isOpen={fulfillmentToggle} toggle={() => toggleFulfillment()}>
                                    <DropdownToggle className="btn text-primary" role="button" size="sm" color="white" onClick={e => e.preventDefault()}>
                                        <i className="fas fa-ellipsis-v text-dark"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                        <DropdownItem
                                            disabled={!fulfillment_count ? true : false}
                                            onClick={() =>
                                                handleModelToggle(
                                                    "You sure that you want to void all fulfillments?",
                                                    "order/fulfillment/void/",
                                                    orderId,
                                                    "warning",
                                                    false,
                                                    "get"
                                                )
                                            }>
                                            Void All Fulfillment
                                        </DropdownItem>
                                        <DropdownItem
                                            disabled={!pre_fulfillment_count || fulfillment_count ? true : false}
                                            onClick={() =>
                                                handleModelToggle(
                                                    "You sure that you want to void pre fulfillments?",
                                                    "fulfillment/void/pre-fulfillment/",
                                                    orderId,
                                                    "warning",
                                                    true,
                                                    "post"
                                                )
                                            }>
                                            Void All Pre-Fulfillment
                                        </DropdownItem>
                                        <DropdownItem
                                            disabled={!fulfillment_count ? true : false}
                                            onClick={() =>
                                                handleModelToggle(
                                                    "You sure that you want to send shipped notification?",
                                                    "order/notification/shipped/",
                                                    orderId,
                                                    "primary",
                                                    true,
                                                    "post"
                                                )
                                            }>
                                            Send Shipped Notification
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        )}
                    </div>

                    <hr />
                    {pre_fulfillment_info.length > 0 &&
                        pre_fulfillment_info.map((data, index) => (
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "2rem" }} key={index}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div></div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <div className="badge badge-warning">Pre-Fulfillment</div>
                                        <div>
                                            {((user && user.isAdmin && data?.tracking_number) || !data?.aftership_id) && !isPermission && (
                                                <Dropdown isOpen={preFulfillmentToggle[index]} toggle={() => togglePreFulfillment(index)}>
                                                    <DropdownToggle
                                                        className="btn text-primary"
                                                        role="button"
                                                        size="sm"
                                                        color="white"
                                                        onClick={e => e.preventDefault()}>
                                                        <i className="fas fa-ellipsis-v text-dark"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                        <DropdownItem
                                                            disabled={!data?.shopify_api_id || data?.aftership_id ? true : false}
                                                            onClick={() =>
                                                                handleAfterShipFulfillment(
                                                                    "react/aftership/create/",
                                                                    data?.order_id,
                                                                    data?.order_name,
                                                                    data?.fulfillment_tracking_number,
                                                                    data?.fulfillment_tracking_company
                                                                )
                                                            }>
                                                            Create AfterShip Fulfillment
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: "bold" }}>Fulfillment Date</div>
                                    <div>
                                        <Moment format="MMMM DD YYYY, h:mm:ssa" withTitle>
                                            {data?.fulfillment_date}
                                        </Moment>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: "bold" }}>Tracking Number</div>
                                    <div>{data?.tracking_number}</div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: "bold" }}>Tracking Company</div>
                                    <div>{data?.tracking_company}</div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: "bold" }}>Tracking Status</div>
                                    <div className="badge badge-warning" style={{ width: "100%" }}>
                                        {data?.tracking_status ?? "No Status Yet"}
                                    </div>
                                </div>

                                <hr />
                            </div>
                        ))}

                    {/* fulfillment Info below */}

                    {fulfillment_info?.length > 0 &&
                        fulfillment_info.map((data, index) => (
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "2rem" }} key={index}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <div style={{ fontWeight: "bold" }}>Fulfillment Id</div>
                                        <div><a href={`/fulfillment?${new URLSearchParams({ order_id: `${data?.fulfillment_id}` }).toString()}`} target="_blank"rel="noreferrer" >{data?.fulfillment_id}</a></div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <div
                                            className={`badge badge-${data?.fulfillment_type === "local" ? "success" : data?.fulfillment_type === "shipping" ? "purple" : "success"
                                                }`}>
                                            {data?.fulfillment_type}
                                        </div>
                                        <div>
                                            {user && user.isAdmin && data?.fulfillment_tracking_number?.tracking_number && !isPermission && (
                                                <Dropdown isOpen={fulfillmentIDToggle[index]} toggle={() => toggleFulfillmentId(index)}>
                                                    <DropdownToggle
                                                        className="btn text-primary"
                                                        role="button"
                                                        size="sm"
                                                        color="white"
                                                        onClick={e => e.preventDefault()}>
                                                        <i className="fas fa-ellipsis-v text-dark"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                        <DropdownItem onClick={() => handleToggleShipEngineHistoryModal(data?.fulfillment_tracking_number?.tracking_number)}>
                                                            ShipEngine History
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            disabled={!data?.fulfillment_shopify_api_id || data?.fulfillment_shopify_created_at ? true : false}
                                                            onClick={() => handleShopifyFulfillment("fulfillment/shopify-create/", data?.id)}>
                                                            Create Shopify Fulfillment
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() =>
                                                                handleAfterShipFulfillment(
                                                                    "react/aftership/create/",
                                                                    data?.order_id,
                                                                    data?.order_name,
                                                                    data?.fulfillment_tracking_no,
                                                                    data?.fulfillment_tracking_company
                                                                )
                                                            }>
                                                            Create AfterShip Fulfillment
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: "bold" }}>Fulfillment Date</div>
                                    <div>
                                        <Moment format="MMMM DD YYYY, h:mm:ssa" withTitle>
                                            {data?.fulfillment_date}
                                        </Moment>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: "bold" }}>Tracking Number</div>
                                    {data?.fulfillment_tracking_number?.hyperlink ? (
                                        <div>
                                            <div id={`fulfillmentTracking-${index}`}>
                                                <a href={data?.fulfillment_tracking_number?.url}>{data?.fulfillment_tracking_number?.tracking_number}</a>
                                            </div>
                                            <Tooltip target={`fulfillmentTracking-${index}`} isOpen={tooltipOpen[index]} toggle={() => toggle(index)} placement="top">
                                                "Go to" {data?.fulfillment_tracking_number?.url}
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div>{data?.fulfillment_tracking_number?.tracking_number}</div>
                                    )}
                                </div>
                                <div>
                                    <div style={{ fontWeight: "bold" }}>Tracking Company</div>
                                    <div>{data?.fulfillment_tracking_company}</div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: "bold" }}>Expected Delivery Date</div>
                                    <div>
                                        {data?.fulfillment_expected_delivery_date ? (
                                            <Moment format="MMMM DD YYYY, h:mm:ssa" withTitle>
                                                {data?.fulfillment_expected_delivery_date}
                                            </Moment>
                                        ) : (
                                            "-"
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div style={{ fontWeight: "bold" }}>
                                        Tracking Status As Of{" "}
                                        <Moment format="MMMM DD YYYY, h:mm:ssa" withTitle>
                                            {data?.fulfillment_tracking_updated_at}
                                        </Moment>
                                    </div>
                                    {data?.fulfillment_tracking_status_type ? (
                                        <div className={`${getBadgeClass(data?.fulfillment_tracking_status_type)}`} style={{ width: "100%" }}>
                                            {data?.fulfillment_shipment_status}
                                        </div>
                                    ) : (
                                        <div className={`${getShipmentStatusBadgeClass(data?.fulfillment_shipment_status)}`} style={{ width: "100%" }}>
                                            {data?.fulfillment_shipment_status}
                                        </div>
                                    )}
                                </div>
                                <hr />
                            </div>
                        ))}
                </div>
            ):null}
        </>
    );
};

export default FullfilmentInformation;
