/*
    Author - Akshay Vishwakarma
    Description - This page is a modal, which is used to create new record for advent calendar 
    Jira (if any) - BCD - 4091
*/

import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
    Button,
    Col,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Input,
} from "reactstrap";
import { api } from "../../services/api";
import ByShippingInfo from "./ByShippingInfo";
import ByOrderName from "./ByOrderName";

export function AdventCalendarCreateModal({ url, toggle, isOpen, loading, handleRefresh }) {
    const { addToast } = useToasts();
    const [isLoading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(isOpen);
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState({});
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedChildProduct, setSelectedChildProduct] = useState([]);
    const [selectedState, setSelectedState] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [availabeQty, setAvailableQty] = useState(0);
    const [formOption, setFormOption] = useState(true);
    const [force, setForce] = useState(false);
    const [orderId, setOrderId] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [stateIsValid, setStateIsValid] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [countryIsValid, setCountryIsValid] = useState(true);

    const createRecord = () => {
        document.getElementById("save_btn").disabled = true;
        let states = selectedState.length > 0 ? selectedState.map((item) => item.value) : [];
        let country = selectedCountry.length > 0 ? selectedCountry.map((item) => item.value) : [];
        let order_id = (orderId instanceof Object && orderId !== null) ? orderId?.value : ""

        setLoading(true);

        let option_1_payload = {
            shipping_states: states,
            shipping_countries: country,
            available_quantity: availabeQty,
            parent_order_variant: selectedProduct,
            child_order_variant: selectedChildProduct,
        }

        let option_2_payload = {
            parent_order_variant: selectedProduct,
            child_order_variant: selectedChildProduct,
            order_id: order_id,
            force: force,
        }

        let option_1_url = "flaviar/order/advent-batch/";
        let option_2_url = "flaviar/advent-batches/orders/"

        api
            .post(formOption ? option_1_url : option_2_url, formOption ? option_1_payload : option_2_payload)
            .then((response) => {
                if (response.status === 201) {
                    addToast(response.data.message, {
                        appearance: "success",
                        autoDismiss: true,
                        autoDismissTimeout: 40000,
                    });
                    setLoading(false);
                    toggle();

                    setTimeout(() => {
                        // window.location.reload(false);
                        handleRefresh()
                    }, 1000);
                } else {
                    addToast(response.data.message, {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    toggle();
                }
            })
            .catch((err) => {
                addToast("Something went wrong, please try again !", {
                    appearance: "error",
                    autoDismiss: true,
                });
                setLoading(false);
                toggle();
            });
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        
        if (!error) {

            if (formOption) {
                selectedState.length !== 0 || selectedCountry.length > 0 ? setStateIsValid(true) : setStateIsValid(false);
                selectedCountry.length !== 0 || selectedState.length > 0 ? setCountryIsValid(true) : setCountryIsValid(false);
                if (
                    ((selectedState.length !== 0) || (selectedCountry.length !== 0)) &&
                    (selectedProduct.length !== 0) &&
                    (availabeQty !== "") &&
                    (selectedChildProduct.length !== 0)
                ) {

                    createRecord();

                } else {
                    addToast("Missing required field !", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    setLoading(false);
                }
            } else {
                if ((selectedProduct.length !== 0) && orderId && (selectedChildProduct.length !== 0)) {

                    createRecord();

                } else {
                    addToast("Missing required field !", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    setLoading(false);
                }
            }

        } else {
            const errorData = {};
            for (let item of error.details) {
                const name = item.path[0];
                const message = item.message;
                errorData[name] = message;
            }
            setError(errorData);
            setLoading(false);
            return errorData;
        }
    };

    //this function toggles between "By Shipping Info and By Order Name"
    const toggeleOption = () => {
        setFormOption(!formOption);
        setSelectedState([]);
        setSelectedCountry([]);
        setSelectedProduct([]);
        setAvailableQty(0);
        setSelectedChildProduct([]);
        setForce(false);
        setOrderId("")
    }

    useEffect(() => {
        setShowModal(isOpen);
        if (isOpen) {
            // Clear form data if set last time
            setSelectedState([]);
            setSelectedCountry([]);
            setSelectedProduct([]);
            setSelectedChildProduct([]);
            setAvailableQty(0);
            setForce(false);
            setOrderId("")
            setError("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        setFormOption(true)
    }, [showModal])

    return (
        <>
            {showModal && (
                <div className="form-group mb-3 d-flex flex-column" data-field="name">
                    <Modal
                        style={{ marginTop: "100px" }}
                        isOpen={showModal}
                        onExit={toggle}
                        toggle={() => toggle()}
                        size={"xl"}

                    >
                        <Form onSubmit={handleSubmitForm}>
                            <ModalHeader toggle={toggle} className="bg-primary text-white">
                                <span className="text-white h3">
                                    Advent Calendar
                                </span>
                            </ModalHeader>
                            <ModalBody>
                                <Row className="px-4 mb-4">
                                    <Col>
                                        <Input
                                            name="option1"
                                            type="radio"
                                            checked={formOption}
                                            onChange={toggeleOption}
                                        />
                                        <label>By Shipping Info</label>
                                    </Col>
                                    <Col>
                                        <Input
                                            name="option2"
                                            type="radio"
                                            checked={!formOption}
                                            onChange={toggeleOption}
                                        />
                                        <label>By Order Name</label>
                                    </Col>
                                </Row>

                                {/* Below are two components for which will be displayed depending condition.
                                    condition 1 - ByShippingInfo 
                                        -componenet : <ByShippingInfo/>
                                    condition 2 - ByOrderName
                                        -component : <ByOrderName/>
                                 */}
                                {formOption ? <ByShippingInfo
                                    url={url}
                                    selectedProduct={selectedProduct}
                                    setSelectedProduct={setSelectedProduct}
                                    selectedChildProduct={selectedChildProduct}
                                    setSelectedChildProduct={setSelectedChildProduct}
                                    setSelectedState={setSelectedState}
                                    selectedState={selectedState}
                                    stateIsValid={stateIsValid}
                                    selectedCountry={selectedCountry}
                                    setSelectedCountry={setSelectedCountry}
                                    countryIsValid={countryIsValid}
                                    availabeQty={availabeQty}
                                    setAvailableQty={setAvailableQty}
                                /> : <ByOrderName
                                    url={url}
                                    orderId={orderId}
                                    setOrderId={setOrderId}
                                    setForce={setForce}
                                    force={force}
                                    selectedProduct={selectedProduct}
                                    setSelectedProduct={setSelectedProduct}
                                    selectedChildProduct={selectedChildProduct}
                                    setSelectedChildProduct={setSelectedChildProduct}
                                />}
                            </ModalBody>
                            <ModalFooter className="mt-0 pt-0">
                                <Button color="secondary" onClick={toggle}>
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    id="save_btn"
                                    disabled={isLoading}
                                    onClick={handleSubmitForm}
                                >
                                    Save
                                    {isLoading && <Spinner size="sm" color="light" />}
                                </Button>
                            </ModalFooter>
                        </Form>

                    </Modal>
                </div>
            )}
        </>
    );
}
