/*
    Author - Akshay Vishwakarma
    Description - This is a listing page for Advent-calendar
    Jira (if any) - BCD - 4091
*/

import React, { useContext, useEffect, useState } from "react";
import {useHistory} from 'react-router-dom';
import {
    Card,
    CardHeader,
    Table,
    Container,
    Row,
    Col,
    Button,
} from "reactstrap";
import Moment from "react-moment";
import { api } from "services/api";
import { AuthContext } from "contexts/AuthContext";
import useFilter from "customHooks/useFilter";
import { getParams } from "utils/commonFunctions";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import { AdventCalendarCreateModal } from "components/Modal/AdventCalendarCreateModal";
import "../../utils/commonStyles.css";

const AdventCalendar = props => {
    const history = useHistory();
    const [adventCalendar_api_result, setAdventCalendar_api_result] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });
    const [isLoading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [selected, setSelected] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [refresh, setRefresh] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [filter, setFilter, clearFilterState] = useFilter({});

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    useEffect(() => {
        getAdventCalenderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filter, refresh]);

    const getAdventCalenderList = () =>{
        setLoading(true);
        let new_params = getParams(filter);
        new_params = { ...new_params, page};
        api.get("flaviar/order/advent-batch/", { params: new_params })
            .then(response => {
                const list = response.data.results.map(order => {
                    const s = selected.filter(item => parseInt(item.id) === order.id);
                    order.checked = s.length > 0;
                    return order;
                });
                setAdventCalendar_api_result(list);
                setTotalOrders(response.data.count);
                const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                const previous = page !== 1 ? page - 1 : 1;
                const next = page !== totalPages ? page + 1 : totalPages;
                setPagination({
                    previous,
                    next,
                    pages: () => {
                        let startCount = 1;
                        let endCount = isMobile ? 4 : 12;
                        let numberCount = Math.round(endCount / 2);
                        const pageList = [];
                        if (numberCount < 0) numberCount = 1;
                        startCount = page - numberCount;
                        if (startCount <= 0) startCount = 1;
                        if (page !== 1) endCount = page + numberCount;
                        if (endCount > totalPages) endCount = totalPages;
                        if (totalPages >= endCount) {
                            for (let i = startCount; i <= endCount; i++) {
                                pageList.push(i);
                            }
                        } else if (totalPages >= 1) {
                            for (let i = 1; i <= totalPages; i++) pageList.push(i);
                        }
                        return pageList;
                    }
                });
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const handlePagination = page => {
        setPage(page);
    };

    const { user, isAuthentication } = useContext(AuthContext);

    const handleRefresh = () => {
        // setRefresh(!refresh);
        getAdventCalenderList();
    };

    if (isAuthentication && !user.isAdmin) window.location.href = window.env.APP_URL;
    return (
        <>
            {isAuthentication && user.isAdmin && (
                <Container fluid>
                    <div className={"pt-7"}>
                        {isAuthentication && (
                            <Row className={"mb-2"}>
                                <Col xs={10} lg={"2"} className={"text-left"}></Col>
                                <Col xs={2} lg={"10"} className={"text-right"}>
                                    <div className={"d-none d-lg-inline-block mr-2"}>
                                        <div className="mb-1 d-inline">
                                            <button className="button-font-size btn btn-neutral btn-sm mr-2 p-9px" onClick={handleRefresh}>
                                                <i className="fas fa-sync-alt"></i>
                                            </button>
                                        </div>

                                        <Button className="button-font-size p-9px" color={"primary"} size={"sm"} onClick={e => setOpenModal(true)}>
                                            <i className="fas fa-file-csv"></i> Create
                                        </Button>

                                        <AdventCalendarCreateModal
                                            url={"flaviar/order/advent-calendar/products"}
                                            isOpen={openModal}
                                            toggle={()=>setOpenModal(false)}
                                            loading={isLoading}
                                            handleRefresh={handleRefresh}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            {isAuthentication && (
                                <div className="col">
                                    <Card className="shadow ">
                                        <CardHeader className="border-0">
                                            <Row>
                                                <Col>
                                                    <h3
                                                        className="mb-0"
                                                        style={{
                                                            display: "inline-block",
                                                            paddingRight: "5px"
                                                        }}>
                                                        Advent Calendar <small>&nbsp;({totalOrders})</small>
                                                    </h3>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        {isLoading ? (
                                            <div className="text-center mt-3 mb-3">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <Table className="align-items-center table-flush" responsive>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Batch Id 
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Created at
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Updated at
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                User
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Shipping States
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Shipping Countries
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                            Parent (pre order) Product
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                            Child (Fulfillable-Order)
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                               Parent SKU
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                               Child SKU
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Available Quantity
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                            Order Name
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Status
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Force
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Error Message
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Unsynced BOB Orders
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                     <tbody>
                                                        {adventCalendar_api_result.map(record => (
                                                            <tr key={record.id}>
                                                                
                                                                <td className="pl-3 pr-3 ">
                                                                    {/* <span style={{ color: "#5e72e4",cursor:"pointer"}} onClick={()=>history.push({pathname:`/advent-batch`,search:new URLSearchParams({Id: `${record.id}`}).toString()})}>{record.id}</span> */}
                                                                    <a href={`/advent-batch?${new URLSearchParams({Id: `${record.id}`}).toString()}`}>{record.id}</a>
                                                                </td>
                                                                <td className="pl-3 pr-3 ">
                                                                    <Moment format="M/D/Y h:mm:ssa" withTitle>
                                                                        {record.created_at}
                                                                    </Moment>
                                                                </td>
                                                                <td className="pl-3 pr-3 ">
                                                                    <Moment format="M/D/Y h:mm:ssa" withTitle>
                                                                        {record.updated_at}
                                                                    </Moment>
                                                                </td>
                                                                <td className="pl-3 pr-3 ">{record.full_name  ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.shipping_states_code  ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.shipping_countries_list  ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.parent_order_variant_name ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.child_order_variant_name ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.parent_sku ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.child_sku ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.available_quantity ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.order_name === "" ? "-" : record.order_name}</td>
                                                                <td className="pl-3 pr-3 ">{record.statuses ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.force ? "True" :"False"}</td>
                                                                <td className="pl-3 pr-3 ">{record.error_message ? record.error_message : "-"}</td>
                                                                <td className="pl-3 pr-3 ">{record.unsynced_orders ?? "-"}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={totalOrders} />
                                            </>
                                        )}
                                    </Card>
                                </div>
                            )}
                        </Row>
                    </div>
                </Container>
            )}
        </>
    );
};
export default AdventCalendar;
