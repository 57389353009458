// 7243367935 - Brand Order Monitoring
import React, { useEffect, useState } from "react";
import { Card, CardFooter, CardHeader, Col, Container, Pagination, PaginationItem, PaginationLink, Row, Table, Button } from "reactstrap";
import { api } from "services/api";
import { BrandOrderMonitoringCreateModal } from "components/Modal/BrandOrderMonitoringCreateModal";
import { removeAllURLParams } from "../../utils/urls";
import { useToasts } from "react-toast-notifications";
import { FaEdit, FaTrash } from "react-icons/fa";
import ConfirmDeleteModal from "components/Modal/BrandOrderMonitoringDeleteModal";
import Moment from "react-moment";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import Filter from "../../components/Filters/Filter";
import BadgePillFilter from "components/Filters/Badge";
import { onFilterChange, getParams, removeBadge, clearFilter } from "../../utils/commonFunctions";

const BrandOrderMonitoring = () => {
    const [isLoading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [domain, setDomain] = useState("");
    const [brandOrderMonitoringList, setBrandOrderMonitoringList] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editRecord, setEditRecord] = useState(null);
    const [showConfirmModalForDelete, setShowConfirmModalForDelete] = useState(false);
    const [recordToDelete, setRecordToDelete] = useState(null);
    const [params, setParams] = useState([]);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState("-id");
    const [clearall, setClearAll] = useState(false);
    const isMobile = useMobileDetection(mobileScreenThresholdValue);
    const [filter, setFilter] = useState({
        domains: []
    });
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });

    const { addToast } = useToasts();

    const handleDomainChange = e => {
        setDomain(e.target.value.replace(/\s+/g, ''));
    };

    const handleDomainPaste = e => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text').replace(/\s+/g, '');
        
        const input = e.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        
        const updatedText = input.value.slice(0, start) + pastedText + input.value.slice(end);
        
        setDomain(updatedText);
    
        // Move cursor to the end of the pasted text
        setTimeout(() => {
            input.setSelectionRange(start + pastedText.length, start + pastedText.length);
        }, 0);
    };

    const handleCreate = () => {
        // Reset domain when creating a new record
        setDomain("");
        setEditMode(false);
        setOpenModal(true);
    };

    const handleEdit = record => {
        setEditMode(true);
        setEditRecord(record);
        setDomain(record.domain);
        setOpenModal(true);
    };

    const handleDelete = async record => {
        setRecordToDelete(record);
        setShowConfirmModalForDelete(true);
    };

    const confirmDelete = async () => {
        setLoading(true);
        try {
            await api.delete("order/brand-order-monitoring", { params: { id: recordToDelete.id } });
            addToast("Record deleted successfully", { appearance: "success", autoDismiss: true });
            fetchRecords();
        } catch (err) {
            addToast("Failed to delete record", { appearance: "error", autoDismiss: true });
        } finally {
            setLoading(false);
            setShowConfirmModalForDelete(false);
            setRecordToDelete(null);
        }
    };

    const fetchRecords = () => {
        setLoading(true);
        const new_params = getParams(filter, setClearAll);
        api.get("order/brand-order-monitoring", { params: new_params })
            .then(response => {
                setBrandOrderMonitoringList(response?.data?.results);
                setLoading(false);
                const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                const previous = page !== 1 ? page - 1 : 1;
                const next = page !== totalPages ? page + 1 : totalPages;
                setPagination({
                    previous,
                    next,
                    pages: () => {
                        let startCount = 1;
                        let endCount = isMobile ? 4 : 12;
                        let numberCount = Math.round(endCount / 2);
                        const pageList = [];
                        if (numberCount < 0) numberCount = 1;
                        startCount = page - numberCount;
                        if (startCount <= 0) startCount = 1;
                        if (page !== 1) endCount = page + numberCount;
                        if (endCount > totalPages) endCount = totalPages;
                        if (totalPages >= endCount) {
                            for (let i = startCount; i <= endCount; i++) {
                                pageList.push(i);
                            }
                        } else if (totalPages >= 1) {
                            for (let i = 1; i <= totalPages; i++) {
                                pageList.push(i);
                            }
                        }
                        return pageList;
                    }
                });
                delete new_params["page"];
                delete new_params["ordering"];
                setParams(new_params);
            })
            .catch(err => {
                if (err.response.status === 403) addToast(err.response.data.detail, { appearance: "error", autoDismiss: true });
            });
    };

    const handleClearAll = () => {
        setClearAll(false);
        setFilter({
            domains: []
        });
        removeAllURLParams();
    };

    const handlePagination = page => {
        setPage(page);
    };

    const createRemoveBadgeWrapper = (filter, setFilter) => e => {
        removeBadge(
            e,
            filter,
            setFilter,
            () => {},
            () => {},
            "",
            () => {}
        );
    };

    useEffect(() => {
        fetchRecords();
    }, [sort, page, filter]);

    return (
        <>
            <Container fluid>
                <div className={"pt-7"}>
                    <Row className={"mb-2"}>
                        <Col xs={10} lg={"2"} className={"text-left"}></Col>
                        <Col xs={2} lg={"10"} className={"text-right"}>
                            <div className={"d-none d-lg-inline-block mr-2"}>
                                <Filter
                                    name={"Domains"}
                                    prefix={"domains"}
                                    onChange={e => onFilterChange(e, "domains", filter, setFilter)}
                                    newparams={params}
                                    selected={filter.domains}
                                    url={"order/brand-order-monitoring/domains"}
                                    inputSearch={true}
                                    onClear={() => clearFilter("domains", setFilter, setClearAll)}
                                />
                                <Button className="p-9px" color={"primary"} size={"sm"} onClick={handleCreate}>
                                    <i className="fas fa-file-csv"></i> Create
                                </Button>
                                <BrandOrderMonitoringCreateModal
                                    isOpen={openModal}
                                    toggle={() => {
                                        setOpenModal(false); // Close the modal
                                        setEditMode(false); // Reset edit mode
                                    }}
                                    loading={isLoading}
                                    domain={domain}
                                    onDomainChange={handleDomainChange}
                                    isEditMode={editMode}
                                    setEditMode={setEditMode}
                                    editRecord={editRecord}
                                    onSuccess={fetchRecords}
                                    handleDomainPaste={handleDomainPaste}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <div className="col">
                        <Card className="shadow ">
                            <CardHeader className="border-0">
                                <Row className="d-flex justify-content-between align-items-center p-1">
                                    <div className="d-flex">
                                        <h3 className="mb-0">
                                            Brand Order Monitoring
                                            {clearall && (
                                                <a
                                                    href={void 0}
                                                    className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                                    onClick={handleClearAll}
                                                    id="clear_all_filters">
                                                    <i className="fas fa-times-circle"></i> Clear All filters
                                                </a>
                                            )}
                                        </h3>
                                    </div>
                                    <Col sm="12">
                                        <BadgePillFilter data={filter} onClick={createRemoveBadgeWrapper(filter, setFilter)} />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" className="pl-5">
                                            Created at
                                        </th>
                                        <th scope="col" className="pl-5">
                                            Domain(s)
                                        </th>
                                        <th scope="col" className="pl-5">
                                            User
                                        </th>
                                        <th scope="col" className="pl-5 text-center">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {brandOrderMonitoringList.map(record => (
                                        <tr key={record.id}>
                                            <td className="pl-5">
                                                <Moment format="MM/D/YYYY" withTitle>
                                                    {record.created_at}
                                                </Moment>
                                            </td>
                                            <td className="pl-5">{record.domain}</td>
                                            <td className="pl-5">{record.user_full_name}</td>
                                            <td className="pl-5 text-center">
                                                <Button color="link" onClick={() => handleEdit(record)}>
                                                    <FaEdit />
                                                </Button>
                                                <Button color="link" onClick={() => handleDelete(record)} style={{ color: "red" }}>
                                                    <FaTrash />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <CardFooter className="text-center">
                                <nav aria-label="...">
                                    {pagination.pages().length > 1 && (
                                        <Pagination className="pagination justify-content-center mb-0" listClassName="justify-content-center mb-0">
                                            <PaginationItem className="">
                                                <PaginationLink href="#" onClick={e => handlePagination(pagination?.previous)} tabIndex="-1">
                                                    <i className="fas fa-angle-left" />
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            {pagination.pages().map((pageIndex, index) => (
                                                <PaginationItem key={index} className={page === pageIndex ? "active" : ""}>
                                                    <PaginationLink href="#" onClick={e => handlePagination(pageIndex)}>
                                                        {pageIndex}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))}
                                            <PaginationItem>
                                                <PaginationLink href="#" onClick={e => handlePagination(pagination?.next)}>
                                                    <i className="fas fa-angle-right" />
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    )}
                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
            <ConfirmDeleteModal
                isOpen={showConfirmModalForDelete}
                toggle={() => setShowConfirmModalForDelete(false)}
                onConfirm={confirmDelete}
                record={recordToDelete}
            />
        </>
    );
};
export default BrandOrderMonitoring;
