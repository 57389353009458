import React, { useContext, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { api } from "../../services/api";
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";

// 7001728363 - Void an order from shipping chargebacks

const VoidOrdersFromShippingChargebacksModalForm = props => {
    const [modal, setModal] = useState(props.isOpen);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    const toggle = () => {
        setModal(!modal);
    };

    const handleVoidOrders = e => {
        e.preventDefault();
        setLoading(true);
        const payload = {
            order_ids: props?.orders
        };
        api.put("order/shipping-chargebacks/void", payload)
            .then(response => {
                setModal(false);
                addToast("Orders successfully voided from the shipping chargeback table!", { appearance: "success", autoDismiss: true });
                setLoading(false);
                setTimeout(() => {
                    if (props?.onComplete) {
                        props?.onComplete();
                    }
                }, 2000);
            })
            .catch(error => addToast(error.message, { appearance: "error" }));
    };

    useEffect(() => {
        setModal(props.isOpen);
    }, [props.isOpen]);

    return (
        <>
            {modal && (
                <Modal isOpen={modal} toggle={toggle} onExit={props.onExit}>
                    <Form onSubmit={handleVoidOrders}>
                        <ModalHeader toggle={toggle} className="bg-primary text-white">
                            <span className="text-white h3">Void Orders From Shipping Chargebacks</span>
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label for="orders">
                                    This action will void the following ( {props?.orders?.length} ) orders from the shipping chargeback table:
                                </Label>
                                <ul
                                    style={{
                                        listStyleType: "disc",
                                        paddingLeft: "50px"
                                    }}>
                                    {props?.orders?.map((order, index) => (
                                        <li
                                            key={index}
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: "bold"
                                            }}>
                                            {order.title}
                                        </li>
                                    ))}
                                </ul>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={loading}>
                                Save
                                {loading && <Spinner size="sm" color="light" />}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            )}
        </>
    );
};
export default VoidOrdersFromShippingChargebacksModalForm;
