import React, { useState, useEffect } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Form,
    Label,
    Row,
    Col,
    Spinner,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown
} from "reactstrap";
import { MDBCol, MDBIcon } from "mdbreact";
import { useToasts } from "react-toast-notifications";
import { api } from "../../services/api";
import { stateListOptions } from "../../utils/constants";
import { getParams } from "utils/commonFunctions";

export const DropdownField = ({ label, selectedItem, itemsList, onSelect, placeholder, disabled, displayProperty = 'name' }) => {
    return (
        <Row className="mb-3">
            <Col style={{ display: "flex", flexDirection: "column" }}>
                <Label>{label}</Label>
                <UncontrolledDropdown disabled={disabled}>
                    <DropdownToggle
                        caret
                        className="btn text-primary"
                        style={disabled ? { width: "350px", backgroundColor: '#e9ecef', cursor: "no-drop" } : { width: "350px" }}>
                        {selectedItem || placeholder}
                    </DropdownToggle>
                    <DropdownMenu
                        className="dropdown-menu-arrow pre-scrollable"
                        styles={{
                            overflow: "auto",
                            maxHeight: "100",
                            width: "350px"
                        }}>
                        {itemsList.map((item, index) => (
                            <DropdownItem key={index} id={item?.id} onClick={() => onSelect(item[displayProperty], item.id)} style={{ width: "350px" }}>
                                {item[displayProperty]}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Col>
        </Row>
    );
};

export const ProductVariantSelector = ({
    data,
    setParamsFilter,
    paramsFilter,
    selectedProduct,
    setSelectedProduct,
    setData,
    isVariantSearchLoading,
    disabled
}) => {
    const [isLoading, setLoading] = useState(false);
    let timer = null;

    const handleNext = () => {
        if (data.next) {
            let results = data.results;
            setLoading(true);
            api.get(data.next)
                .then(response => {
                    const new_results = results.concat(response.data.results);
                    setData({
                        ...data,
                        results: new_results,
                        next: response.data.next,
                        previous: response.data.previous
                    });
                    setLoading(false);
                })
                .catch(error => console.log(error));
        }
    };

    const handleSearch = e => {
        // limpamos o timer
        clearTimeout(timer);
        // armazenamos o timer novamente
        timer = setTimeout(function () {
            setParamsFilter({
                ...paramsFilter,
                search: e.target.value
            });
        }, 750);
    };

    const handleVariantProductCheckbox = (e, variant_id) => {
        // If the clicked variant is already selected, deselect it by setting selectedProduct to null.
        // Otherwise, set selectedProduct to the id of the clicked variant.
        if (selectedProduct === variant_id) {
            setSelectedProduct(null); // Deselect the variant
        } else {
            setSelectedProduct(variant_id); // Select the new variant
        }
    };

    return (
        <>
            <Label>Variant *</Label>
            <MDBCol style={{ display: "-webkit-box" }}>
                <MDBIcon icon="search" className="mr-2 ml-0" />
                <input
                    className="form-control form-control-sm"
                    onKeyDown={handleSearch}
                    type="text"
                    placeholder="Search"
                    aria-label="Search"
                    disabled={disabled}
                    style={disabled ? { cursor: "no-drop" } : {}}
                />
            </MDBCol>
            <small className="ml-5" style={{ color: "#5F93F8" }}>
                Only one variant can be selected
            </small>
            <ul
                className={"p-2 overflow-auto"}
                style={disabled ? { maxHeight: "300px", backgroundColor: '#e9ecef', cursor: "no-drop" } : { maxHeight: "300px" }}
                id="filter_">
                {isVariantSearchLoading && (
                    <div className="text-center mt-3 mb-3">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {!isVariantSearchLoading && data.results.length === 0 && (
                    <li className={"text-center"}>
                        <span className={"text-sm text-muted"}>No Records Found</span>
                    </li>
                )}
                {!isVariantSearchLoading &&
                    data.results.map(product => (
                        <div key={product.id + "_prod_"}>
                            <div key={product.id + "_product_"} className="row my-2 d-flex align-items-center" style={{ width: "40rem" }}>
                                <div className="col-2 pr-0">
                                    <Label className="text-sm">
                                        <input
                                            type="checkbox"
                                            id={"_" + product.id}
                                            className="checkbox_product"
                                            value={product.id}
                                            data-label={product.name}
                                            data-image={product.image}
                                            defaultChecked={product.checked}
                                            disabled={true}
                                            style={{ marginRight: "20px" }}
                                        />
                                    </Label>
                                    {product.image ? (
                                        <img src={product.image} className="img-thumbnail rounded-0" width="50%" alt={product.name} />
                                    ) : (
                                        <img
                                            src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                                            className="img-thumbnail rounded-0"
                                            width="50%"
                                            alt="default-product-img"
                                        />
                                    )}
                                </div>
                                <div className="col-10">
                                    <a
                                        href={window.env.APP_URL + "product/" + product.id}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={disabled ? { cursor: "no-drop" } : { cursor: "pointer" }}>
                                        <span className="text-wrap">{product.name}</span>
                                    </a>
                                </div>
                            </div>
                            {product.variants &&
                                product.variants.map((variant, index) => (
                                    <div className="row sub-product ml-3" key={index + "_variant_"}>
                                        <div className="col-2 pr-0 variant-container">
                                            <Label className="text-sm">
                                                <input
                                                    type="checkbox"
                                                    id={"_" + variant.id}
                                                    className={"variant_" + product.id}
                                                    value={variant.id}
                                                    data-label={variant.sku}
                                                    data-image={variant.image}
                                                    checked={selectedProduct === variant.id}
                                                    onChange={e => handleVariantProductCheckbox(e, variant.id)}
                                                    style={disabled ? { cursor: "no-drop", marginRight: "20px" } : { marginRight: "20px" }}
                                                    disabled={disabled}
                                                />
                                            </Label>
                                            <img
                                                src={variant.image || "https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"}
                                                className="img-thumbnail rounded-0"
                                                width="50%"
                                                alt={variant.sku}
                                            />
                                        </div>
                                        <a href="#!" style={disabled ? { cursor: "no-drop" } : { cursor: "pointer" }}>
                                            {variant?.size_title === null ? (
                                                <span className="text-wrap">{`${variant.sku}`}</span>
                                            ) : (
                                                <span className="text-wrap">{`${variant.size_title} - ${variant.sku}`}</span>
                                            )}
                                        </a>
                                    </div>
                                ))}
                        </div>
                    ))}

                {!isVariantSearchLoading && data.next && (
                    <li className={"text-center"}>
                        {!isLoading ? (
                            <Button className={"btn btn-sm "} onClick={() => handleNext("parent")}>
                                Show More ...
                            </Button>
                        ) : (
                            <span className={"text-sm text-muted"}>Loading...</span>
                        )}
                    </li>
                )}
            </ul>
        </>
    );
};

export function BulkEditRetailerAssignmentOverrides({
    toggle,
    url,
    isOpen,
    retailerOverrideList,
    totalOrdersCount,
    selectAllRetailerOverridesInThisView,
    updateInventoryLocations,
    handleRefresh,
    filters,
    onComplete
}) {
    const [isLoading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(isOpen);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [data, setData] = useState({ results: [], count: 0, next: null, previous: null });
    const [paramsFilter, setParamsFilter] = useState({});
    const [state, setState] = useState("");
    const [inventoryLocationId, setInventoryLocationId] = useState(null);
    const [inventoryLocationName, setInventoryLocationName] = useState(null);
    const [inventoryLocationList, setInventoryLocationList] = useState([]);
    const [orderChannel, setOrderChannel] = useState("");
    const [channelId, setChannelId] = useState("");
    const [orderChannelList, setOrderChannelList] = useState([]);
    const { addToast } = useToasts();
    // Add a new state for search variant loading
    const [isVariantSearchLoading, setIsVariantSearchLoading] = useState(false);
    // Add a new state for as_is flag to set state, sales channel, variant value as it is
    const [asIsFlag, setAsIsFlag] = useState(false);
    // Add a new state for error handling
    const [errors, setErrors] = useState({});

    const transformedStateListOptions = stateListOptions
        .filter(option => option.value !== "__all__")
        .map(option => ({
            name: option.label,
            id: option.value
        }));

    // API call to fetch Order Channel List
    const fetchOrderChannelList = async () => {
        try {
            const response = await api.get(`order/retailer-billing-overrides-channel-list/`);
            if (response.status === 200) {
                setOrderChannelList([{ name: "Null", channel_store: "None", id: "null" }, ...response.data]);
            }
        } catch (err) {
            console.error("Failed to fetch Order Channel List", err);
        }
    };

    // API call to fetch Inventory Location List
    const fetchInventoryLocationList = async () => {
        try {
            const response = await api.get(`inventory-location-list/`);
            if (response.status === 200) {
                setInventoryLocationList(response.data);
            }
        } catch (err) {
            console.error("Failed to fetch Inventory Location List", err);
        }
    };

    const onCancelForm = () => {
        setState("");
        setInventoryLocationId(null);
        setInventoryLocationName(null);
        setChannelId("");
        setOrderChannel("");
        setSelectedProduct(null);
        toggle();
        setLoading(false);
        setAsIsFlag(false);
        setParamsFilter({...paramsFilter, search: ""});
        setErrors({});
    };

    useEffect(() => {
        setShowModal(isOpen);
        if (isOpen) {
            setState("");
            setInventoryLocationId(null);
            setInventoryLocationName(null);
            setChannelId("");
            setOrderChannel("");
            setSelectedProduct(null);
            setErrors({});
        }
    }, [isOpen]);

    const handlePayoutsShippingCheckbox = e => {
        if (e.target.checked) {
            setAsIsFlag(true);
            setState("");
            setChannelId("");
            setOrderChannel("");
            setSelectedProduct(null);
        } else {
            setAsIsFlag(false);
        }
        // Clear the form error related to State, Sales Channel, or Variant
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors.form;
            return newErrors;
        });
    };

    const handleSubmitForm = async e => {
        e.preventDefault();
        setLoading(true);
        
        // Initialize a new errors object
        const newErrors = {};

        // Check if either state, sales channel, and variant are selected or asIsFlag is checked
        const missingRequiredFields = !state || !channelId || !selectedProduct;
        const allFieldsMissing = missingRequiredFields && !asIsFlag;

        if (!inventoryLocationId) {
            addToast("Inventory Location must be selected", { appearance: "error", autoDismiss: true });
            newErrors.inventoryLocation = "Inventory Location must be selected";
            setLoading(false);
            // return; // Exit the function early
        }

        if (allFieldsMissing) {
            addToast("Either Select State, Sales Channel, Variant or select the Checkbox", { appearance: "error", autoDismiss: true });
            newErrors.form = "Either Select State, Sales Channel, Variant or select the Checkbox";
            setLoading(false);
            // return; // Exit the function early
        }

        // Update state with errors and stop execution if there are any
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setLoading(false);
            return; // Exit the function early
        }

        let retailerOverridePageParams = getParams(filters);

        const retailerOverrideIds = retailerOverrideList.map((item) => item.id);

        let payloadBulkUpdateData = {
            id: retailerOverrideList ? retailerOverrideIds : [],
            state: state,
            sales_channel: channelId,
            inventory_location: inventoryLocationName,
            variant: selectedProduct,
            as_is: asIsFlag,
            select_all_in_this_view: selectAllRetailerOverridesInThisView,
            overrides_count_on_ui: totalOrdersCount,
            params: retailerOverridePageParams
        };

        // Remove optional fields if as_is is true
        if (!asIsFlag) {
            payloadBulkUpdateData.state = state;
            payloadBulkUpdateData.sales_channel = channelId;
            payloadBulkUpdateData.variant = selectedProduct;
        } else {
            delete payloadBulkUpdateData.state;
            delete payloadBulkUpdateData.sales_channel;
            delete payloadBulkUpdateData.variant;
        }

        console.log("payloadBulkUpdateData", payloadBulkUpdateData);

        console.log("INVENTORY LOCATIONS", {
            retailer_override_list: retailerOverrideList,
            inventory_location_id: inventoryLocationId,
            select_all_retailer_overrides_in_this_view : selectAllRetailerOverridesInThisView,
            retailer_override_count_on_ui : totalOrdersCount,
            params: retailerOverridePageParams
        })

        // If user wants to keep sales channel, state and variant value as it is and wants to update Inventory Location
        if (asIsFlag) {
            try {
                await api.patch("retailer-override/", {
                    retailer_override_list: retailerOverrideList,
                    inventory_location_id: inventoryLocationId,
                    select_all_retailer_overrides_in_this_view : selectAllRetailerOverridesInThisView,
                    retailer_override_count_on_ui : totalOrdersCount,
                    params: retailerOverridePageParams
                }).then((response) => {
                    setLoading(false);
                    toggle();
                    setInventoryLocationId(null);
                    setInventoryLocationName(null);
                    if (response.status === 200 && response.data?.success) {
                        addToast(response.data.message, {
                            appearance: "success",
                            autoDismiss: true,
                        });
                        updateInventoryLocations(retailerOverrideIds);
                        if (onComplete) {
                            onComplete();
                        }
                        // window.location.reload(true);
                    } else {
                        addToast(
                            response?.error ?? "Something went wrong, please try again!!",
                            {
                            appearance: "error",
                            autoDismiss: true,
                            }
                        );
                    }
                })
            } catch (err) {
                console.error(err);
                addToast("Something went wrong, please try again!", {
                    appearance: "error",
                    autoDismiss: true,
                    autoDismissTimeout: 20000
                });
            } finally {
                setLoading(false);
                onCancelForm();
                toggle();
            }
        }
        // If user wants to update sales channel, state, variant and Inventory Location
        else {
            try {
                const response = await api.patch(`retailer-override-ui/`,
                    payloadBulkUpdateData
                );

                if ([200, 201].includes(response.status)) {
                    addToast(response.data.message, {
                        appearance: "success",
                        autoDismiss: true,
                        autoDismissTimeout: 20000
                    });
                    handleRefresh();
                } else {
                    // Handle unexpected status codes
                    addToast("Unexpected response from the server, please try again.", {
                        appearance: "warning",
                        autoDismiss: true,
                        autoDismissTimeout: 20000
                    });
                }
            } catch (err) {
                console.error(err);
                addToast("Something went wrong, please try again!", {
                    appearance: "error",
                    autoDismiss: true,
                    autoDismissTimeout: 20000
                });
            } finally {
                setLoading(false);
                onCancelForm();
                toggle();
            }
        }
    };

    // Load initial data
    useEffect(() => {
        fetchOrderChannelList();
        fetchInventoryLocationList();
        // Fetch products based on paramsFilter
        if (paramsFilter) {
            setIsVariantSearchLoading(true);
            const fetchData = async () => {
                try {
                    const response = await api.get(url, { params: paramsFilter });
                    setData(response.data);
                } catch (error) {
                    console.error("Error fetching data", error);
                } finally {
                    setIsVariantSearchLoading(false);
                }
            };
            fetchData();
        }
    }, [paramsFilter, url]);

    const handleSelectInventoryLocation = (value, id) => {
        console.log('id: ', id);
        console.log('value: ', value);
        setInventoryLocationName(value);
        setInventoryLocationId(id);
        
        // Clear the error for inventory location
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors.inventoryLocation;
            return newErrors;
        });
    };

    const handleSelectState = (value) => {
        setState(value);
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors.form;
            return newErrors;
        });
    };

    const handleSelectSalesChannel = (value, id) => {
        setOrderChannel(value);
        setChannelId(id);
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors.form;
            return newErrors;
        });
    };

    const handleSelectVariant = (variant_id) => {
        console.log('variant_id: ', variant_id);
        setSelectedProduct(variant_id);
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors.form;
            return newErrors;
        });
    };

    
    return (
        <Modal isOpen={showModal} toggle={() => toggle()} onExit={() => onCancelForm()} size="lg" style={{ marginTop: "4.69rem" }}>
            <Form onSubmit={handleSubmitForm}>
                <ModalHeader toggle={toggle} className="bg-primary text-white">
                    <span className="text-white h3">Bulk Edit Retailer Assignment Overrides</span>
                </ModalHeader>
                <ModalBody style={{ paddingBottom: 0 }}>
                    <FormGroup>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div>
                                <DropdownField
                                    label="Inventory Location *"
                                    selectedItem={inventoryLocationName}
                                    itemsList={inventoryLocationList}
                                    // onSelect={(value, id) => {setInventoryLocationId(id); setInventoryLocationName(value)}}
                                    onSelect={(value, id) => handleSelectInventoryLocation(value, id)}
                                    placeholder="Select Inventory Location"
                                />
                                {errors?.inventoryLocation && <div className="text-danger">{errors?.inventoryLocation}</div>}
                            </div>
                            <DropdownField
                                label="State *"
                                selectedItem={state}
                                itemsList={transformedStateListOptions}
                                onSelect={handleSelectState}
                                disabled={asIsFlag}
                                placeholder="Select State"
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "2.1rem" }}>
                            <DropdownField
                                label="Sales Channel *"
                                selectedItem={orderChannel}
                                itemsList={orderChannelList}
                                onSelect={(value, id) => {handleSelectSalesChannel(value, id)}}
                                disabled={asIsFlag}
                                placeholder="Select Sales Channel"
                                displayProperty="channel_store"
                            />
                            <div>
                                <input type="checkbox" name={"check_as_it_is"} id={"check_as_it_is"} onChange={handlePayoutsShippingCheckbox} />
                                &nbsp;
                                <span>Keep State, Sales Channel and Variant value as it is</span>
                            </div>
                        </div>
                        {errors?.form && <div className="text-danger">{errors?.form}</div>}
                        <ProductVariantSelector
                            data={data}
                            setData={setData}
                            paramsFilter={paramsFilter}
                            setParamsFilter={setParamsFilter}
                            selectedProduct={selectedProduct}
                            setSelectedProduct={handleSelectVariant}
                            isVariantSearchLoading={isVariantSearchLoading}
                            disabled={asIsFlag}
                        />
                    </FormGroup>
                    {selectAllRetailerOverridesInThisView && (
                        <>
                            <h5 className="text-danger fw-500">This action will affect {totalOrdersCount} Retailer Overrides in current view</h5>
                        </>
                    )}
                </ModalBody>
                <ModalFooter className="mt-0 pt-0">
                    <Button color="secondary" onClick={() => onCancelForm()}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit">
                        Update {isLoading && <Spinner size="sm" color="light" />}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}
