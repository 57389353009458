import React, { useContext, useEffect, useRef, useState } from "react";
import { api } from "../../services/api";
import { ComponentContext } from "../../contexts/ComponentContext";
import { AuthContext } from "../../contexts/AuthContext";
import BadgePillFilter from "components/Filters/Badge";
import moment from "moment";
import Moment from 'react-moment';
import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Col,
    Button,
    UncontrolledTooltip,
    ModalHeader,
    Modal,
    ModalBody
} from "reactstrap";
import Filter from 'components/Filters/Filter';
import { NavLink, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { addURLParam, processURLSearchParams, removeAllURLParams, removeURLParam } from "utils/urls";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import { Sort, handleSort } from "../../utils/commonFunctions";
import "../../utils/commonStyles.css";

const MarketingCampaignListing = () => {
    const urlSearchParams = useLocation().search;

    let name = useQuery().get("name");

    const [isLoading, setLoading] = useState(false);
    const [marketingCampaigns, setMarketingCampaigns] = useState();
    const [totalCampaigns, setTotalCampaigns] = useState();
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        },
    });
    const { user, isAuthentication } = useContext(AuthContext);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState("-id");
    const [sortOrder, setSortOrder] = useState({order: "", columnName: ""});
    const [params, setParams] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [clearall, setClearAll] = useState(false);
    const [filter, setFilter] = useState({
        search: name,
        brands: [],
        products: [],
        urlParamsProcessed: false,
    });
    const [openArchiveModal, setOpenArchiveModal] = useState(false);
    const [campaignName, setCampaignName] = useState();
    const [campaignId, setCampaignId] = useState();
    const [workflow, setWorkflow] = useState();
    const { component } = useContext(ComponentContext);

    const { addToast } = useToasts();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    };

    React.useMemo(() => {
        processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter);
    }, [urlSearchParams]);

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    const getParams = () => {
        let new_params = {
            page,
            sorted: sort,
        };

        if (filter.search?.length > 0) {
            new_params.search = filter.search;
            setClearAll(true);
        }

        if (filter?.brands.length > 0) {
            new_params.brand = [];
            filter.brands.map(item => new_params.brand.push(item.value));
            setClearAll(true);
        }
        if (filter?.products.length > 0) {
            new_params.product = [];
            filter.products.map((item) => new_params.product.push(item.value));
            setClearAll(true);
        }

        setClearAll(filter?.search?.length > 0 || filter?.brands?.length > 0 || filter?.products?.length > 0);
        setParams(new_params);
        return new_params;

    };

    // const { component } = useContext(ComponentContext);

    let timer = null;
    const handleSearch = (e) => {
        setPage(1);
        addURLParam(window, "search", e.target.value);
        clearTimeout(timer);
        timer = setTimeout(function () {
            setFilter({
                ...filter,
                search: e.target.value
            })
        }, 750);
    };

    const handlePagination = (page) => {
        setPage(page);
    };

    /**
     * Function for Brands Filter
     * @param {*} e 
     */
    const handleBrands = (e, prefix) => {
        setPage(1);
        let checkbox = [...new Set(filter.brands)];
        let filteredRecords = [...new Set(filter[`${prefix}`])];

        if (e.target.checked) {
            filteredRecords.push({ value: e.target.value, label: e.target.dataset.label });
            addURLParam(window, prefix, e.target.value);
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
        } else {
            filteredRecords = filteredRecords.filter(item => item.value !== e.target.value);
            removeURLParam(window, prefix, e.target.value);
            checkbox = checkbox.filter(item => item.value !== e.target.value);
        }
        let updatedFilter = { ...filter };
        updatedFilter[`${prefix}`] = filteredRecords;

        setFilter({ ...filter, brands: checkbox });
    }

    /**
     * Function for Products Filter
     * @param {*} e 
     */
    const handleProducts = (e, prefix) => {
        setPage(1);
        let checkbox = [...new Set(filter.products)];
        let filteredRecords = [...new Set(filter[`${prefix}`])];

        if (e.target.checked) {
            filteredRecords.push({ value: e.target.value, label: e.target.dataset.label });
            addURLParam(window, prefix, e.target.value);
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
        } else {
            filteredRecords = filteredRecords.filter(item => item.value !== e.target.value);
            removeURLParam(window, prefix, e.target.value);
            checkbox = checkbox.filter((item) => item.value !== e.target.value);
        }
        let updatedFilter = { ...filter };
        updatedFilter[`${prefix}`] = filteredRecords;

        setFilter({ ...filter, products: checkbox });
    };

    /**
     * Function to handle Refresh
     */
    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    /**
     * Function for clearing all filters
     */
    const handleClearAll = () => {
        setClearAll(false);
        window.history.replaceState({}, "", `${window.location.pathname}`);
        document.getElementById("marketing-page-search-input").value = "";
        setFilter({
            search: null,
            brands: [],
            products: [],
            urlParamsProcessed: true,
        });
        removeAllURLParams();
    };

    /**
     * Handler for approving campaigns
     * @param {*} id 
     * @param {*} workflow 
     */
    const handleApprove = (id, workflow) => {
        api({
            method: 'patch',
            url: "marketing/campaign/approveoption/",
            params: { id: id, workflow: workflow }
        }).then((response) => {
            addToast("Campaign approved successfully!", { appearance: "success", autoDismiss: true });
        }).catch((error) => {
            let message = "Something went wrong, please try again!!";
            if (error?.response?.data?.error) {
                message = error.response.data.error;
            };
            addToast(message, { appearance: "error" });
        });

    }

    /**
     *  Handler for archiving campaigns
     * @param {*} id 
     */
    const handleArchive = (id) => {
        api({
            method: 'patch',
            url: "marketing/campaign/archiveoption/",
            params: { id: id }
        }).then((response) => {
            addToast("Campaign archived successfully!", { appearance: "success", autoDismiss: true });
            setOpenArchiveModal(!openArchiveModal);
        }).catch((error) => {
            let message = "Something went wrong, please try again!!";
            if (error?.response?.data?.error) {
                message = error.response.data.error;
            };
            addToast(message, { appearance: "error" });
        });
    }

    const toggleArchiveModal = () => {
        setOpenArchiveModal(!openArchiveModal);
    }

    const handleArchiveModal = (id, name) => {
        toggleArchiveModal();
        setCampaignName(name);
        setCampaignId(id);
    }

    /**
     * Handler for deleting campaigns
     * @param {*} id 
     */
    const handleDelete = (id) => {
        api({
            method: 'delete',
            url: 'marketing/campaign/deleteoption/',
            params: { id: id }
        }).then((response) => {
            addToast("Campaign deleted successfully!", { appearance: "success", autoDismiss: true });
        }).catch((error) => {
            let message = "Something went wrong, please try again!!";
            if (error?.response?.data?.error) {
                message = error.response.data.error;
            };
            addToast(message, { appearance: "error" });
        });
    }

    /**
     * useEffect for calling listing API
     */
    useEffect(() => {
        if (filter.urlParamsProcessed) {
            setLoading(true);

            const new_params = getParams();
            api
                .get("marketing/campaign", {
                    params: new_params
                })
                .then((response) => {
                    setMarketingCampaigns(response);
                    setTotalCampaigns(response.data.count);

                    const totalPages = Math.ceil(
                        response.data.count / window.env.PAGE_SIZE
                    );
                    const previous = page !== 1 ? page - 1 : 1;
                    const next = page !== totalPages ? page + 1 : totalPages;
                    setPagination({
                        previous,
                        next,
                        pages: () => {
                            let startCount = 1;
                            let endCount = isMobile ? 4 : 12;;
                            let numberCount = Math.round(endCount / 2);
                            const pageList = [];
                            if (numberCount < 0) {
                                numberCount = 1;
                            }

                            startCount = page - numberCount;

                            if (startCount <= 0) {
                                startCount = 1;
                            }
                            if (page !== 1) {
                                endCount = page + numberCount;
                            }

                            if (totalPages >= endCount) {
                                for (let i = startCount; i <= endCount; i++) {
                                    pageList.push(i);
                                }
                            } else if (totalPages >= 1) {
                                for (let i = 1; i <= totalPages; i++) {
                                    pageList.push(i);
                                }
                            }
                            return pageList;
                        },
                    });
                    setLoading(false);
                    delete new_params["page"];
                    delete new_params["sorted"];
                    setParams(new_params);
                })
                .catch((error) => console.log(error));
        }
    }, [page, refresh, filter, sort, component]);

    const removeBadge = (e) => {
        if (e.component === "search") {
            setFilter({ ...filter, search: null });
            document.getElementById("marketing-page-search-input").value = "";
            removeURLParam(window, "search")
        }
        else if (e.component === 'brand') {
            let items = filter.brands;
            items = items.filter(item => item.value !== e.item.value);
            setFilter({ ...filter, brands: items });
            removeURLParam(window, "brands", e.item.value);
        } else if (e.component === "product") {
            let items = filter.products;
            items = items.filter((item) => item.value !== e.item.value);
            setFilter({ ...filter, products: items });
            removeURLParam(window, "products", e.item.value);
        }
        else if (e.component == "selected") {
            let items = selected;
            items = items.filter((item) => item.id !== e.item.id);
            if (e.component === 'brand') {
                let items = filter.brands;
                items = items.filter(item => item.value !== e.item.value);
                setFilter({ ...filter, brands: items });
                removeURLParam(window, "brands", e.item.value);
            } else if (e.component === 'product') {
                let items = filter.products;
                items = items.filter(item => item.value !== e.item.value);
                setFilter({ ...filter, products: items });
                removeURLParam(window, "products", e.item.value);
            }
            let pathName = window.location.search.toString();
            if (pathName.startsWith("?name=")) {
                window.location.assign("/");
            }
        }
        setClearAll(false);
    };

    const clearFilter = (prefix) => {
        if (prefix === "brand") {
            setFilter({
                ...filter, brands: []
            });
        } else if (prefix === "product") {
            setFilter({
                ...filter,
                products: [],
            });
        }

        let checkbox = document.querySelectorAll('.checkbox_' + prefix + ':checked');
        if (checkbox.length > 0) {
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = false;
            }
        }
        setClearAll(false);
    };


    useEffect(() => {
        marketingCampaigns?.data?.results?.map((campaigns) => {
            setWorkflow(campaigns.workflow);
        })
    }, [])

    return (
        <>
            <Container fluid>
                <div className={"pt-7"}>
                    {isAuthentication && (
                        <Row className={"mr-n4"}>
                            <Col xs={10} lg={"2"} className={"text-left"}>
                                <input
                                    type="text"
                                    id="marketing-page-search-input"
                                    className={"button-font-size form-control form-control-sm"}
                                    placeholder={"Campaign Name"}
                                    defaultValue={filter.search}
                                    onKeyDown={handleSearch}
                                    newparams={""}
                                />
                            </Col>
                            <Col xs={2} lg={"10"} className={"text-right"}>
                                <div className={"d-none d-lg-inline-block mr-1"}>
                                    <button
                                        className="button-font-size btn btn-neutral btn-sm p-9px"
                                        onClick={handleRefresh}
                                    >
                                        <i className="fas fa-sync-alt"></i>
                                    </button>
                                    <Filter
                                        name={"Products"}
                                        prefix={"products"}
                                        onChange={handleProducts}
                                        newparams={params}
                                        selected={filter.products}
                                        url={"marketing/campaign/filters/products"}
                                        onClear={() => clearFilter("product")}
                                    />
                                    <Filter
                                        name={"Brands"}
                                        prefix={"brands"}
                                        onChange={handleBrands}
                                        newparams={params}
                                        selected={filter.brands}
                                        url={"marketing/campaign/filters/brands"}
                                        onClear={() => clearFilter("brand")}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <div className="col">
                            <Card className="shadow mt-3">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col style={{ display: "flex" }}>
                                            <h3 className="mb-0">
                                                Campaigns <small>({totalCampaigns})</small>
                                            </h3>
                                            {clearall &&
                                                <a href={void (0)}
                                                    className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                                    onClick={handleClearAll}
                                                    id="clear_all_filters">
                                                    <i className="fas fa-times-circle"></i> Clear All filters
                                                </a>
                                            }
                                            <Col className="text-right">
                                                <NavLink to={"/create-marketing-campaign"} style={{ cursor: "pointer" }}>
                                                    <button className="button-font-size btn btn-success btn-sm">
                                                        <i className="fas fa-plus" />
                                                        New Campaign
                                                    </button>
                                                </NavLink>
                                            </Col>

                                        </Col>
                                    </Row>
                                    <Col sm="12">
                                        <BadgePillFilter
                                            data={filter}
                                            selected={selected}
                                            onClick={removeBadge}
                                        />
                                    </Col>
                                </CardHeader>
                                {isLoading ? (<div className="text-center mt-3 mb-3">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                ) : (
                                    <>
                                        <Table
                                            className="align-items-center table-flush"
                                            responsive
                                        >
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col" className="pl-4 pr-1" style={{ width: 420 }}>
                                                        <Sort onClick={() => handleSort(sort, "name", setSortOrder, setSort)} sortingOrder={sortOrder} column="name">
                                                            Name
                                                        </Sort>
                                                    </th>
                                                    <th className="pl-5">
                                                        Type
                                                    </th>
                                                    <th className="pr-1">
                                                        <Sort onClick={() => handleSort(sort, "budget", setSortOrder, setSort)} sortingOrder={sortOrder} column="budget">
                                                            Budget
                                                        </Sort>
                                                    </th>
                                                    <th className="pl-1 pr-1">
                                                        <Sort onClick={() => handleSort(sort, "start_date", setSortOrder, setSort)} sortingOrder={sortOrder} column="start_date">
                                                            Starts At
                                                        </Sort>
                                                    </th>
                                                    <th className="pl-1">
                                                        <Sort onClick={() => handleSort(sort, "end_date", setSortOrder, setSort)} sortingOrder={sortOrder} column="end_date">
                                                            Ends At
                                                        </Sort>
                                                    </th>
                                                    <th className="pl-1" style={{ width: 20 }}>
                                                        Owner
                                                    </th>
                                                    <th className="pl-1">
                                                        <Sort onClick={() => handleSort(sort, "workflow", setSortOrder, setSort)} sortingOrder={sortOrder} column="workflow">
                                                            Status
                                                        </Sort>
                                                    </th>
                                                    <th className="pl-1">
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {marketingCampaigns?.data?.results?.map((campaigns) => (
                                                    <tr key={campaigns.id}>
                                                        <td className="pl-4 pr-1">
                                                            <a href={
                                                                window.env.APP_URL + "marketing/campaign/detail/" + campaigns.id
                                                            } className="btn btn-link p-0">
                                                                {campaigns.name}
                                                            </a>
                                                        </td>
                                                        <td className="pl-5">
                                                            {campaigns.type ? campaigns.type : "-"}
                                                        </td>
                                                        <td className="pr-1">
                                                            {campaigns.budget}
                                                        </td>
                                                        <td className="pl-1 pr-1">
                                                            {campaigns.start_date ?
                                                                <Moment format="MMM. DD, YYYY" withTitle>
                                                                    {campaigns.start_date}
                                                                </Moment>
                                                                : "-"
                                                            }
                                                        </td>
                                                        <td className="pl-1 pr-1">
                                                            {campaigns.end_date ?
                                                                <Moment format="MMM. DD, YYYY" withTitle>
                                                                    {campaigns.end_date}
                                                                </Moment>
                                                                : "-"
                                                            }
                                                        </td>
                                                        <td className="pl-1 pr-1">
                                                            {campaigns.owner_name}
                                                        </td>
                                                        <td className="pl-1 pr-1">
                                                            {campaigns.workflow == "review" ?
                                                                <>
                                                                    <i id={`reviewWorkflow_${campaigns.id}`} class="fas text-yellow fa-exclamation-circle text-lg" />
                                                                    <UncontrolledTooltip
                                                                        delay={0}
                                                                        placement="bottom"
                                                                        target={`reviewWorkflow_${campaigns.id}`}
                                                                    >
                                                                        "Your campaign is under review. If approved, your card on file will be charged."
                                                                    </UncontrolledTooltip>
                                                                </>
                                                                :
                                                                <>
                                                                    <i class="fas fa-check-circle text-success text-lg" id={`liveWorkflow_${campaigns.id}`} />
                                                                    <UncontrolledTooltip
                                                                        delay={0}
                                                                        placement="bottom"
                                                                        target={`liveWorkflow_${campaigns.id}`}
                                                                    >
                                                                        "Your Campaign is live now."
                                                                    </UncontrolledTooltip>
                                                                </>
                                                            }
                                                        </td>
                                                        {user.isAdmin &&
                                                            <td>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle
                                                                        className="btn text-primary"
                                                                        role="button"
                                                                        size="sm"
                                                                        color="white"
                                                                        onClick={(e) => e.preventDefault()}
                                                                    >
                                                                        <i className="fas fa-ellipsis-v text-dark" />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu
                                                                        right
                                                                        positionFixed={true}
                                                                        className="dropdown-menu-arrow"
                                                                        modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                                                                    >
                                                                        {campaigns.workflow == "review" ?
                                                                            <>
                                                                                <DropdownItem
                                                                                    onClick={(e) => handleApprove(campaigns.id, campaigns.workflow)}
                                                                                >
                                                                                    <i className="fas fa-check" /> Approve
                                                                                </DropdownItem>
                                                                                <DropdownItem onClick={(e) => handleArchiveModal(campaigns.id, campaigns.name)}>
                                                                                    <i className="fas fa-archive" /> Archive
                                                                                </DropdownItem>
                                                                                <DropdownItem style={{ color: "red" }} onClick={(e) => handleDelete(campaigns.id)}>
                                                                                    <i className="fas fa-trash" /> Delete
                                                                                </DropdownItem>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <DropdownItem onClick={(e) => handleArchiveModal(campaigns.id, campaigns.name)}>
                                                                                    <i className="fas fa-archive" /> Archive
                                                                                </DropdownItem>
                                                                                <DropdownItem style={{ color: "red" }} onClick={(e) => handleDelete(campaigns.id)}>
                                                                                    <i className="fas fa-trash" /> Delete
                                                                                </DropdownItem>
                                                                            </>
                                                                        }
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>
                                                        }
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <Modal isOpen={openArchiveModal} size={"sm"} toggle={() => toggleArchiveModal}
                                        >
                                            <ModalHeader style={{ backgroundColor: 'red' }}>
                                                <span className="text-white h3">Confirm</span>
                                            </ModalHeader>
                                            <ModalBody>
                                                Are you sure you want to archive campaign "{campaignName}"
                                                <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
                                                    <button className='btn btn-primary btn-sm mr-1' style={{ flex: 1, marginLeft: 175 }} onClick={toggleArchiveModal} >
                                                        Cancel
                                                    </button>
                                                    <button className="btn btn-danger btn-sm mr-1" style={{ flex: 1 }} onClick={() => handleArchive(campaignId)} >
                                                        Archive
                                                    </button>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                        <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={totalCampaigns} />
                                    </>
                                )}
                            </Card>
                        </div>
                    </Row>
                </div>
            </Container>
        </>
    )


}

export default MarketingCampaignListing;