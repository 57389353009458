import React, { useState } from "react";
import styles from "./orderDetail.module.css";
import { Table, Tooltip } from "reactstrap";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import Moment from "react-moment";

const OrderSummary = ({ orderDetails }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const { discount_code, discount_amount, subtotal, shipping, retailer_fee, taxes, total, shipping_method } = orderDetails?.order?.order_price_info;
    return (
        <div className={styles.cardConatiner}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                    <div>
                        <h3 style={{ marginBottom: "0px" }}>Order Detail - {orderDetails?.order?.order_info?.name}</h3>
                    </div>
                    <div style={{ marginTop: "-8px" }}>
                        {" "}
                        <span style={{ fontSize: "12px" }}>
                            <Moment format="M/D/Y h:mm:ssa" withTitle>
                                {orderDetails?.order?.order_info?.shopify_created_at}
                            </Moment>
                        </span>
                    </div>
                </div>
                <div className={`badge badge-${orderDetails?.order?.order_info?.financial_status === "paid" ? "success" : "danger"}`}>
                    {orderDetails?.order?.order_info?.financial_status} -{" "}
                    {orderDetails?.order?.order_info?.payment_method === "shopify_payments"
                        ? "Mash&Grape_payments"
                        : orderDetails?.order?.order_info?.payment_method}
                </div>
            </div>
            <hr />
            <div>
                <div>
                    <Table responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                    PRODUCT{" "}
                                    <BsFillQuestionCircleFill
                                        color="#3c71ff"
                                        id="productTooltip"
                                        style={{ marginLeft: "5px", width: "17px", height: "17px" }}
                                    />
                                    <Tooltip target="productTooltip" isOpen={tooltipOpen} toggle={toggle} placement="right">
                                        Click on the product name to see more details about that product, including distribution states.
                                    </Tooltip>
                                </th>
                                <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                    FULFILLED
                                </th>
                                <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                    BRAND
                                </th>
                                <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                    SKU
                                </th>
                                <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                    QUANTITY
                                </th>
                                <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                    PRICE
                                </th>
                                <th scope="col" className="pl-3 pr-3" style={{ fontWeight: "bold" }}>
                                    TOTAL
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderDetails?.order?.order_lineitems_info.map(line_item => (
                                <React.Fragment key={line_item.id}>
                                    <tr className={`${line_item?.refund > 0 ? "table-danger" : ""}`}
                                        style={{
                                            textDecoration: line_item?.refund > 0 ? "line-through" : "none"
                                        }}>
                                        <td>
                                            <a href={`/product-details?${new URLSearchParams({ productId: `${line_item.product_id}` })}`}>
                                                {line_item?.name}
                                            </a>
                                        </td>
                                        <td>
                                            {line_item.fulfilled ? (
                                                <i class="fa fa-check-circle text-success"></i>
                                            ) : (
                                                <i class="fa fa-times-circle text-light"></i>
                                            )}
                                        </td>

                                        <td>{line_item?.brand}</td>
                                        <td>{line_item?.sku}</td>
                                        <td>{line_item?.quantity}</td>
                                        <td>{line_item?.price}</td>
                                        <td>{line_item?.total}</td>
                                    </tr>

                                    {line_item?.subskus?.map(sub_skus => (
                                        <tr key={sub_skus.id} className={`${sub_skus?.refund > 0 ? "table-danger" : ""}`}
                                            style={{
                                                textDecoration: sub_skus?.refund > 0 ? "line-through" : "none"
                                            }}>
                                            <td>
                                                <a href={`/product-details?${new URLSearchParams({ productId: `${sub_skus.product_id}` })}`}>
                                                    {"- "}{sub_skus?.name}
                                                </a>
                                            </td>
                                            <td></td>

                                            <td>{sub_skus?.brand}</td>
                                            <td>{sub_skus?.sku}</td>
                                            <td>{sub_skus?.quantity}</td>
                                            <td>{sub_skus?.price}</td>
                                            <td>{sub_skus?.total}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                        <tfoot style={{ fontWeight: "bold" }}>
                            <tr>
                                <td colSpan={6} className={styles.customPadding} style={{ fontSize: "1rem" }}>
                                    Subtotal:
                                </td>
                                <td style={{ fontSize: "1rem" }}>{subtotal ? "$ " + subtotal : "$ 0.00"}</td>
                            </tr>
                            {discount_code && (
                                <tr>
                                    <td colSpan={6} className={styles.customPadding} style={{ fontSize: "1rem" }}>
                                        <span className="badge badge-primary" style={{ marginRight: "5px" }}>
                                            {discount_code}
                                        </span>
                                        Discount:
                                    </td>
                                    <td style={{ fontSize: "1rem" }}>{discount_amount ? "$ " + discount_amount : "$ 0.00"}</td>
                                </tr>
                            )}
                            <tr>
                                <td colSpan={6} className={styles.customPadding} style={{ fontSize: "1rem" }}>
                                    Shipping ({shipping_method ?? "-"}):
                                </td>
                                <td style={{ fontSize: "1rem" }}>{shipping ? "$ " + shipping : "$ 0.00"}</td>
                            </tr>
                            <tr>
                                <td colSpan={6} className={styles.customPadding} style={{ fontSize: "1rem" }}>
                                    Retailer Fee:
                                </td>
                                <td style={{ fontSize: "1rem" }}>{retailer_fee ? "$ " + retailer_fee : "$ 0.00"}</td>
                            </tr>
                            <tr>
                                <td colSpan={6} className={styles.customPadding} style={{ fontSize: "1rem" }}>
                                    Taxes:
                                </td>
                                <td style={{ fontSize: "1rem" }}>{taxes ? "$ " + taxes : "$ 0.00"}</td>
                            </tr>
                            <tr>
                                <td colSpan={6} className={styles.customPadding} style={{ fontSize: "1.5rem" }}>
                                    Total:
                                </td>
                                <td style={{ fontSize: "1.5rem" }}>{total ? "$ " + total : "$ 0.00"}</td>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default OrderSummary;
