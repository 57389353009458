/*
    Author - Akshay Vishwakarma
    Description - This is a common component for parent and child products for advent calendar
    Jira (if any) - BCD - 4091
*/

import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Label,
    Row,
} from "reactstrap";
import { MDBCol, MDBIcon } from "mdbreact";
import { api } from "../../services/api";

const ParentChildProductComponent = ({ url, selectedProduct, setSelectedProduct, selectedChildProduct, setSelectedChildProduct }) => {
    //const product_variant_list = [];
    //const product_child_variant_list = [];
    const [isLoading, setLoading] = useState(false);
    const [isLoadingChild, setLoadingChild] = useState(false);
    const [paramsFilter, setParamsFilter] = useState({});
    const [paramsChildFilter, setParamsChildFilter] = useState({});

    const [data, setData] = useState({
        results: [],
        count: 0,
        next: null,
        previous: null,
    });
    const [childData, setChildData] = useState({
        results: [],
        count: 0,
        next: null,
        previous: null,
    });

    useEffect(() => {
        api
            .get(url, {
                params: paramsFilter,
            })
            .then((response) => setData(response.data))
            .catch((error) => console.log(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsFilter]);

    useEffect(() => {
        api
            .get(url, {
                params: paramsChildFilter,
            })
            .then((response) => setChildData(response.data))
            .catch((error) => console.log(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paramsChildFilter]);



    let timer = null;

    const handleSearch = (e) => {
        // limpamos o timer
        clearTimeout(timer);
        // armazenamos o timer novamente
        timer = setTimeout(function () {
            setParamsFilter({
                ...paramsFilter,
                search: e.target.value,
            });
        }, 750);
    };

    const handleChildSearch = (e) => {
        // limpamos o timer
        clearTimeout(timer);
        // armazenamos o timer novamente
        timer = setTimeout(function () {
            setParamsChildFilter({
                ...paramsFilter,
                search: e.target.value,
            });
        }, 750);
    };

    // const handleProductCheckbox = (e, product_id) => {
    //     if (selectedProduct.length <= 0) {
    //         let checkbox = document.querySelectorAll(".variant_" + product_id);
    //         console.log("checkbox", checkbox);
    //         for (let i = 0; i < checkbox.length; i++) {
    //             if (e.target.checked === true && checkbox[i].checked === false) {
    //                 checkbox[i].checked = true;
    //             }
    //             if (e.target.checked === false && checkbox[i].checked === true) {
    //                 checkbox[i].checked = false;
    //             }
    //             if (e.target.checked === false) {
    //                 checkbox[i].checked = false;
    //             }
    //             if (
    //                 e.target.checked === true &&
    //                 product_variant_list.includes(parseInt(product_id)) === false
    //             ) {
    //                 setSelectedProduct(product_variant_list);
    //             }
    //             for (let i = 0; i < checkbox.length; i++) {
    //                 if (e.target.checked === true) {
    //                     if (
    //                         product_variant_list.includes(parseInt(checkbox[i].value)) ===
    //                         false
    //                     ) {
    //                         product_variant_list.push(parseInt(checkbox[i].value));
    //                         setSelectedProduct(product_variant_list);
    //                     }
    //                 }
    //             }
    //         }
    //     } else if (selectedProduct.length > 0) {
    //         if (e.target.checked === false) {
    //             let remove_checkbox = document.querySelectorAll(
    //                 ".variant_" + product_id
    //             );
    //             for (let i = 0; i < remove_checkbox.length; i++) {
    //                 remove_checkbox[i].checked = false;
    //                 var removeVariantIdIndex = selectedProduct.indexOf(
    //                     parseInt(remove_checkbox[i].value)
    //                 );
    //                 if (removeVariantIdIndex !== -1) {
    //                     selectedProduct.splice(removeVariantIdIndex, 1);
    //                     setSelectedProduct(selectedProduct);
    //                 }
    //                 setSelectedProduct(selectedProduct);
    //             }
    //         } else if (e.target.checked === true) {
    //             var new_product_variant_list = [];
    //             new_product_variant_list = selectedProduct;
    //             var productIdIndex = new_product_variant_list.indexOf(product_id);
    //             if (productIdIndex !== -1) {
    //                 new_product_variant_list.splice(productIdIndex, 1);
    //             }
    //             let checkbox = document.querySelectorAll(".variant_" + product_id);
    //             for (let i = 0; i < checkbox.length; i++) {
    //                 if (e.target.checked === true && checkbox[i].checked === false) {
    //                     checkbox[i].checked = true;
    //                 }
    //                 if (e.target.checked === false && checkbox[i].checked === true) {
    //                     checkbox[i].checked = false;
    //                     var checkboxproductIdIndex = new_product_variant_list.indexOf(
    //                         checkbox[i].value
    //                     );
    //                     if (productIdIndex !== -1) {
    //                         new_product_variant_list.splice(checkboxproductIdIndex, 1);
    //                     }
    //                     setSelectedProduct(new_product_variant_list);
    //                 }
    //                 if (
    //                     e.target.checked === true &&
    //                     new_product_variant_list.includes(parseInt(product_id)) === false
    //                 ) {
    //                     setSelectedProduct(new_product_variant_list);
    //                 }
    //                 for (let i = 0; i < checkbox.length; i++) {
    //                     if (e.target.checked === true) {
    //                         if (
    //                             new_product_variant_list.includes(
    //                                 parseInt(checkbox[i].value)
    //                             ) === false
    //                         ) {
    //                             new_product_variant_list.push(parseInt(checkbox[i].value));
    //                             setSelectedProduct(new_product_variant_list);
    //                             console.log("setSelectedProduct", new_product_variant_list);
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // };

    // const handleProductChildCheckbox = (e, product_id) => {
    //     if (selectedChildProduct.length <= 0) {
    //         let checkbox = document.querySelectorAll(".variant_childproduct_" + product_id);
    //         for (let i = 0; i < checkbox.length; i++) {
    //             if (e.target.checked === true && checkbox[i].checked === false) {
    //                 checkbox[i].checked = true;
    //             }
    //             if (e.target.checked === false && checkbox[i].checked === true) {
    //                 checkbox[i].checked = false;
    //             }
    //             if (e.target.checked === false) {
    //                 checkbox[i].checked = false;
    //             }
    //             if (
    //                 e.target.checked === true &&
    //                 product_child_variant_list.includes(parseInt(product_id)) === false
    //             ) {
    //                 setSelectedChildProduct(product_child_variant_list);
    //             }
    //             for (let i = 0; i < checkbox.length; i++) {
    //                 if (e.target.checked === true) {
    //                     if (
    //                         product_child_variant_list.includes(parseInt(checkbox[i].value)) ===
    //                         false
    //                     ) {
    //                         product_child_variant_list.push(parseInt(checkbox[i].value));
    //                         setSelectedChildProduct(product_child_variant_list);
    //                     }
    //                 }
    //             }
    //         }
    //     } else if (selectedChildProduct.length > 0) {
    //         if (e.target.checked === false) {
    //             let remove_checkbox = document.querySelectorAll(
    //                 ".variant_childproduct_" + product_id
    //             );
    //             for (let i = 0; i < remove_checkbox.length; i++) {
    //                 remove_checkbox[i].checked = false;
    //                 var removeVariantIdIndex = selectedChildProduct.indexOf(
    //                     parseInt(remove_checkbox[i].value)
    //                 );
    //                 if (removeVariantIdIndex !== -1) {
    //                     selectedChildProduct.splice(removeVariantIdIndex, 1);
    //                     setSelectedChildProduct(selectedChildProduct);
    //                 }
    //                 setSelectedChildProduct(selectedChildProduct);
    //             }
    //         } else if (e.target.checked === true) {
    //             var new_product_child_variant_list = [];
    //             new_product_child_variant_list = selectedChildProduct;
    //             var productIdIndex = new_product_child_variant_list.indexOf(product_id);
    //             if (productIdIndex !== -1) {
    //                 new_product_child_variant_list.splice(productIdIndex, 1);
    //             }
    //             let checkbox = document.querySelectorAll(".variant_childproduct_" + product_id);
    //             for (let i = 0; i < checkbox.length; i++) {
    //                 if (e.target.checked === true && checkbox[i].checked === false) {
    //                     checkbox[i].checked = true;
    //                 }
    //                 if (e.target.checked === false && checkbox[i].checked === true) {
    //                     checkbox[i].checked = false;
    //                     var checkboxproductIdIndex = new_product_child_variant_list.indexOf(
    //                         checkbox[i].value
    //                     );
    //                     if (productIdIndex !== -1) {
    //                         new_product_child_variant_list.splice(checkboxproductIdIndex, 1);
    //                     }
    //                     setSelectedChildProduct(new_product_child_variant_list);
    //                 }
    //                 if (
    //                     e.target.checked === true &&
    //                     new_product_child_variant_list.includes(parseInt(product_id)) === false
    //                 ) {
    //                     setSelectedChildProduct(new_product_child_variant_list);
    //                 }
    //                 for (let i = 0; i < checkbox.length; i++) {
    //                     if (e.target.checked === true) {
    //                         if (
    //                             new_product_child_variant_list.includes(
    //                                 parseInt(checkbox[i].value)
    //                             ) === false
    //                         ) {
    //                             new_product_child_variant_list.push(parseInt(checkbox[i].value));
    //                             setSelectedChildProduct(new_product_child_variant_list);
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // };

    const handleVariantProductCheckbox = (e, variant_id) => {
        // var new_product_variant_list = [];
        // if (selectedProduct.length > 0) {
        //     var myIndex = selectedProduct.indexOf(variant_id);
        //     if (myIndex !== -1) {
        //         selectedProduct.splice(myIndex, 1);
        //         console.log("new_product_variant_list",new_product_variant_list);
        //     } else {
        //         new_product_variant_list = selectedProduct;
        //         new_product_variant_list.push(parseInt(variant_id));
        //         setSelectedProduct(new_product_variant_list);
        //     }
        // } else {
        //     new_product_variant_list = selectedProduct;
        //     new_product_variant_list.push(parseInt(variant_id));
        //     setSelectedProduct(new_product_variant_list);
        // }

        const checked = e.target.checked;
        if (checked) {
            setSelectedProduct([parseInt(variant_id)]);
        } else {
            // If the checkbox is unchecked, clear the selectedProduct list
            setSelectedProduct([]);
        }

        const allCheckboxes = document.querySelectorAll("input.variant_parent[type='checkbox']");
        if (checked) {
            // Disable all checkboxes except the checked one
            allCheckboxes.forEach(checkbox => {
                if (parseInt(checkbox.value) !== variant_id) {
                    checkbox.disabled = true;
                }
            });
        } else {
            // Enable all checkboxes if none is selected
            allCheckboxes.forEach(checkbox => {
                checkbox.disabled = false;
            });
        }
    };

    const handleVariantProductChildCheckbox = (e, variant_id) => {
        // var new_product_variant_child_list = [];
        // if (selectedChildProduct.length > 0) {
        //     var myIndex = selectedChildProduct.indexOf(variant_id);
        //     if (myIndex !== -1) {
        //         selectedChildProduct.splice(myIndex, 1);
        //     } else {
        //         new_product_variant_child_list = selectedChildProduct;
        //         new_product_variant_child_list.push(parseInt(variant_id));
        //         setSelectedChildProduct(new_product_variant_child_list);
        //     }
        // } else {
        //     new_product_variant_child_list = selectedChildProduct;
        //     new_product_variant_child_list.push(parseInt(variant_id));
        //     setSelectedChildProduct(new_product_variant_child_list);
        // }

        const checked = e.target.checked;
        if (checked) {
            setSelectedChildProduct([parseInt(variant_id)]);
        } else {
            // If the checkbox is unchecked, clear the selectedProduct list
            setSelectedChildProduct([]);
        }

        const allCheckboxes = document.querySelectorAll("input.variant_childproduct[type='checkbox']");
        if (checked) {
            // Disable all checkboxes except the checked one
            allCheckboxes.forEach(checkbox => {
                if (parseInt(checkbox.value) !== variant_id) {
                    checkbox.disabled = true;
                }
            });
        } else {
            // Enable all checkboxes if none is selected
            allCheckboxes.forEach(checkbox => {
                checkbox.disabled = false;
            });
        }
    };

    const getNextProducts = (type, next) => {
        let results = type === "parent" ? data.results : childData.results;
        api
            .get(next)
            .then((response) => {
                const new_results = results.concat(response.data.results);
                type === "parent" ? setData({
                    ...data,
                    results: new_results,
                    next: response.data.next,
                    previous: response.data.previous,
                }) : setChildData({
                    ...data,
                    results: new_results,
                    next: response.data.next,
                    previous: response.data.previous,
                });
                setLoading(false);
                setLoadingChild(false);
            })
            .catch((error) => console.log(error));
    }

    const handleNext = (type) => {
        if (type === "parent" && data.next) {
            setLoading(true);
            getNextProducts(type, data.next)
        }
        if (type === "child" && childData.next) {
            setLoadingChild(true);
            getNextProducts(type, childData.next)
        }
    };
    return (
        <div>
            <Row className="mt-4 px-4" style={{ display: "flex",width:"100%" }}>
                <Col lg={6} xs={9}>
                    <Label className="mt-3">Parent (Pre-Order) Product *</Label>
                    <MDBCol style={{ display: "-webkit-box" }}>
                        <MDBIcon icon="search" className="mr-2 ml-0" />
                        <input
                            className="form-control form-control-sm"
                            onKeyDown={handleSearch}
                            type="text"
                            placeholder="Search"
                            aria-label="Search"
                        />
                    </MDBCol>
                    <ul
                        className={"p-2 overflow-auto"}
                        style={{ maxHeight: "300px" }}
                        id={"filter_"}
                    >
                        {data.results.map((product) => (
                            <div key={product.id + "_prod_"}>
                                <div
                                    key={product.id + "_product_"}
                                    className="row my-2 d-flex align-items-center"
                                    style={{ width: "40rem" }}
                                >
                                    <div className="col-2 pr-0">
                                        <Label className={"text-sm"}>
                                            {/* <input
                                                type="checkbox"
                                                id={"_" + product.id}
                                                className={"checkbox_product"}
                                                value={product.id}
                                                data-label={product.name}
                                                data-image={product.image}
                                                defaultChecked={product.checked}
                                                onChange={(e) =>
                                                    handleProductCheckbox(e, product.id)
                                                }
                                                style={{ marginRight: "20px" }}
                                            /> */}
                                        </Label>
                                        {product.image ? (
                                            <img
                                                src={product.image}
                                                className="img-thumbnail rounded-0"
                                                width="50%"
                                                alt="variant"
                                            />
                                        ) : (
                                            <img
                                                src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                                                className="img-thumbnail rounded-0"
                                                width="50%"
                                                alt="variant"
                                            />
                                        )}
                                    </div>
                                    <div className="col-10">
                                        <a
                                            href={
                                                window.env.APP_URL + "product/" + product.id
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <span className="text-wrap"> {product.name}</span>
                                            <span
                                                className="text-right"
                                                style={{ paddingLeft: "10px", color: "black" }}
                                            ></span>
                                        </a>
                                    </div>
                                </div>
                                {product.variants &&
                                    product.variants.map((variant, index) => (
                                        <div
                                            className={"row sub-product ml-3"}
                                            key={index + "_variant_"}
                                        >
                                            <div
                                                className="col-2 pr-0 variant-container"
                                                key={index}
                                            >
                                                <Label className={"text-sm"}>
                                                    <input
                                                        type="checkbox"
                                                        id={"_" + variant.id}
                                                        // className={"variant_" + product.id}
                                                        className={"variant_parent"}
                                                        value={variant.id}
                                                        data-label={variant.sku}
                                                        data-image={variant.image}
                                                        defaultChecked={variant.checked}
                                                        onChange={(e) =>
                                                            handleVariantProductCheckbox(
                                                                e,
                                                                variant.id
                                                            )
                                                        }
                                                        style={{ marginRight: "20px" }}
                                                    />
                                                </Label>
                                                {variant.image ? (
                                                    <img
                                                        src={variant.image}
                                                        className="img-thumbnail rounded-0"
                                                        width="50%"
                                                        alt="variant"
                                                    />
                                                ) : (
                                                    <img
                                                        src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                                                        className="img-thumbnail rounded-0"
                                                        width="50%"
                                                        alt="variant"
                                                    />
                                                )}
                                            </div>

                                            <span className="text-wrap">{variant.sku}</span>
                                            <span
                                                className="text-right"
                                                style={{ color: "black" }}
                                            ></span>

                                        </div>
                                    ))}
                            </div>
                        ))}

                        {data.results.length < data.count && (
                            <li className={"text-center"}>
                                {!isLoading ? (
                                    <Button
                                        className={"btn btn-sm "}
                                        onClick={() => handleNext("parent")}
                                    >
                                        show more
                                    </Button>
                                ) : (
                                    <span className={"text-sm text-muted"}>
                                        Loading...
                                    </span>
                                )}
                            </li>
                        )}
                    </ul>

                </Col>

                <Col lg={6} xs={9}>
                    <Label className="mt-3">Child (Fulfillable-Order) *</Label>
                    <MDBCol style={{ display: "-webkit-box" }}>
                        <MDBIcon icon="search" className="mr-2 ml-0" />
                        <input
                            className="form-control form-control-sm"
                            onKeyDown={handleChildSearch}
                            type="text"
                            placeholder="Search"
                            aria-label="Search"
                        />
                    </MDBCol>
                    <ul
                        className={"p-2 overflow-auto"}
                        style={{ maxHeight: "300px" }}
                        id={"filter_"}
                    >
                        {childData.results.map((product) => (
                            <div key={product.id + "_prod_"}>
                                <div
                                    key={product.id + "_product_"}
                                    className="row my-2 d-flex align-items-center"
                                    style={{ width: "40rem" }}
                                >
                                    <div className="col-2 pr-0">
                                        <Label className={"text-sm"}>
                                            {/* <input
                                                type="checkbox"
                                                id={"_" + product.id}
                                                className={"checkbox_product"}
                                                value={product.id}
                                                data-label={product.name}
                                                data-image={product.image}
                                                defaultChecked={product.checked}
                                                onChange={(e) =>
                                                    handleProductChildCheckbox(e, product.id)
                                                }
                                                style={{ marginRight: "20px" }}
                                            /> */}
                                        </Label>
                                        {product.image ? (
                                            <img
                                                src={product.image}
                                                className="img-thumbnail rounded-0"
                                                width="50%"
                                                alt="variant"
                                            />
                                        ) : (
                                            <img
                                                src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                                                className="img-thumbnail rounded-0"
                                                width="50%"
                                                alt="variant"
                                            />
                                        )}
                                    </div>
                                    <div className="col-10">
                                        <a
                                            href={
                                                window.env.APP_URL + "product/" + product.id
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <span className="text-wrap"> {product.name}</span>
                                            <span
                                                className="text-right"
                                                style={{ paddingLeft: "10px", color: "black" }}
                                            ></span>
                                        </a>
                                    </div>
                                </div>
                                {product.variants &&
                                    product.variants.map((variant, index) => (
                                        <div
                                            className={"row sub-product ml-3"}
                                            key={index + "_variant_"}
                                        >
                                            <div
                                                className="col-2 pr-0 variant-container"
                                                key={index}
                                            >
                                                <Label className={"text-sm"}>
                                                    <input
                                                        type="checkbox"
                                                        id={"_" + variant.id}
                                                        // className={"variant_childproduct_" + product.id}
                                                        className={"variant_childproduct"}
                                                        value={variant.id}
                                                        data-label={variant.sku}
                                                        data-image={variant.image}
                                                        defaultChecked={variant.checked}
                                                        onChange={(e) =>
                                                            handleVariantProductChildCheckbox(
                                                                e,
                                                                variant.id
                                                            )
                                                        }
                                                        style={{ marginRight: "20px" }}
                                                    />
                                                </Label>
                                                {variant.image ? (
                                                    <img
                                                        src={variant.image}
                                                        className="img-thumbnail rounded-0"
                                                        width="50%"
                                                        alt="variant"
                                                    />
                                                ) : (
                                                    <img
                                                        src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                                                        className="img-thumbnail rounded-0"
                                                        width="50%"
                                                        alt="variant"
                                                    />
                                                )}
                                            </div>

                                            <span className="text-wrap">{variant.sku}</span>
                                            <span
                                                className="text-right"
                                                style={{ color: "black" }}
                                            ></span>

                                        </div>
                                    ))}
                            </div>
                        ))}

                        {data.results.length < data.count && (
                            <li className={"text-center"}>
                                {!isLoadingChild ? (
                                    <Button
                                        className={"btn btn-sm "}
                                        onClick={() => handleNext("child")}
                                    >
                                        show more
                                    </Button>
                                ) : (
                                    <span className={"text-sm text-muted"}>
                                        Loading...
                                    </span>
                                )}
                            </li>
                        )}
                    </ul>

                </Col>
            </Row>
        </div>
    )
}

export default ParentChildProductComponent