import React, { useState, useContext } from "react";
import styles from "./orderDetail.module.css";
import { FaEdit, FaUser, FaClock } from "react-icons/fa";
import Moment from "react-moment";
import { api } from "services/api";
import { useToasts } from "react-toast-notifications";
import { AuthContext } from "contexts/AuthContext";
import { managedByIds } from "utils/commonFunctions";

const InternalNote = ({ orderDetails }) => {
    const { user } = useContext(AuthContext);
    const { addToast } = useToasts();
    const { id, managed_by_id } = orderDetails?.order?.order_info;
    const { internal_note, note_updated_at, user_first_name, user_last_name } = orderDetails?.order?.order_internal_notes
    const fullname = user_first_name + " " + user_last_name
    const [note, setNote] = useState(internal_note || "");
    const [noteUpdateUser, setNoteUpdateUser] = useState(fullname || "");
    const [updatedAt, setUpdatedAt] = useState(note_updated_at || "");
    const [showNote, setShowNote] = useState(false);
    const addInterNotePermissions = managedByIds;
    const isPermission = managed_by_id && addInterNotePermissions.includes(managed_by_id);
    const { show_order_note } = orderDetails?.order;

    const saveNote = async () => {
        if (!note){
            addToast("Please enter a note", { appearance: "error" });
            return;
        };
        const data = {
            notes: note,
            orders: [id],
        }
        const response = await api.put("order/internal-notes/", data);
        const res_data = response
        if (res_data.status === 200) {
            const noteData = res_data?.data?.notes
            const userData = res_data?.data?.user
            const updatedData = res_data?.data?.updated_at
            console.log("notes data", noteData);
            setShowNote(false)
            setNote(noteData)
            setNoteUpdateUser(userData)
            setUpdatedAt(updatedData)
            addToast("Notes Updated", { appearance: "success", autoDismiss: true });


        } else {
            setShowNote(false)
            addToast("Error Occured", { appearance: "error", autoDismiss: true });
        }

    }
    return (
        <div className={styles.cardConatiner}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h3>Internal Note</h3>
                {(user && (user.isAdmin || user.isRetailer || user.isMaster || user.isManager) && note !== "") && <div style={{ cursor: "pointer" }} onClick={() => setShowNote(true)}>
                    <FaEdit />
                </div>}
            </div>
            <hr />

            {showNote ? (
                <div>
                    <div>
                        <textarea
                            style={{ width: "100%", paddingInline: "10px" }}
                            placeholder="Enter Note for order"
                            onChange={e => setNote(e.target.value)}
                            value={note}
                        ></textarea>

                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <button className="btn btn-success" onClick={() => saveNote()}>Add Note</button>
                        </div>
                        <div>
                            <button className="btn btn-danger" onClick={() => setShowNote(!showNote)}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                ((!isPermission) && (user && (user.isAdmin || user.isMaster || user.isManager)))? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <button className="btn btn-success" onClick={() => setShowNote(!showNote)}>
                            Add Internal Note
                        </button>
                    </div>
                ) :
                    ((user && (user.isAdmin || user.isMaster || user.isManager || (user.isSupplier && show_order_note)) && (!isPermission))?
                        (<div>
                            <div style={{ marginBottom: "4rem" }}>
                                <span style={{ textWrap: "wrap" }}>{note}</span>

                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <div style={{ display: "flex", gap: 4, alignItems: "baseline" }}>
                                        <div>
                                            <FaUser />
                                        </div>
                                        <div>{noteUpdateUser}</div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ display: "flex", gap: 4, alignItems: "baseline" }}>
                                        <div>
                                            <FaClock />
                                        </div>
                                        <div>
                                            {updatedAt ? <Moment format="MMMM D YYYY h:mm:ssa" withTitle>
                                                {updatedAt}
                                            </Moment> : "-"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>):null
                    )
            )

            }
        </div>
    );
};

export default InternalNote;
