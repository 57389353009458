/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { AuthContext } from "contexts/AuthContext";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {  DropdownMenu,  DropdownItem,  UncontrolledDropdown,  DropdownToggle,  Navbar,  Nav,  Container,  Media,} from "reactstrap";
import { getToken, removeTokens } from '../../services/auth'

const showTitleOnNavbarFor = [  { path: "/dashboard", title : "Dashboard" },]

const showTitle = () => {
  let title = "";
  showTitleOnNavbarFor.forEach((item) => {
    if(item.path === window.location.pathname) {title = item.title;}
  })
  return title;
}
function handlerLogout(){
  removeTokens()
  window.location.href = window.env.APP_URL + "logout/"
}

const AdminNavbar = (props) => {
  const { user } = useContext(AuthContext)
  const token  =  getToken()

  if (!token){
    window.location.href = window.env.APP_URL + "logout/"
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark nav-fixed bg-primary" expand="md" id="navbar-main">
        <Container fluid>
          <div className="ml-5 ml-lg-3 mb-1">
            {showTitle() && <h6 className="h2 text-white d-inline-block mb-0 mr-2 d-none d-xl-block">{showTitle()}</h6>}
            {user && user.isWmsRetailer &&
              <div className=" h1 text-primary text-center">
                <img src="https://assets-barcart.s3.amazonaws.com/static/images/logo.png" height="28" alt=""/>
              </div>
            }
          </div>

          <Nav className="align-items-center d-none d-flex flex-row" navbar>
            <a href="https://help.business.flaviar.com/en/"  target="blank" style={{ color: 'white' }}>
              <i class="fas fa-question-circle" style={{ paddingRight: '10px', fontSize: '1.5rem' }}></i>
            </a>
            <a href="https://updates.business.flaviar.com" target="blank" className="rn-badge" id="rn-badge" style={{ color: 'white' }}>
              <i class="fas fa-bell mr-2" style={{ paddingLeft: '10px', fontSize: '1.5rem' }}></i>
            </a>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-3 ml-3 ml-md-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle overflow-hidden">
                    {user?.avatar ?
                      <img
                        alt="..."
                        src={user?.avatar}
                      />
                      : <i className="fa fa-user"></i>}
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user?.first_name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                {/* <DropdownItem href={window.env.APP_URL + "profile/"} >
                  <i className="ni ni-single-02" />
                  <span>Settings</span>
                </DropdownItem> */}
                {user && (
                  <DropdownItem href={user.isWmsRetailer ? "/profile" : window.env.APP_URL + "profile/"}>
                    <i className="ni ni-single-02" />
                    <span>Settings</span>
                  </DropdownItem>
                )}
                <DropdownItem href={window.env.APP_URL + "change-password/"}>
                  <i className="ni ni-lock-circle-open" />
                  <span>Change Password</span>
                </DropdownItem>
                {/* Integrate customer prtal within barcart specific to only supplier role */}
                {user && user.isSupplier &&
                  <DropdownItem href={window.env.APP_URL + "stripe/create-customer-portal-session/"} target="_blank">
                    <i className="ni ni-lock-circle-open" />
                    <span>Account</span>
                  </DropdownItem>
                }
                {/* <DropdownItem href={ window.env.APP_URL + "?product_tour_id=145946"}>
                  <i className="fas fa-globe-americas" />
                  <span>Take a Tour</span>
                </DropdownItem> */}
                <DropdownItem divider />
                <DropdownItem onClick={()=>handlerLogout()}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
