import React from "react";
import styles from "./orderDetail.module.css";

const OrderCount = ({ orderDetails }) => {
    const { order_count_info } = orderDetails?.order;
    const junkValues = ["", " ", "-"];
    const isValidOrderCount = order_count_info && !junkValues.includes(order_count_info);
    return (
        <div className={styles.cardConatiner}>
            <div>
                <h3>Order Count</h3>
            </div>
            <hr />
            <div>
                <span>{isValidOrderCount ? order_count_info : "-"}</span>
            </div>
        </div>
    );
};

export default OrderCount;
