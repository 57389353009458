// ChargebacksTableRow.js

import React, { useContext } from "react";
import { BillingStatusColor } from "../../components/Order";
import Moment from "react-moment";
import { AuthContext } from "../../contexts/AuthContext";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const ChargebacksTableRow = ({ chargeback, handleCheckbox, handleUpdateChargebackStatusModal, selected }) => {
    const { user } = useContext(AuthContext);

    const handleCheckboxChange = e => {
        handleCheckbox(e);
    };
    const isChecked = Boolean(selected?.find(item => +item.id === +chargeback.order_id));

    return (
        <tr key={chargeback.id}>
            <td className={"pr-3 pl-3"}>
                <input
                    type="checkbox"
                    name={chargeback?.order?.id}
                    value={chargeback?.order?.id}
                    id={"checkbox_" + chargeback?.order?.id}
                    className={"chargebackCheckbox"}
                    onChange={e => handleCheckboxChange(e)}
                    defaultChecked={isChecked}
                />
            </td>
            <td
                className="pl-1 pr-1"
                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "200px" }}
                title={chargeback?.order?.brand_names?.join(", ")}>
                {chargeback?.order?.brand_names?.join(", ") || "-"}
            </td>
            <td className="pl-1 pr-1">
                {chargeback?.order?.name ? (
                    <a href={`/order-details?${new URLSearchParams({ orderId: `${chargeback?.order?.id}` })}`} className="btn btn-link p-0">
                        {chargeback?.order?.name}
                    </a> ) : (
                        "-"
                )}
            </td>
            <td className="pl-1 pr-1">
                <Moment format="MM/DD/YYYY" withTitle>
                    {chargeback?.order?.created_at || "-"}
                </Moment>
            </td>
            {/* BCD-4294 Discount Code Chargeback Created At */}
            <td className="pl-1 pr-1">
                {chargeback?.discount_chargeback_created_at ?
                <Moment format="MM/DD/YYYY" withTitle>
                    {chargeback.discount_chargeback_created_at}
                </Moment>
                : "-"
                }
            </td>
            <td className="pl-1 pr-1">{chargeback?.code || "-"}</td>
            <td className="pl-1 pr-1">{chargeback?.type_discount || "-"}</td>
            <td className="pl-1 pr-1">{chargeback?.value || "-"}</td>
            <td className="pl-1 pr-1">${chargeback?.order?.subtotal || "-"}</td>
            <td className="pl-1 pr-1">{chargeback?.order?.channel_name || "-"}</td>
            <td className="pl-1 pr-1">
                {chargeback?.invoice_url && chargeback?.invoice_ref ? (
                    <a target="_blank" href={chargeback?.invoice_url} className="btn btn-link p-0">
                        {chargeback?.invoice_ref}
                    </a>
                ) : (
                    "-"
                )}
            </td>
            {user?.isAdmin && (
                <td className="pl-1 pr-1">{chargeback?.billable_user || "-"}</td>
            )}
            <td className="pl-3 pr-3">
                <BillingStatusColor>{chargeback?.billing_status || "-"}</BillingStatusColor>
            </td>
            {user.isAdmin && (
                <td className="pl-3 pr-3">
                    <UncontrolledDropdown>
                        <DropdownToggle className="btn text-primary" role="button" size="sm" color="white" onClick={e => e.preventDefault()}>
                            <i className="fas fa-ellipsis-v text-dark"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem onClick={e => handleUpdateChargebackStatusModal([chargeback?.order])}>
                                <i className="fas fa-file-invoice" /> Update Order Status
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </td>
            )}
        </tr>
    );
};

export default ChargebacksTableRow;
