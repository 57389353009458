/*
    Author - Akshay Vishwakarma
*/
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
function FulfillmentInfoConfirmationModal({ modalIsOpen, setModalIsOpen, onConfirmation,text,type}) {
    
    const toggleModel = () => {
        setModalIsOpen(!modalIsOpen);
    };
    return (
        <Modal isOpen={modalIsOpen} toggle={toggleModel}>
            <ModalHeader className={`bg-${type} text-white`}>
                <span className="text-white h3">Confrim Delete</span>
            </ModalHeader>
            <ModalBody>{text}</ModalBody>
            <ModalFooter>
                <Button onClick={toggleModel}>Cancel</Button>
                <Button
                    className={`bg-${type} text-white`}
                    onClick={() => {
                        onConfirmation();
                        toggleModel();
                    }}>
                    confirm
                </Button>
            </ModalFooter>
        </Modal>
    );
}
export default FulfillmentInfoConfirmationModal;