import React from "react";
import styles from "./orderDetail.module.css";
import DataTable from "./DataTable";

const columns = ["key", "value"];

const MetaFields = ({ orderDetails }) => {
    const { order_metafields_info } = orderDetails?.order
    return (
        <div className={styles.cardConatiner}>
            <div>
                <h3>Meta Fields</h3>
            </div>
            <hr />
            <div>
                <DataTable columns={columns} data={order_metafields_info?.metafields} extraRow={order_metafields_info} />
            </div>
        </div>
    );
};

export default MetaFields;
