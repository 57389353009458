/*
    Author - Akshay Vishwakarma
    Description - This a listing page for order status Override
    Related Jira - BCD - 3870 

*/
import React, { useContext, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
    Card,
    CardHeader,
    Table,
    Container,
    Row,
    Col,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import Moment from "react-moment";
import { api } from "services/api";
import { AuthContext } from "contexts/AuthContext";
import Filter from "../../components/Filters/Filter";
import BadgePillFilter from "components/Filters/Badge";
import { OrderStatusOverrideModal } from "components/Retailer/OrderStatusOverrideModal";
import DeleteOrderStatusOverrideModal from "feature/rolewise/admin/retailer-payout-override/DeleteOrderStatusOverrideModal";
import fileDownload from "js-file-download";
import useFilter from "customHooks/useFilter";
import { removeBadge } from "utils/commonFunctions";
import { getParams } from "utils/commonFunctions";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import { Sort, handleSort } from "../../utils/commonFunctions"
import moment from "moment/moment";

const OrderStatusOverride = props => {
    const { addToast } = useToasts();

    const [order_status_override_api_result, set_order_status_override_api_result] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });
    const [exportButton, setExportButton] = useState({
        label: "Export",
        disabled: false,
    });
    const [sort, setSort] = useState("-id");
    const [sortOrder, setSortOrder] = useState({ order: "", columnName: "" });
    const [isLoading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState();
    const [deleteOrderStatusOverrideInventoryLocation, setdeleteOrderStatusOverrideInventoryLocation] = useState();
    const [deleteOrderStatusOverrideSKU, setdeleteOrderStatusOverrideSKU] = useState();
    const [deleteOrderStatusOverrideState, setdeleteOrderStatusOverrideState] = useState();
    const [deleteOrderStatusOverrideId, setdeleteOrderStatusOverrideId] = useState();

    const [deleteOrderStatusOverrideBrand, setdeleteOrderStatusOverrideBrand] = useState();
    const [deleteOrderStatusOverrideVariant, setdeleteOrderStatusOverrideVariant] = useState();
    const [deleteOrderStatusOverrideSalesChannel, setdeleteOrderStatusOverrideSalesChannel] = useState();
    const [deleteOrderStatusOverrideCurrentStatus, setdeleteOrderStatusOverrideCurrentStatus] = useState();
    const [deleteOrderStatusOverrideNewStatus, setdeleteOrderStatusOverrideNewStatus] = useState();
    const [deleteOrderStatusOverrideStartDate, setdeleteOrderStatusOverrideStartDate] = useState();
    const [deleteOrderStatusOverrideEndDate, setdeleteOrderStatusOverrideEndDate] = useState();


    const [delete_retailer_override_assignee_list, setdelete_retailer_override_assignee_list] = useState([]);
    const [isActiveButton, setisActiveButton] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [selected, setSelected] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [params, setParams] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [clearall, setClearAll] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [filter, setFilter, clearFilterState] = useFilter({});

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    useEffect(() => {
        setLoading(true);
        let new_params = getParams(filter, setClearAll);
        new_params = { ...new_params, page, ordering: sort };
        api.get("order/pre-order-status-override/", { params: new_params })
            .then(response => {
                const list = response.data.results.map(order => {
                    const s = selected.filter(item => parseInt(item.id) === order.id);
                    order.checked = s.length > 0;
                    return order;
                });
                set_order_status_override_api_result(list);
                setTotalOrders(response.data.count);
                const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                const previous = page !== 1 ? page - 1 : 1;
                const next = page !== totalPages ? page + 1 : totalPages;
                setPagination({
                    previous,
                    next,
                    pages: () => {
                        let startCount = 1;
                        let endCount = isMobile ? 4 : 12;
                        let numberCount = Math.round(endCount / 2);
                        const pageList = [];
                        if (numberCount < 0) numberCount = 1;
                        startCount = page - numberCount;
                        if (startCount <= 0) startCount = 1;
                        if (page !== 1) endCount = page + numberCount;
                        if (endCount > totalPages) endCount = totalPages;
                        if (totalPages >= endCount) {
                            for (let i = startCount; i <= endCount; i++) {
                                pageList.push(i);
                            }
                        } else if (totalPages >= 1) {
                            for (let i = 1; i <= totalPages; i++) pageList.push(i);
                        }
                        return pageList;
                    }
                });
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sort, filter, refresh]);

    // function to handle export functionality
    const handleExportRetailers = () => {
        setExportButton({
            label: "Exporting...",
            disabled: true,
        });

        let new_params = {};
        new_params.overrideExportIds = delete_retailer_override_assignee_list.map((item) => item.id);
        api
            .get("export/pre-order-status-override/", {
                params: new_params,
            })
            .then((response) => response.data)
            .then((response) => {
                const success = response?.success | false;

                if (success) {
                    addToast(response.message, {
                        appearance: "success",
                        autoDismiss: false,
                    });
                } else {
                    const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
                    const filename = "retailers_" + timestamp + ".csv";
                    fileDownload(response, filename);
                }

                setExportButton({
                    label: "Export",
                    disabled: false,
                });
            });
    };

    const handlePagination = page => {
        setPage(page);
    };

    const { user, isAuthentication } = useContext(AuthContext);

    // Below functions handle working of filters

    const handleBrands = e => {
        setPage(1);
        let checkbox = [...new Set(filter.brands)];
        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
        }
        setFilter({ ...filter, brands: checkbox });
    };

    const handleStates = e => {
        setPage(1);
        if (e === "__all__") {
            let status = [];

            let checkbox = document.querySelectorAll(".checkbox_state");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                status.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label
                });
            }
            setFilter({ ...filter, states: status });
        } else {
            let checkbox = [...new Set(filter.states)];

            if (e.target.checked) {
                checkbox.push({ value: e.target.value, label: e.target.dataset.label });
            } else {
                checkbox = checkbox.filter(item => item.value !== e.target.value);
            }

            setFilter({ ...filter, states: checkbox });
        }
    };

    const handleInventoryLocation = e => {
        setPage(1);
        if (e === "__all__") {
            let inventory_location = [];

            let checkbox = document.querySelectorAll(".checkbox_inventory_location");
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                inventory_location.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label
                });
            }
            setFilter({ ...filter, inventory_location: inventory_location });
        } else {
            let checkbox = [...new Set(filter.inventory_location)];
            if (e.target.checked) {
                checkbox.push({ value: e.target.value, label: e.target.dataset.label });
            } else {
                checkbox = checkbox.filter(item => item.value !== e.target.value);
            }
            setFilter({ ...filter, inventory_location: checkbox });
        }
    };

    const handleProduct = e => {
        setPage(1);
        let checkbox = [...new Set(filter.products)];

        if (e.target.checked) {
            checkbox.push({ value: e.target.value, label: e.target.dataset.label });
        } else {
            checkbox = checkbox.filter(item => item.value !== e.target.value);
        }

        setFilter({ ...filter, products: checkbox });
    };

    //clears the filter

    const clearFilter = prefix => {
        setPage(1);
        if (prefix === "brand") {
            setFilter({ ...filter, brands: [] });
        } else if (prefix === "product") {
            setFilter({ ...filter, products: [] });
        } else if (prefix === "state") {
            setFilter({ ...filter, states: [] });
        } else if (prefix === "inventory_location") {
            setFilter({ ...filter, inventory_location: [] });
        } else if (prefix === "user") {
            setFilter({ ...filter, user: [] });
        }
        setClearAll(false);

        let checkbox = document.querySelectorAll(".checkbox_" + prefix + ":checked");
        if (checkbox.length > 0) {
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = false;
            }
        }
    };

    const handleClearAll = () => {
        setPage(1);
        setClearAll(false);
        setFilter({ ...filter, brands: [], products: [], states: [], inventory_location: [], user: [] });
    };

    // Above functions handle working of filters

    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    //This function invokes when user checks the ckeckbox on the listing page
    const handleCheckbox = e => {
        let checkbox = document.querySelectorAll(".retailer_override_assignee_checbox");
        for (let i = 0; i < checkbox.length; i++) {
            if (e.target.checked === true && checkbox[i].checked === false) {
                checkbox[i].checked = true;
                var new_retailer_override_assignee_list = [];
                var retailer_instance = {};

                // eslint-disable-next-line no-loop-func
                order_status_override_api_result.forEach(order_detail => {
                    if (parseInt(order_detail.id) === parseInt(checkbox[i].name)) {
                        retailer_instance.id = order_detail.id;
                        retailer_instance.sku = order_detail.sku;
                        retailer_instance.inventory_location = order_detail.inventory_location;
                        retailer_instance.ship_to_state = order_detail.ship_to_state;
                        retailer_instance.variant = order_detail.variant;
                        retailer_instance.current_status = order_detail.current_status;
                        retailer_instance.new_status = order_detail.new_status;
                        retailer_instance.override_start_date = order_detail.override_start_date;
                        retailer_instance.override_end_date = order_detail.override_end_date;
                        retailer_instance.channel = order_detail.channel;
                        retailer_instance.brand = order_detail.brand;

                        if (delete_retailer_override_assignee_list.length <= 1) {
                            setisActiveButton(false);
                        }

                        if (delete_retailer_override_assignee_list.length > 0) {
                            new_retailer_override_assignee_list = delete_retailer_override_assignee_list;
                            new_retailer_override_assignee_list.push(retailer_instance);
                            setdelete_retailer_override_assignee_list(new_retailer_override_assignee_list);
                            setisActiveButton(true);
                        } else {
                            new_retailer_override_assignee_list = delete_retailer_override_assignee_list;
                            new_retailer_override_assignee_list.push(retailer_instance);
                            setdelete_retailer_override_assignee_list(new_retailer_override_assignee_list);
                            setisActiveButton(true);
                        }
                    }
                });
            }
            if (e.target.checked === false && checkbox[i].checked === true) {
                setdelete_retailer_override_assignee_list([]);
                checkbox[i].checked = false;
                setisActiveButton(false);
            }
        }
    };

    const handleOverrideCheckbox = (e, id, sku, inventory_location, ship_to_state, variant, current_status, new_status, override_start_date, override_end_date, channel, brand) => {
        let new_retailer_override_assignee_list = [];
        let retailer_instance = {
            id: id,
            sku: sku,
            inventory_location: inventory_location,
            ship_to_state: ship_to_state,
            variant: variant,
            current_status: current_status,
            new_status: new_status,
            override_start_date: override_start_date,
            override_end_date: override_end_date,
            channel: channel,
            brand: brand,
        };
        if (delete_retailer_override_assignee_list.length <= 1) {
            setisActiveButton(false);
        }
        if (delete_retailer_override_assignee_list.length > 0) {
            const index = delete_retailer_override_assignee_list.findIndex(element => element.id === id);
            if (index !== -1) {
                delete_retailer_override_assignee_list.splice(index, 1);
            } else {
                new_retailer_override_assignee_list = delete_retailer_override_assignee_list;
                new_retailer_override_assignee_list.push(retailer_instance);
                setdelete_retailer_override_assignee_list(new_retailer_override_assignee_list);
                setisActiveButton(true);
            }
        } else {
            new_retailer_override_assignee_list = delete_retailer_override_assignee_list;
            new_retailer_override_assignee_list.push(retailer_instance);
            setdelete_retailer_override_assignee_list(new_retailer_override_assignee_list);
            setisActiveButton(true);
        }
    };

    const toggle = () => setOpenDeleteModal(!openDeleteModal);

    const DeleteRetailerOverride = override_assignee_id => {
        let deleted_override_assignee_id = "";
        if (typeof override_assignee_id === "string") {
            deleted_override_assignee_id = override_assignee_id;
        }
        if (typeof override_assignee_id === "object") {
            let override_assignee_id_list = [];
            override_assignee_id.forEach(event => {
                override_assignee_id_list.push(event.id);
            });
            deleted_override_assignee_id = override_assignee_id_list;
        }
        api.delete(`order/pre-order-status-override/?order_status_overrides=${deleted_override_assignee_id}`, {})
            .then(response => response)
            .then(response => {
                if (response.status === 200) {
                    addToast(response.data.message, {
                        appearance: "success",
                        autoDismiss: true
                    });
                    handleRefresh();
                    setisActiveButton(false);
                    setdelete_retailer_override_assignee_list([]);
                    setOpenDeleteModal(false);
                } else if (response.status === 404) {
                    addToast(response.data.message, {
                        appearance: "error",
                        autoDismiss: true
                    });
                    handleRefresh();
                    setOpenDeleteModal(false);
                }
            })
            .catch(err => {
                addToast("Something went wrong, please try again!!", {
                    appearance: "error",
                    autoDismiss: true
                });
            });
    };

    const DeleteOrderStatusOverride = (id, ship_to_state, brand, variant, sku, inventory_location, current_status, new_status, override_start_date, override_end_date, channel) => {
        setOpenDeleteModal(true);
        setdeleteOrderStatusOverrideInventoryLocation(inventory_location);
        setdeleteOrderStatusOverrideSKU(sku);
        setdeleteOrderStatusOverrideState(ship_to_state);
        setdeleteOrderStatusOverrideId(id);
        setdeleteOrderStatusOverrideBrand(brand);
        setdeleteOrderStatusOverrideVariant(variant);
        setdeleteOrderStatusOverrideSalesChannel(channel);
        setdeleteOrderStatusOverrideCurrentStatus(current_status);
        setdeleteOrderStatusOverrideNewStatus(new_status);
        setdeleteOrderStatusOverrideStartDate(override_start_date);
        setdeleteOrderStatusOverrideEndDate(override_end_date);
    };

    if (isAuthentication && !user.isAdmin) window.location.href = window.env.APP_URL;

    return (
        <>
            {isAuthentication && user.isAdmin && (
                <Container fluid>
                    <div className={"pt-7"}>
                        {isAuthentication && (
                            <Row className={"mb-2"}>
                                <Col xs={10} lg={"2"} className={"text-left"}></Col>
                                <Col xs={2} lg={"10"} className={"text-right"}>
                                    <div className={"d-none d-lg-inline-block mr-2"}>
                                        <div className="mb-1 d-inline">
                                            {/* 7278215305 - increase font size */}
                                            <button className="button-font-size btn btn-neutral btn-sm mr-2 p-9px" onClick={handleRefresh}>
                                                <i className="fas fa-sync-alt"></i>
                                            </button>
                                        </div>

                                        <Filter
                                            name={"State"}
                                            prefix={"state"}
                                            onChange={handleStates}
                                            newparams={params}
                                            selected={filter.states}
                                            url={"order/filter/state/"}
                                            inputSearch={true}
                                            onClear={() => clearFilter("state")}
                                        />
                                        <Filter
                                            name={"Brand"}
                                            prefix={"brand"}
                                            onChange={handleBrands}
                                            newparams={params}
                                            selected={filter.brands}
                                            url={"/order/filter/brand/"}
                                            onClear={() => clearFilter("brand")}
                                        />
                                        <Filter
                                            name={"Inventory Location"}
                                            prefix={"inventory_location"}
                                            showSelectAll={false}
                                            onChange={handleInventoryLocation}
                                            newparams={params}
                                            selected={filter.inventory_location}
                                            url={"order/filter/inventory-location/"}
                                            disablePagination={true}
                                            inputSearch={true}
                                            onClear={() => clearFilter("inventory_location")}
                                        />
                                        <Filter
                                            name={"Product"}
                                            prefix={"product"}
                                            onChange={handleProduct}
                                            newparams={params}
                                            selected={filter.products}
                                            url={"inventory/filter/product"}
                                            inputSearch={true}
                                            onClear={() => clearFilter("product")}
                                        />
                                        {/* 7278215305 - increase font size */}
                                        <Button
                                            color={"primary"}
                                            size={"sm"}
                                            disabled={exportButton.disabled}
                                            className="button-font-size p-9px"
                                            onClick={handleExportRetailers}
                                        >
                                            <i className="fas fa-file-csv"></i> {exportButton.label}
                                        </Button>
                                        {/* 7278215305 - increase font size */}
                                        <Button className="button-font-size p-9px" color={"primary"} size={"sm"} onClick={e => setOpenModal(true)}>
                                            <i className="fas fa-file-csv"></i> Create
                                        </Button>

                                        <OrderStatusOverrideModal
                                            url={"retailer-assignment-product-list/"}
                                            isOpen={openModal}
                                            toggle={e => setOpenModal(false)}
                                            loading={isLoading}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            {isAuthentication && (
                                <div className="col">
                                    <Card className="shadow ">
                                        <CardHeader className="border-0">
                                            <Row>
                                                <Col>
                                                    <h3
                                                        className="mb-0"
                                                        style={{
                                                            display: "inline-block",
                                                            paddingRight: "5px"
                                                        }}>
                                                        Order Status Override <small>&nbsp;({totalOrders})</small>
                                                    </h3>
                                                    {clearall && (
                                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                        <a
                                                            href={void 0}
                                                            className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                                            onClick={handleClearAll}
                                                            id="clear_all_filters">
                                                            <i className="fas fa-times-circle"></i> Clear All filters
                                                        </a>
                                                    )}
                                                </Col>
                                                <Col className="text-right">
                                                    {isActiveButton && user.isAdmin && (
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                className="btn text-primary"
                                                                href="#"
                                                                role="button"
                                                                size="sm"
                                                                color="white"
                                                                onClick={e => e.preventDefault()}>
                                                                <i className="fas fa-cogs" /> actions
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                                <DropdownItem href="#" onClick={e => setOpenDeleteModal(true)} className="text-danger">
                                                                    <i className="fas fa-file-invoice" />
                                                                    Delete Records
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    )}
                                                </Col>
                                                <Col sm="12">
                                                    <BadgePillFilter
                                                        data={filter}
                                                        onClick={e => {
                                                            removeBadge(e, filter, setFilter, undefined, undefined, undefined, undefined, setPage);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        {isLoading ? (
                                            <div className="text-center mt-3 mb-3">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <Table className="align-items-center table-flush" responsive>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>
                                                                <input
                                                                    type="checkbox"
                                                                    name={"check_orders"}
                                                                    id={"checkbox_orders"}
                                                                    onChange={handleCheckbox}
                                                                />
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                <Sort onClick={() => handleSort(sort, "created_at", setSortOrder, setSort)} sortingOrder={sortOrder} column="created_at">Created at</Sort>
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Ship To State
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Brand
                                                            </th>
                                                            <th scope="col" style={{ minWidth: "300px" }} className="pl-3 pr-3">
                                                                Variant Name
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                SKU
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Inventory Location Name
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Current Status
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                New Status
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Override Start Date
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Override End Date
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Sales Channel
                                                            </th>
                                                            {/* <th scope="col" className="pl-3 pr-3">
                                                                User
                                                            </th> */}
                                                            <th scope="col" className="pl-3 pr-7"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {order_status_override_api_result.map(order => (
                                                            <tr key={order.id}>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        name={order.id}
                                                                        className="retailer_override_assignee_checbox"
                                                                        onChange={e =>
                                                                            handleOverrideCheckbox(
                                                                                e,
                                                                                order.id,
                                                                                order.sku,
                                                                                order.inventory_location,
                                                                                order.ship_to_state,
                                                                                order.variant,
                                                                                order.current_status,
                                                                                order.new_status,
                                                                                order.override_start_date,
                                                                                order.override_end_date,
                                                                                order.channel,
                                                                                order.brand
                                                                            )
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="pl-3 pr-3 ">
                                                                    <Moment format="M/D/Y h:mm:ssa" withTitle>
                                                                        {order.created_at}
                                                                    </Moment>
                                                                </td>
                                                                <td className="pl-3 pr-3 ">{order.ship_to_state}</td>
                                                                <td className="pl-3 pr-3 ">{order.brand}</td>
                                                                <td className="pl-3 pr-3">
                                                                    <span className="text-wrap">
                                                                        {order.variant ?? "-"}
                                                                    </span>
                                                                </td>
                                                                <td className="pl-3 pr-3">
                                                                    <span className="text-wrap">{order.sku}</span>
                                                                </td>
                                                                <td className="pl-3 pr-3 ">{order.inventory_location ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{order.current_status ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{order.new_status ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{moment(order.override_start_date).format("MM/DD/YYYY") ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{moment(order.override_end_date).format("MM/DD/YYYY") ?? "-"}</td>
                                                                <td className="pl-3 pr-3 ">{order.channel ?? "-"}</td>
                                                                {/* <td className="pl-3 pr-3 ">{order.user ?? "-"}</td> */}
                                                                <td className="pl-3 pr-3 ">
                                                                    <i
                                                                        className="fa fa-trash"
                                                                        style={{ color: "red", cursor: "pointer" }}
                                                                        onClick={e =>
                                                                            DeleteOrderStatusOverride(
                                                                                `${order.id ?? "-"}`,
                                                                                `${order.ship_to_state ?? "-"}`,
                                                                                `${order.brand ?? "-"}`,
                                                                                `${order.variant ?? "-"}`,
                                                                                `${order.sku ?? "-"}`,
                                                                                `${order.inventory_location ?? "-"}`,
                                                                                `${order.current_status ?? "-"}`,
                                                                                `${order.new_status ?? "-"}`,
                                                                                `${order.override_start_date ?? "-"}`,
                                                                                `${order.override_end_date ?? "-"}`,
                                                                                `${order.channel ?? "-"}`,

                                                                            )
                                                                        }></i>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={totalOrders} />
                                            </>
                                        )}
                                    </Card>
                                </div>
                            )}
                        </Row>
                    </div>
                </Container>
            )}

            <DeleteOrderStatusOverrideModal
                delete_retailer_override_assignee_list={delete_retailer_override_assignee_list}
                openDeleteModal={openDeleteModal}
                toggle={toggle}
                setOpenDeleteModal={setOpenDeleteModal}
                deleteOrderStatusOverrideState={deleteOrderStatusOverrideState}
                deleteOrderStatusOverrideSKU={deleteOrderStatusOverrideSKU}
                deleteOrderStatusOverrideSalesChannel={deleteOrderStatusOverrideSalesChannel}
                deleteOrderStatusOverrideVariant={deleteOrderStatusOverrideVariant}
                deleteOrderStatusOverrideNewStatus={deleteOrderStatusOverrideNewStatus}
                deleteOrderStatusOverrideBrand={deleteOrderStatusOverrideBrand}
                deleteOrderStatusOverrideCurrentStatus={deleteOrderStatusOverrideCurrentStatus}
                deleteOrderStatusOverrideInventoryLocation={deleteOrderStatusOverrideInventoryLocation}
                DeleteRetailerOverride={DeleteRetailerOverride}
                deleteOrderStatusOverrideId={deleteOrderStatusOverrideId}
                deleteOrderStatusOverrideStartDate={deleteOrderStatusOverrideStartDate}
                deleteOrderStatusOverrideEndDate={deleteOrderStatusOverrideEndDate}
            />

        </>
    );
};

export default OrderStatusOverride;
