import React from "react";
import Moment from "react-moment";

const ChildOrderTableRow = ({ chargeback }) => {
    return (
        <tr key={chargeback.id}>
            {/* BATCH ID */}
            <td className="pl-3 pr-3 ">{chargeback.id}</td>

            {/* PARENT ORDER */}
            <td className="pl-3 pr-3" style={{ maxWidth: "200px" }}>
                {chargeback?.parent_order?.order_name && chargeback?.parent_order?.order_id ? (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/order-details?${new URLSearchParams({ orderId: `${chargeback.parent_order.order_id}` })}`}
                        className="btn btn-link p-0"
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%"
                        }}
                        title={chargeback?.parent_order?.order_name}>
                        <span>{chargeback?.parent_order?.order_name}</span>
                    </a>
                ) : (
                    "-"
                )}
            </td>

            {/* EXISTING CHILD ORDERS */}
            {/*<td className="pl-3 pr-3">
                {chargeback?.parent_order?.child_order_names?.map(order => (
                    <a target="_blank" href={window.env.APP_URL + "order/" + order.id} className="btn btn-link p-0">
                        <span> {order.name}</span>
                    </a>
                ))}
                </td> */}

            <td
                className="pl-3 pr-3"
                style={{
                    display: chargeback?.parent_order?.child_order_names.length > 1 ? "flex" : "revert",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    maxWidth: "250px"
                }}>
                {chargeback?.parent_order?.child_order_names.length > 0
                    ? chargeback?.parent_order?.child_order_names?.map(order => (
                          <a
                              target="_blank"
                              href={`/order-details?${new URLSearchParams({ orderId: `${order.id}` })}`}
                              rel="noopener noreferrer"
                              className="btn btn-link p-0 m-0"
                              style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  maxWidth: "100%"
                              }}
                              title={order.name}>
                              <span> {order.name}</span>
                          </a>
                      ))
                    : "-"}
            </td>

            {/* CHILD ORDER TYPE */}
            <td className="pl-3 pr-3">{chargeback?.child_order_type || "-"}</td>

            {/* NEW CHILD ORDER */}
            <td className="pl-1 pr-1">
                {chargeback?.new_child_order_info ? (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={window.env.APP_URL + "order/" + chargeback?.new_child_order_info?.id}
                        className="btn btn-link p-0">
                        {chargeback?.new_child_order_info?.name}
                    </a>
                ) : (
                    "-"
                )}
            </td>

            {/* PARENT ORDER LINE ITEM SELECTED	 */}
            <td className="pl-3 pr-3">
                {chargeback?.parent_order_line_item_selected ? (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/product-details?productId=${chargeback?.parent_order_line_item_selected_id}`}
                        className="btn btn-link p-0"
                        // style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "250px" }}
                        title={chargeback?.parent_order_line_item_selected}>
                        {chargeback?.parent_order_line_item_selected}
                    </a>
                ) : (
                    "-"
                )}
            </td>

            {/* CHILD ORDER LINE ITEM SELECTED */}
            <td
                className="pl-3 pr-3"
                style={{
                    display: chargeback?.manual_child_order_line_items.length > 1 ? "flex" : "revert",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    // maxWidth: "250px"
                }}>
                {chargeback?.manual_child_order_line_items.length > 0
                    ? chargeback?.manual_child_order_line_items?.map(child_lineitem => (
                          <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`/product-details?productId=${child_lineitem?.id}`}
                              className="btn btn-link p-0  m-0"
                            //   style={{
                            //       overflow: "hidden",
                            //       textOverflow: "ellipsis",
                            //       whiteSpace: "nowrap",
                            //       maxWidth: "100%"
                            //   }}
                              title={child_lineitem?.name}>
                              {child_lineitem?.name}
                          </a>
                      ))
                    : "-"}
            </td>

            {/* BATCH STATUS */}
            <td className="pl-3 pr-3">{chargeback?.batch_status || "-"}</td>

            {/* SUBMITTED USER */}
            <td className="pl-3 pr-3">{chargeback?.submitted_user_name || "-"}</td>

            {/* DATE SUBMITTED */}
            <td className="pl-1 pr-1">
                {chargeback?.created_at ? (
                    <Moment format="MM/DD/YYYY" withTitle>
                        {chargeback?.created_at}
                    </Moment>
                ) : (
                    "-"
                )}
            </td>

            {/* ERROR MESSAGE */}
            <td className="pl-3 pr-3">{chargeback?.error_message || "-"}</td>
        </tr>
    );
};

export default ChildOrderTableRow;
