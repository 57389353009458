import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ConfirmDeleteModal = ({ isOpen, toggle, onConfirm, record }) => (
    <div className="form-group mb-3 d-flex flex-column" data-field="name">
        <Modal style={{ marginTop: "100px" }} isOpen={isOpen} toggle={toggle} size={"xl"}>
            <ModalHeader toggle={toggle} className="bg-primary text-white">
                <span className="text-white h3">Confirm Deletion</span>
            </ModalHeader>
            <ModalBody>Are you sure you want to delete the record - {record?.domain}?</ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <Button color="danger" onClick={onConfirm}>
                    Delete
                </Button>
            </ModalFooter>
        </Modal>
    </div>
);
export default ConfirmDeleteModal;
