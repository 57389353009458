import React, { useState } from "react";
import styles from "./orderDetail.module.css";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip } from "reactstrap";
import NotificationConfirmationModal from "./NotificationConfirmationModal";
import { useToasts } from "react-toast-notifications";
import { api } from "services/api";
import { managedByIds } from "utils/commonFunctions";

const Notification = ({ orderDetails }) => {
    const { id, valid_fulfillments, channel_id, order_status, fulfillment_exist, fulfillments } = orderDetails?.order?.order_notification_info
    const { addToast } = useToasts();
    const [eventToggle, setEventToggle] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [endPoint, setEndpoint] = useState("");
    const [orderId, setOrderId] = useState(null)
    const [text, setText] = useState(false);
    const [title, setTitle] = useState(false);
    const toggleEvent = () => {
        setEventToggle(!eventToggle);
    };

    const [tooltipOpen, setTooltipOpen] = useState({});
    const [buttonTooltipOpen, setButtonTooltipOpen] = useState(false);
    const toggle = (id) => setTooltipOpen(prev =>({
        ...prev,
        [id]: !prev[id]
    }));
    const buttonTooltoggle = (id) => setButtonTooltipOpen(prev =>(
        {
            ...prev,
            [id]: !prev[id]
        }
    ));

    const handleModelToggle = (text, endPoint, orderId,title) => {
        setText(text)
        setEndpoint(endPoint)
        setOrderId(orderId)
        setTitle(title)
        setModalIsOpen(true);
    }

    const fetchData = async (endpoint, id) => {
        try {
            const url = `${endpoint}?id=${id}`;
            const response = await api.post(url);
            const status = response?.status
            const data = response?.data
            if (status === 200) {
                addToast(data?.message, { appearance: "success", autoDismiss: true });
            } else {
                addToast(data?.message, { appearance: "error", autoDismiss: true });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const { managed_by_id } = orderDetails?.order?.order_info;
    const addFulfillmentInfoPermissions = managedByIds;
    const isPermission = managed_by_id && addFulfillmentInfoPermissions.includes(managed_by_id);

    return (
        <>
            <NotificationConfirmationModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} text={text} onConfirmation={fetchData} id={orderId} endpoint={endPoint} title={title}/>
            {valid_fulfillments ?<div className={styles.cardConatiner}>
                <div>
                    <h3>Notification</h3>
                </div>
                <hr />
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "3rem" }}>
                        <div>Order Events</div>
                        <div>
                            <Dropdown isOpen={eventToggle} toggle={() => toggleEvent()}>
                                <DropdownToggle onClick={e => e.preventDefault()} caret color="primary">
                                    Event
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem onClick={() => handleModelToggle(" You sure that you want to send this notification? ", "notification/order/created/", id,"Order Created")} disabled={(isPermission)} key={1}>Order Created</DropdownItem>
                                    <DropdownItem onClick={() => handleModelToggle(" You sure that you want to send this notification?", "notification/order/shipped/", id,"Order Shipped")} disabled={((channel_id !== 40) || (order_status !== "CLOSED") || (fulfillment_exist) || (isPermission)) ? true : false} key={2}>Order Shipped</DropdownItem>
                                    <DropdownItem onClick={() => handleModelToggle(" You sure that you want to send this notification?", "notification/order/cancelled/", id,"Order Cancelled")} disabled={((channel_id !== 40) || (order_status !== "CANCELLED") || (isPermission)) ? true : false} key={3}>Order Cancelled</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>


                     {(fulfillments.length>0) ?(<div>
                        <div style={{ fontWeight: "bold" }}>Fulfillments</div>
                        {fulfillments.map((data, index) => (<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
                            <div key={index}>
                                <div id={`fulfillmentValue-${index}`}>
                                    <a href={data?.tracking_number?.url}>{data?.tracking_number?.tracking_number}</a>
                                </div>
                                {data?.tracking_number?.hyperlink ? <Tooltip target={`fulfillmentValue-${index}`} isOpen={tooltipOpen[index]} toggle={() => toggle(index)}>
                                    {data?.tracking_number?.url}
                                </Tooltip> : null}
                            </div>
                            <div>
                                <div id={`send-button-tooltip-${index}`}>
                                    <button className="btn btn-success" disabled={((data.tracking_status_type !== "D") || isPermission) ? true : false} onClick={() => handleModelToggle(" You sure that you want to send this notification?", "notification/order/delivered/", data?.id,"Send Delivered")}>Send Delivered</button>
                                </div>
                                {data.tracking_status_type !== "D" && <Tooltip target={`send-button-tooltip-${index}`} isOpen={buttonTooltipOpen[index]} toggle={() => buttonTooltoggle(index)} >
                                    This is not delivered yet
                                </Tooltip>}
                            </div>

                        </div>))}
                    </div>):null}
                </div>
            </div>:null}
        </>
    );
};

export default Notification;
